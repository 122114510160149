import axios from "axios";
import { BackEndUrl } from "../App";

export async function GetCoworkers(){
    try{
        let fetchData = await axios.get(BackEndUrl + "Coworker", {
            headers:{
                "Access-Control-Allow-Origin": "*",
            }
        });
        let coworkersData = fetchData.data;

        return coworkersData.data;
    }catch(e){
        console.log(e);
    }
}

export async function RemoveCoworkers(name: string){
    try{
        let deleteData = await axios.delete(BackEndUrl + "Coworker?name=" + name, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("UserToken"),
                "Access-Control-Allow-Origin": "*",
            }
        });

        let data = deleteData.data;

        return data.message;
    }catch(e){
        return null;
    }
}

export async function SetCoworkers(file: File, name: string) {
    try{
        const formData = new FormData();
        formData.append('ImageFile', file);
        formData.append("name", name)

        let fetchData = await axios.post(BackEndUrl + "Coworker", formData, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("UserToken"),
                "Access-Control-Allow-Origin": "*",
            }
        });
        let coworkersData = fetchData.data;


        return coworkersData.message;
    }catch(e){
        console.log(e);
    }
}