import React, { useEffect, useState } from "react";
import { SetEntity } from "../../../services/SiteEntitiesServices";
import ReactQuill from "react-quill";
import "./QLStyleFaqEdit.css";

const modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        ['link']
    ],
};

const formats = [
    'bold', 'italic', 'underline', 'strike', 'color', 'background', 'link'
];

export default function FAQEdit(props: any){
    let [isLoading, setIsLoading] = useState(false);
    let currentQuestions: string[] = props.otherQuestions;
    let currentAnswers: string[] = props.otherAnswers;
    let [question, setQuestion] = useState("");
    let [answer, setAnswer] = useState("");
    let [id, setId] = useState<number | null>(null);

    function changeHandler(content: string, isAnswer: boolean){
        if (isAnswer){
            setAnswer(content);
            return;
        }
        setQuestion(content);
    }

    useEffect(() => {
        if (props.id != null){
            setAnswer(currentAnswers[props.id]);
            setQuestion(currentQuestions[props.id]);
            setId(props.id);
        }
    }, []);

    function submitHandler(ev: any){
        if (isLoading){
            return;
        }
        setIsLoading(true);
        if (id == null){
            currentQuestions.push(question);
            currentAnswers.push(answer);

            let object = {
                questions: currentQuestions,
                answers: currentAnswers
            }

            SetEntity("index-faq-data", JSON.stringify(object), "index-faq", "index").then((res) => {
                setIsLoading(false);
                if (res == null){
                    alert("مشکلی در ایجاد به وجود امد");
                    return;
                }

                window.location.reload();
                return;
            });
        }else{
            currentQuestions[id] = question;
            currentAnswers[id] = answer;
            
            let object = {
                questions: currentQuestions,
                answers: currentAnswers
            }

            SetEntity("index-faq-data", JSON.stringify(object), "index-faq", "index").then((res) => {
                setIsLoading(false);
                if (res == null){
                    alert("مشکلی در ویرایش به وجود امد");
                    return;
                }

                window.location.reload();
                return;
            });
        }
    }

    return <section className="popups flex flex-row justify-center py-12 px-72 tablet:px-0">
        <div className="flex flex-col popup bg-white p-8 rounded-15px">
            <div className="popup-content">
                <div className="popup-header flex flex-row-reverse justify-between" onClick={() => {props.closeHandler()}}>
                    <h4 className="popup-header-title text-2xl">سوالات متادول</h4>
                    <i className="fa-solid fa-x" style={{cursor: "pointer"}}></i>
                </div>
                <div className="popup-tabs flex flex-row-reverse justify-start px-4 gap-6 mt-5">
                </div>
                <div className="grid grid-cols-2 gap-6 justify-center mt-4">
                    <div className="popup-input col-span-2 rounded-15px px-2 py-2 my-4">
                        <input type="text" className="w-full h-full text-right" placeholder="سوال" value={question} onChange={(ev) => {changeHandler(ev.target.value, false)}}></input>
                    </div>
                    <div className="col-span-2" id="faq-text-editor" dir="rtl">
                        <ReactQuill
                            value={answer}
                            onChange={(newContent) => {changeHandler(newContent, true)}}
                            modules={modules}
                            formats={formats}
                            theme="snow"
                        />
                    </div>
                </div>
                
                <div className="flex flex-row-reverse justify-center gap-4 mt-6">
                    <a href="#" className="rounded-30px text-center py-4 px-10 popup-submit text-xs" onClick={submitHandler}>{isLoading? <i className="fa-solid fa-spinner"></i> : <p>انتشار</p>}</a>
                    <a href="#" className="rounded-30px text-center py-4 px-10 popup-cancel text-xs" onClick={(ev) => {ev.preventDefault();props.closeHandler()}}>لغو</a>
                </div>
            </div>
        </div>
    </section>
}