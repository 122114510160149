import React, { useEffect, useReducer, useState } from 'react';
import { User, UserActionTypes, UserContext, UserInitialValues, UserReducer } from './context/UserContext';
import { GetUser } from './services/UserServices';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import IndexPage from "./pages/IndexPage";
import AuthPage from "./pages/AuthPage";
import EventsPage from "./pages/EventsPage";
import NewsPage from "./pages/NewsPage";
import NewsDataPage from "./pages/NewsDataPage";
import FilmPage from "./pages/FilmPage";
import GamePage from "./pages/GamePage";
import { hideUiEventHandler, scrollEvent } from './services/EditUiServices';
import { Stream } from 'stream';
import appSettings from "./appsettings.json";
import CentersPage from './pages/CentersPage';

export var BackEndUrl = appSettings.BackEndUrl;
export var StaticFileUrl = appSettings.StaticFilesUrl;

function App() {
  var [userData, userReducer] = useReducer(UserReducer, UserInitialValues);

  useEffect(() => {
    async function getUser() {
      await GetUser().then((res) => {
        userReducer({
          type: UserActionTypes.All,
          value: res
        });
      });
    }

    getUser();    
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", (ev) => {
        scrollEvent(ev);
    });
  }, []);

  if (localStorage.getItem("UserToken") != null && !userData.IsLoggedIn){
    return <></>
  }

  return (
    <>
    <UserContext.Provider value={[userData, userReducer]}>
      <BrowserRouter>
        <Routes>
          <Route path='/' index={true} element={<IndexPage></IndexPage>}></Route>
          <Route path='/Auth' element={<AuthPage></AuthPage>}></Route>
          <Route path='/Events' element={<EventsPage></EventsPage>}></Route>
          <Route path='/News' element={<NewsPage></NewsPage>}></Route>
          <Route path='/NewsData' element={<NewsDataPage></NewsDataPage>}></Route>
          <Route path='/Film' element={<FilmPage></FilmPage>}></Route>
          <Route path='/Game' element={<GamePage></GamePage>}></Route>
          <Route path='/:pageName' Component={CentersPage}></Route>
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
      
    </>
  );
}

export default App;
