import React, { createContext, useReducer, useContext } from 'react';
import { Permissions } from '../enums/Permissions';

export interface User {
    FirstName: string;
    LastName: string;
    Token: string;
    Roles: Permissions[];
    IsLoggedIn: boolean;
}

export interface UserActions {
    type: string,
    value: any
}

export const UserActionTypes = {
    Token: "TOKEN",
    FirstName: "FIRSTNAME",
    LastName: "LASTNAME",
    Roles: "ROLES",
    IsLoggedIn: "ISLOGGEDIN",
    All: "ALL"
}

export const UserInitialValues: User = {
    FirstName: "",
    LastName: "",
    Token: "",
    Roles: [],
    IsLoggedIn: false
}

export function UserReducer(state: User, action: UserActions) : User{
    switch (action.type){
        case UserActionTypes.Roles:
            return {...state, Roles: action.value}
        case UserActionTypes.Token:
            return {...state, Token: action.value}
        case UserActionTypes.FirstName:
            return {...state, FirstName: action.value}
        case UserActionTypes.LastName:
            return {...state, LastName: action.value}
        case UserActionTypes.IsLoggedIn:
            return {...state, IsLoggedIn: action.value}
        case UserActionTypes.All:
            return {...action.value}
        default: 
            return state
    }
}

export const UserContext = createContext<[User, any]>([UserInitialValues, null]);