import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { CurrentEditId, CurrentFocusId, hideUiEventHandler, setCurrentEditing, showUiEventHandler } from "../../services/EditUiServices";
import { Permissions } from "../../enums/Permissions";
import HeaderCentersEdit from "./HeaderCentersEdit";
import { GetEntitiesByCategory } from "../../services/SiteEntitiesServices";
import { StaticFileUrl } from "../../App";

export default function HeaderCentersPopup(props: any){
    let initialValue = {
        title: "راوی گیم" ,
        description : "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده.",
        image: "",
        buttonText: "دیدن راوی گیم",
        buttonLink: "/Game"
      }

    let [data, setData] = useState<any[]>([{...initialValue}, {...initialValue}]);

    useEffect(() => {
        GetEntitiesByCategory("header-centers").then((res: any[] | null) => {
            if (res == null){
                return;
            }

            setData(res.map((r) => JSON.parse(r.content)));
        });
    }, []);

    const popupRef = useRef<HTMLDivElement>(null);
    const [internalOpen, setInternalOpen] = useState(false);
    let [userData, userReducer] = useContext(UserContext);

    let [editId, setEditId] = useState("");

    function switchHandle(){

    }

    function editHandle(ev: any){
        if (CurrentFocusId == "centers-popup-1"){
            setCurrentEditing("centers-popup-1");
            setEditId("header-centers-1");
        }
        if (CurrentFocusId == "centers-popup-2"){
            setCurrentEditing("centers-popup-2");
            setEditId("header-centers-2");
        }
        hideUiEventHandler(null);
    }

    function closeHandle(){
        hideUiEventHandler(null);
        setCurrentEditing("");
        setEditId("");
    }

    function editButtonEnter(){
        props.mouseEnter("edit");
    }

    function mouseEnterEvent(ev: any, elementId: string){
        if (!userData.IsLoggedIn || !userData.Roles.includes(Permissions.ChangeWebsiteSettings)){
            return;
        }
        showUiEventHandler(ev, elementId, switchHandle, editHandle, null, null, null, editButtonEnter);
    }

    return<>
        {
            editId != ""? <HeaderCentersEdit id={editId} closeHandler={closeHandle} title={data[parseInt(editId.replace("header-centers-", "")) - 1]?.title} description={data[parseInt(editId.replace("header-centers-", "")) - 1]?.description} buttonLink={data[parseInt(editId.replace("header-centers-", "")) - 1]?.buttonLink} buttonText={data[parseInt(editId.replace("header-centers-", "")) - 1]?.buttonText} image={data[parseInt(editId.replace("header-centers-", "")) - 1]?.image}></HeaderCentersEdit> : <></>
        }
    <div ref={popupRef} className={"header-centers-popup flex flex-row-reverse w-[500px] bg-white px-8 py-10 gap-4 rounded-xl " + (props.shouldOpen? "" : "hidden")} onMouseEnter={() => props.mouseEnter("center")} onMouseLeave={() => props.mouseLeave("center")}>
    <a href={data[0]?.buttonLink} className="header-center flex flex-col gap-1 border-gray-left px-4" onMouseMove={(ev) => {mouseEnterEvent(ev, "centers-popup-1")}}>
        <div className="flex flex-row-reverse justify-start gap-4">
            <img alt={`${data[0]?.title}`} className="h-12, w-12" src={StaticFileUrl + "/" + data[0]?.image}></img>
            <div className="flex flex-col justify-center">
                <h2 className="text-center text-base header-center-title">{data[0]?.title}</h2>
            </div>
        </div>
        <p className="text-xs header-center-content">{data[0]?.description}</p>
        <div className="flex flex-row-reverse header-center-content gap-4">
            <p className="text-xs">{data[0]?.buttonText}</p>
            <i className="fa-solid fa-arrow-left"></i>
        </div>
    </a>
    <a href={data[1]?.buttonLink} className="header-center flex flex-col gap-1 px-4" onMouseMove={(ev) => {mouseEnterEvent(ev, "centers-popup-2")}}>
        <div className="flex flex-row-reverse justify-start gap-4">
            <img alt={`${data[0]?.title}`} className="h-12, w-12" src={StaticFileUrl + "/" + data[1]?.image}></img>
            <div className="flex flex-col justify-center">
                <h2 className="text-center text-base header-center-title">{data[1]?.title}</h2>
            </div>
        </div>
        <p className="text-xs header-center-content">{data[1]?.description}</p>
        <div className="flex flex-row-reverse header-center-content gap-4">
            <p className="text-xs">{data[1]?.buttonText}</p>
            <i className="fa-solid fa-arrow-left"></i>
        </div>
    </a>
</div>
</>
}