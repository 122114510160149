import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import style from "./Editor.module.css";
import axios from "axios";
import { BackEndUrl } from "../../../App";

const modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }]
    ],
};

const formats = [
    'bold', 'italic', 'underline', 'strike', 'color', 'background'
];

export default function CentersEditor(props: any){
    let [template, setTemplate] = useState(0); //0 for creating(all) 1 for editing popup

    let [name, setName] = useState("");
    let [popupInfo, setPopupInfo] = useState({
        title: "",
        description: "",
        link: "",
        linkTitle: ""
    });
    let [popupImage, setPopupImage]: any[] = useState(null);
    let [pageInfo, setPageInfo] = useState({
        title: "",
        description: "",
        link: "",
        linkTitle: ""
    });
    let [pageImage, setPageImage]: any[] = useState(null);
    let [activitiesTitle, setActivitiesTitle] = useState("");

    useEffect(() => {
        if(props.editName != null){
            setTemplate(1);
            setName(props.editName)
            setPopupInfo({
                title: props.editData.title,
                description: props.editData.description,
                link: props.editData.link,
                linkTitle: props.editData.linkTitle
            });
        }else{
            setTemplate(0);
        }
    }, props);

    function changePopupInfo(value: any, infoType: number){
        switch(infoType){
            case 0:
                setPopupInfo((prev) => {
                    return {...prev, title: value}
                });
                break;
            case 1:
                setPopupInfo((prev) => {
                    return {...prev, description: value}
                });
                break;
            case 2:
                setPopupInfo((prev) => {
                    return {...prev, link: value}
                });
                break;
            case 3:
                setPopupInfo((prev) => {
                    return {...prev, linkTitle: value}
                });
                break;
        }
    }

    function changePageInfo(value: any, infoType: number){
        switch(infoType){
            case 0:
                changeName(value);
                setPageInfo((prev) => {
                    return {...prev, title: value}
                });
                break;
            case 1:
                setPageInfo((prev) => {
                    return {...prev, description: value}
                });
                break;
            case 2:
                setPageInfo((prev) => {
                    return {...prev, link: value}
                });
                break;
            case 3:
                setPageInfo((prev) => {
                    return {...prev, linkTitle: value}
                });
                break;
        }
    }

    function changeName(value: string){
        let editedName = value.replace(" ", "-");
        editedName = editedName.replace("/", "-");
        setName(editedName);
        setPageInfo((prev) => {
            return {...prev, link: editedName}
        });
        setPopupInfo((prev) => {
            return {...prev, link: editedName}
        });
    }

    function selectImageForPopup(){
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
    
        input.onchange = async () => {
            const file = input.files ? input.files[0] : null;
            setPopupImage(file);
        }
    }

    function selectImageForPage(){
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
    
        input.onchange = async () => {
            const file = input.files ? input.files[0] : null;
            setPageImage(file);
        }
    }

    function submitHandle(){
        if (template == 0){
            if (pageInfo.title == "" || pageInfo.description == "" || pageImage == null){
                alert("لطفا تمام فیلد‌ها را پر کنید");
                return;
            }

            let formData = new FormData();
            formData.append("Name", name);
            formData.append("Title", pageInfo.title);
            formData.append("Description", pageInfo.description);
            formData.append("Link", name);
            formData.append("LinkTitle", "برو به " + pageInfo.title);
            formData.append("Image", pageImage);
            formData.append("PopupTitle", pageInfo.title);
            formData.append("PopupDescription", pageInfo.description);
            formData.append("PopupLink", name);
            formData.append("PopupLinkTitle", "برو به " + pageInfo.title);
            formData.append("PopupImage", pageImage);
            formData.append("ActivitiesTitle", pageInfo.title);

            axios.post(BackEndUrl + "Centers", formData, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("UserToken")
                }
            }).then((res) => {
                alert(res.data.message);
                window.location.reload();
                return;
            });
        }else{
            let formData = new FormData();
            formData.append("Name", name);
            formData.append("PopupTitle", pageInfo.title);
            formData.append("PopupDescription", pageInfo.description);
            formData.append("PopupLink", name);
            formData.append("PopupLinkTitle", "برو به " + pageInfo.title);
            if (popupImage != null){
                formData.append("PopupImage", popupImage);
            }

            axios.put(BackEndUrl + "Centers", formData, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("UserToken")
                }
            }).then((res) => {
                alert(res.data.message);
                window.location.reload();
                return;
            });

        }
    }

    return <div className={`${style.editor_container}`}>
            <div className={`${style.editor_content}`}>
                <div className={`${style.editor_header}`} onClick={() => {props.closeFunction()}}>
                    <div className={`${style.editor_header_title}`}>
                        {template == 0? "ساخت مرکز" : "ویرایش پاپ آپ مرکز"}
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" style={{width: "20px", height: "20px"}}><path d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"/></svg>
                </div>
                <div className={`${style.editor_body}`}>
                    {/*template == 0? <><div className={`${style.editor_body_label}`}>
                        اطلاعات کلی
                    </div>
                    <div className={`${style.editor_body_input}`}>
                        <input className={`${style.editor_body_input_input}`} placeholder="نام مرکز" value={name} onChange={(ev) => {changeName(ev.target.value)}}></input>
                    </div></> : <></>*/}
                    {/*<div className={`${style.editor_body_label}`}>
                        اطلاعات پاپ آپ
                    </div>
                    <div className={`${style.editor_body_input}`}>
                        <input className={`${style.editor_body_input_input}`} placeholder="تایتل" value={popupInfo.title} onChange={(ev) => changePopupInfo(ev.target.value, 0)}></input>
                    </div>
                    <div className={`${style.editor_body_input}`}>
                        <input className={`${style.editor_body_input_input}`} placeholder="توضیحات" value={popupInfo.description} onChange={(ev) => changePopupInfo(ev.target.value, 1)}></input>
                    </div>
                    <div className={`${style.editor_body_input}`}>
                        <input className={`${style.editor_body_input_input}`} placeholder="انتخاب عکس" value={popupImage?.name || "انتخاب عکس"} onClick={() => selectImageForPopup()}></input>
                    </div>
                    <div className={`${style.editor_body_input}`}>
                        <input className={`${style.editor_body_input_input}`} placeholder="تایتل لینک" value={popupInfo.linkTitle} onChange={(ev) => changePopupInfo(ev.target.value, 3)}></input>
                    </div>*/}
                    <div className={`${style.editor_body_label}`}>
                        {template == 0? "اطلاعات مرکز" : "اطلاعات دراپ دون"}
                    </div>
                    <div className={`${style.editor_body_input}`}>
                        <input className={`${style.editor_body_input_input}`} placeholder="تایتل" value={pageInfo.title} onChange={(ev) => changePageInfo(ev.target.value, 0)}></input>
                    </div>
                    <div className={`${style.editor_body_input}`}>
                        <input className={`${style.editor_body_input_input}`} placeholder="توضیحات" value={pageInfo.description} onChange={(ev) => changePageInfo(ev.target.value, 1)}></input>
                    </div>
                    <div className={`${style.editor_body_input}`}>
                        <input className={`${style.editor_body_input_input}`} placeholder="انتخاب عکس" value={pageImage?.name || "انتخاب عکس صفحه"} onClick={() => selectImageForPage()}></input>
                    </div>
                    <div className={`${style.editor_body_submit}`}>
                        <div className={`${style.editor_body_submit_submit}`} onClick={() => submitHandle()}>
                            ثبت
                        </div>
                        <div className={`${style.editor_body_submit_cancel}`} onClick={() => {props.closeFunction()}}>
                            لغو
                        </div>
                    </div>
                </div>
            </div>
        </div>
}