import React, { useContext, useState } from "react";
import Login from "../components/Auth/Login";
import Register from "../components/Auth/Register";
import { UserActionTypes, UserContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import { GetUser, LoginUser, RegisterUser } from "../services/UserServices";

export default function AuthPage(){
    let [isLogin, setIsLogin] = useState(true);
    let [userData, userReducer] = useContext(UserContext);
    const navigate = useNavigate();

    if (userData.IsLoggedIn){
        navigate("/");
    }

    async function loginHandle(phoneNumber: string, password: string){
        LoginUser(phoneNumber, password).then((token) => {
            if (!token){
                alert("مشکلی در لاگین پیش امد");
                window.location.reload();
                return;
            }
            if (token[1] == ""){
                alert(token[0]);
                window.location.reload();
                return;
            }
            localStorage.setItem("UserToken", token[1]);
            GetUser().then((userData) => {
                userReducer({
                    type: UserActionTypes.All,
                    value: userData
                });
                navigate("/", {state: {
                    reload: true
                }});
            });
        });
    }

    async function registerHandle(phoneNumber: string, password: string, firstName: string, lastName: string){
        RegisterUser(phoneNumber, password, firstName, lastName).then((token) => {
            if (!token){
                alert("مشکلی در ثبت نام پیش امد");
                window.location.reload();
                return;
            }
            if (token[1] == ""){
                alert(token[0]);
                window.location.reload();
                return;
            }
            localStorage.setItem("UserToken", token[1]);
            GetUser().then((userData) => {
                userReducer({
                    type: UserActionTypes.All,
                    value: userData
                });
                navigate("/", {state: {
                    reload: true
                }});
            });
        });
    }

    return<div className="page-container">
            <div id="main-background-au">

            </div>
            <section className="w-full py-5 flex flex-row justify-center phone:px-1 auth-page" id="auth-form">
                <div className="login flex flex-col justify-around phone:w-full">
                    <div className="header-title basis-32 align-middle h-16 flex flex-row justify-center phone:basis-[110px] phone:ml-0">
                        <img alt="راوینو" src="./assets/images/logo.png" className="h-[55px] w-[110px]"></img>
                    </div>
                    <div className="flex flex-col bg-white gap-3 justify-evenly py-10 px-6 rounded-30px w-[430px] phone:w-full">
                        {
                            isLogin? <Login switchHandle={(isLogin: boolean) => {setIsLogin(isLogin)}} loginHandle={loginHandle}></Login> : <Register switchHandle={(isLogin: boolean) => {setIsLogin(isLogin)}} registerHandle={registerHandle}></Register>
                        }
            
                    </div>
        
                </div>
            </section>
    </div> 
    
}