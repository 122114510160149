import React from "react";
import Events from "../components/Events/Events";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import { mouseEnterSelfEvent } from "../services/EditUiServices";

export default function EventsPage(){
    return  <div className="page-container">
                <div id="main-background-au">

                </div>
                <div id="element-actions" className="flex flex-row flex-warp gap-3" onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                    <div id="edit-button" className="text-xl px-4 py-3 rounded-full hover:cursor-pointer" hidden onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                        <i className="fa-regular fa-pen"></i>
                    </div>
                    <div id="delete-button" className="text-xl px-4 py-3 rounded-full hover:cursor-pointer" hidden onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                        <i className="fa-regular fa-trash"></i>
                    </div>
                    <div id="close-button" className="text-xl px-4 py-3 rounded-full hover:cursor-pointer" hidden onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                    <div id="submit-button" className="text-xl px-4 py-3 rounded-full hover:cursor-pointer" hidden onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                        <i className="fa-solid fa-check"></i>
                    </div>
                </div>
                <Header activeTab={"events"}></Header>
                <Events limit={false} link={false}></Events>
                <Footer></Footer>
            </div>
}