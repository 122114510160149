import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BackEndUrl, StaticFileUrl } from "../App";
import Activities from "../components/Activities/Activities";
import Footer from "../components/Footer/Footer";
import GameLanding from "../components/Landing/GameLanding";
import LandingSlider from "../components/LandingSlider/LandingSlider";
import News from "../components/News/News";
import Statics from "../components/Statics/Statics";
import { mouseEnterSelfEvent } from "../services/EditUiServices";
import CentersLanding from "../components/Landing/CentersLanding";
import Header from "../components/Header/Header";

export default function CentersPage(props: any){
    let [name, setName] = useState(useParams().pageName);

    let [centerData, setCenterData]: any[] = useState();

    let [headerData, setHeaderData] = useState({
        name: "",
        title: "مرکز راوینو",
        description: "مرکز راوینو",
        image: StaticFileUrl + "/",
        link: "برو به مرکز راوینو",
        linkTitle: ""
    });
    let [sliderData, setSliderData] = useState([]);
    let [statisticData, setStatisticData] = useState([]);
    let [activitiesData, setActivitiesData] = useState([]);
    let [selectedCategoriesData, setSelectedCategoriesData] = useState([]);

    let [activitiesTitle, setActivitiesTitle] = useState("");

    useEffect(() => {
        axios.get(BackEndUrl + "Centers/Center", {
            params: {
                name: name
            }
        }).then((res) => {
            if (res.data.data == null){
                window.location.href = "/";
                return;
            }
            setCenterData(res.data.data);
            setHeaderData({
                name: name || "",
                title: res.data.data?.title || "مرکز راوینو",
                description: res.data.data?.description || "مرکز راوینو",
                image: StaticFileUrl + "/" + res.data.data?.image,
                link: res.data.data?.link || "برو به مرکز راوینو",
                linkTitle: res.data.data?.linkTitle || "",
            });
            setSliderData(res.data.data.slider);
            setActivitiesTitle(res.data.data?.activityTitle);
            setStatisticData(res.data.data?.statistics);
            setActivitiesData(res.data.data?.activities);
            setSelectedCategoriesData(res.data.data?.newsCategories || []);
        });
    }, []);

    console.log(selectedCategoriesData);
    console.log(sliderData);
    console.log(statisticData);
    console.log(activitiesData);
    console.log(activitiesTitle);

    return <>
        {activitiesTitle != "" && <><div className="page-container">
        <div id="main-background">

        </div>
        <div id="element-actions" className="flex flex-row flex-warp gap-3" onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
            <div id="edit-button" className="text-xl px-4 py-3 rounded-full hover:cursor-pointer" hidden onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                <i className="fa-regular fa-pen"></i>
            </div>
            <div id="delete-button" className="text-xl px-4 py-3 rounded-full hover:cursor-pointer" hidden onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                <i className="fa-regular fa-trash"></i>
            </div>
            <div id="close-button" className="text-xl px-4 py-3 rounded-full hover:cursor-pointer" hidden onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                <i className="fa-solid fa-xmark"></i>
            </div>
            <div id="submit-button" className="text-xl px-4 py-3 rounded-full hover:cursor-pointer" hidden onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                <i className="fa-solid fa-check"></i>
            </div>
        </div>
        <Header landing={<CentersLanding data={headerData}></CentersLanding>}></Header>
        <LandingSlider name={name || ""} data={sliderData}></LandingSlider>
        <Statics customTitle={activitiesTitle} data={statisticData} name={name || ""}></Statics>
        <Activities data={activitiesData} customName={activitiesTitle} name={name || ""}></Activities>
        <News data={selectedCategoriesData} name={name || ""} limit={true} link={true} customTitle={<p>خبرهای مرتبط با <span style={{color: "rgba(121, 200, 215, 1)"}}>{activitiesTitle}</span></p>}></News>
        <Footer></Footer>
        </div></>}
    </>
}