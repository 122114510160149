import React, { useContext, useEffect, useState } from "react";
import FooterDescriptionEdit from "./FooterDescriptionEdit";
import FooterSocialMediaEdit from "./FooterSocialMediaEdit";
import { DeleteEntity, GetEntitiesByCategory, GetEntity } from "../../services/SiteEntitiesServices";
import { UserContext } from "../../context/UserContext";
import { BackEndUrl, StaticFileUrl } from "../../App";
import { CurrentEditId, CurrentFocusId, hideUiEventHandler, setCurrentEditing, showUiEventHandler } from "../../services/EditUiServices";
import { Permissions } from "../../enums/Permissions";
import axios from "axios";

export default function Footer(){
    let [userData, userReducer] = useContext(UserContext);

    let [isEditingSocialMedia, setIsEditingSocialMedia] = useState(false);
    let [isEditingDescription, setIsEditingDescription] = useState(false);
    let [editingId, setEditingId] = useState<string | null>(null);

    let [description, setDescription] = useState("");
    let [socialMedias, setSocialMedias] = useState<any[]>([]);

    function getSocialMedia(entityId: string){
        let returnValue: any = null;

        socialMedias.forEach((socialMedia) => {
            if (socialMedia.id == entityId){
                returnValue = socialMedia;
            }
        });

        return returnValue;
    }

    useEffect(() => {
        GetEntity("footer-description").then((res) => {
            if (res == null){
                return;
            }

            setDescription(res.content);
        });

        GetEntitiesByCategory("footer-socialmedias").then((res: any[]) => {
            if (res == null){
                return;
            }

            let socialMedias: any[] = [];

            res.forEach((socialMedia) => {
                let object = JSON.parse(socialMedia.content);

                socialMedias.push({
                    id: socialMedia.entityId,
                    link: object.link,
                    image: object.image
                });
            });

            setSocialMedias(socialMedias);
        });
    }, []);

    function editHandle(ev: any){
        if (CurrentFocusId.includes("footer-sm-")){
            setIsEditingSocialMedia(true);
            setCurrentEditing(CurrentFocusId);
        }
        if (CurrentFocusId == "footer-description"){
            setIsEditingDescription(true);
        }
    }

    function closeHandle(){
        setIsEditingDescription(false);
        setIsEditingSocialMedia(false);
        hideUiEventHandler(null);
        setEditingId(null);
    }

    function deleteHandler(){
        DeleteEntity(CurrentFocusId.replace("footer-sm-", "")).then((res) => {
            if (res == null){
                alert("مشکلی در حذف به وجود امد");
                return;
            }

            window.location.reload();
        })
    }

    function switchHandle(){
        hideUiEventHandler(null);
    }

    function mouseEnterEvent(ev: any, elementId: string){
        if (!userData.IsLoggedIn || !userData.Roles.includes(Permissions.ChangeWebsiteSettings)){
            return;
        }
        showUiEventHandler(ev, elementId, switchHandle, editHandle, null, null, null);
    }

    function mouseEnterSocialMediasEvent(ev: any, elementId: string){
        if (!userData.IsLoggedIn || !userData.Roles.includes(Permissions.ChangeWebsiteSettings)){
            return;
        }
        showUiEventHandler(ev, elementId, switchHandle, editHandle, deleteHandler, null, null);
    }

    let [centersData, setCentersData] = useState([]);

    useEffect(() => {
        axios.get(BackEndUrl + "Centers/Popups").then((res) => {
            setCentersData(res.data.data);
        });
    }, []);

    return <>
        <section className="footer py-9 px-5 mt-64" dir="rtl" style={{marginBottom: "auto"}}>
            {
                isEditingDescription? <FooterDescriptionEdit content={description} closeHandler={closeHandle}></FooterDescriptionEdit> : <></>
            }
            {
                isEditingSocialMedia? <FooterSocialMediaEdit closeHandler={closeHandle} id={CurrentEditId.replace("footer-sm-", "")} link={(getSocialMedia(CurrentEditId.replace("footer-sm-", "")) as any)?.link || ""} image={(getSocialMedia(CurrentEditId.replace("footer-sm-", "")) as any)?.image || ""}></FooterSocialMediaEdit> : <></>
            }
        <div className="footer-content flex flex-row px-5">
            <div className="footer-des flex flex-col justify-center w-[270px] gap-6">
                <div className="footer-logo flex flex-row">
                    <img src="./assets/images/logo.png" className="h-[50px] w-[110px]" alt="لوگو راوینو"></img>
                </div>
                <p className="text-sm" id="footer-text" onMouseMove={(ev) => {mouseEnterEvent(ev, "footer-description")}}>{description}</p>
                <div className="flex flex-row gap-5 justify-start footer-socialmedias-items text-3xl flex-wrap">
                    {
                        socialMedias.map((socialMedia) => {
                            return <a href={socialMedia.link} onMouseMove={(ev) => {mouseEnterSocialMediasEvent(ev, `footer-sm-${socialMedia.id}`)}} className="h-4 w-4"><img alt="social media" className="footer-sm" src={StaticFileUrl + "/" + socialMedia.image}></img></a>
                        })
                    }
                    {
                        userData.Roles.includes(Permissions.ChangeWebsiteSettings)? <a href="#" className="h-4 w-4" onClick={() => {setIsEditingSocialMedia(true); setCurrentEditing("")}}><i className="fa-solid fa-plus"></i></a> : <></>
                    }
                </div>
            </div>
            <div className="flex flex-row justify-center footer-links">
                <div className="flex flex-col w-40 py-5 phone:col-span-1">
                    <p className="text-lg mb-6 footer-links-title">پیوندهای مفید</p>
                    <div className="flex flex-col justify-center gap-3">
                        <div className="flex flex-row gap-4 footer-links-item">
                            <p className="footer-links-item-dash">-</p>
                            <a href="/News">آرشیو خبر‌ها</a>
                        </div>
                        <div className="flex flex-row gap-4 footer-links-item">
                            <p className="footer-links-item-dash">-</p>
                            <a href="/Events">آرشیو رویداد‌ها</a>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-40 py-5">
                    <p className="text-lg mb-6 footer-links-title">مراکز زیر مجموعه</p>
                    <div className="flex flex-col justify-center gap-3">
                        {
                            centersData.map((center: any) => {
                                return  <div className="flex flex-row gap-4 footer-links-item">
                                            <p className="footer-links-item-dash">-</p>
                                            <a href={center.link}>{center.title}</a>
                                        </div>
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="flex flex-row py-5 footer-logos" style={{gap: "6px"}}>
                <div className="flex flex-col footer-logo-trust rounded-[15px] p-2 justify-center" id="neshan-container">
                <div dangerouslySetInnerHTML={{ __html: `<a target="_blank" href="https://trustseal.enamad.ir/?id=490125&Code=a55z3ssm2VpDLdTyjFtkx26PCIzxqjPC"><img referrerpolicy='origin' src='https://trustseal.enamad.ir/logo.aspx?id=490125&Code=a55z3ssm2VpDLdTyjFtkx26PCIzxqjPC' alt='' style='cursor:pointer' code='a55z3ssm2VpDLdTyjFtkx26PCIzxqjPC'></a>` }} />
                </div>
                <div className="flex flex-col footer-logo-trust rounded-[15px] p-2 justify-center" id="neshan-container">
                    <div dangerouslySetInnerHTML={{ __html: `<img referrerpolicy='origin' id = 'rgvjfukzoeukoeuksizpsizp' style = 'cursor:pointer' onclick = 'window.open("https://logo.samandehi.ir/Verify.aspx?id=368899&p=xlaogvkamcsimcsipfvlpfvl", "Popup","toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30")' alt = 'logo-samandehi' src = 'https://logo.samandehi.ir/logo.aspx?id=368899&p=qftiwlbqaqgwaqgwbsiybsiy' />` }} />
                </div>
            </div>
        </div>
    </section>
    <section className="footer-copyright mx-10 py-4 flex flex-col justify-center">
        <p className="text-center text-sm phone:text-xs" id="copyright-text">کلیه حقوق این وب سایت متعلق به موسسه راوینو می باشد. ۱۳۹۷ - ۱۴۰۳</p>
    </section>
    </>
}