import React, { useContext, useEffect, useState } from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import GameLanding from "../components/Landing/GameLanding";
import LandingSlider from "../components/LandingSlider/LandingSlider";
import Statics from "../components/Statics/Statics";
import { UserContext } from "../context/UserContext";
import { Permissions } from "../enums/Permissions";
import Activities from "../components/Activities/Activities";
import News from "../components/News/News";
import { GetEntitiesByCategory, GetEntity } from "../services/SiteEntitiesServices";
import { mouseEnterSelfEvent } from "../services/EditUiServices";


export default function GamePage(){
    let [userData, userReducer] = useContext(UserContext);
    let [activitiesItem, setActivitiesItem] = useState([]);
    let [newsCategory, setNewsCategory] = useState(null);
    let [landingData, setLandingData] = useState([]);
    let [sliderData, setSliderData] = useState([]);
    let [statics, setStatics] = useState([{
        icon: "",
        content: ""
    }]);

    useEffect(() => {
        Promise.all([GetEntitiesByCategory("game-landing"), GetEntitiesByCategory("game-slider"), GetEntitiesByCategory("game-activities"), GetEntity("game-news-categories"), GetEntitiesByCategory("game-statics")]).then(([res1, res2, res3, res4, res5]) => {
            if (res1 == null || res2 == null || res3 == null || res4 == null || res5 == null){
                return;
            }

            setLandingData(res1);
            setSliderData(res2);
            setActivitiesItem(res3);
            setNewsCategory(JSON.parse(res4.content));

            let cacheStatis: {
                entityId: string,
                icon: string,
                content: string
            }[] = [];

            res5.forEach((staticItem: any) => {
                let decode = JSON.parse(staticItem.content);
                cacheStatis.push({
                    entityId: staticItem.entityId,
                    icon: decode.icon,
                    content: decode.content
                });
            });

            if (userData.Roles.includes(Permissions.ChangeWebsiteSettings)){
                cacheStatis.push({
                    entityId: "",
                    icon: "plus",
                    content: "اضافه کردن"
                });
            }

            setStatics(cacheStatis);
        });
    }, []);

    return <div className="page-container">
        <div id="main-background">

        </div>
        <div id="element-actions" className="flex flex-row flex-warp gap-3" onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
            <div id="edit-button" className="text-xl px-4 py-3 rounded-full hover:cursor-pointer" hidden onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                <i className="fa-regular fa-pen"></i>
            </div>
            <div id="delete-button" className="text-xl px-4 py-3 rounded-full hover:cursor-pointer" hidden onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                <i className="fa-regular fa-trash"></i>
            </div>
            <div id="close-button" className="text-xl px-4 py-3 rounded-full hover:cursor-pointer" hidden onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                <i className="fa-solid fa-xmark"></i>
            </div>
            <div id="submit-button" className="text-xl px-4 py-3 rounded-full hover:cursor-pointer" hidden onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                <i className="fa-solid fa-check"></i>
            </div>
        </div>
        <Header landing={<GameLanding data={landingData}></GameLanding>}></Header>
        {/*<LandingSlider updateCategory="game-landing-slider"></LandingSlider>*/}
        {/*<Statics customTitle={"راوی گیم"} updateCategory={"game-statics"}></Statics>*/}
        {/*<Activities customName="راوی گیم" updateCategory="game-activities"></Activities>*/}
        <News updateCategories="game-news-categories" limit={true} link={true} customTitle={<p>خبرهای مرتبط با <span style={{color: "rgba(121, 200, 215, 1)"}}>مرکز گیم</span></p>}></News>
        <Footer></Footer>
    </div>
}