import React, { useContext, useEffect, useRef, useState } from "react";
import { CurrentEditId, CurrentFocusId, hideUiEventHandler, setCurrentEditing, showUiEventHandler } from "../../services/EditUiServices";
import { SetEntity } from "../../services/SiteEntitiesServices";
import { UserContext } from "../../context/UserContext";
import { Permissions } from "../../enums/Permissions";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "./QLStyleIndexLanding.css";


export default function IndexLanding(props: {data: any[]}){
    let [userData, userReducer] = useContext(UserContext);

    let [isEditing, setIsEditing] = useState("");
    let [titleEditValue, setTitleEditValue] = useState<string>("");
    let [descriptionEditValue, setDescriptionEditValue] = useState<string>("");
    let [bubbleEditValue, setBubbleEditValue] = useState<string>("");

    var titleEditRef = useRef(titleEditValue);
    var bubbleEditRef = useRef(bubbleEditValue);
    var descriptionEditRef = useRef(descriptionEditValue);

    let [title, setTitle] = useState("");
    let [bubble, setBubble] = useState("");
    let [description, setDescription] = useState("");

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': [] }, { 'background': [] }]
        ],
    };

    const formats = [
        'bold', 'italic', 'underline', 'strike', 'color', 'background'
    ];

    useEffect(() => {
        props.data.forEach((d) => {
            if (d.entityId == "index-landing-title"){
                setTitleEditValue(d.content);
                setTitle(d.content);
            }
            if (d.entityId == "index-landing-bubble"){
                setBubbleEditValue(d.content);
                setBubble(d.content);
            }
            if (d.entityId == "index-landing-description"){
                setDescriptionEditValue(d.content);
                setDescription(d.content);
            }
        });
    }, []);

    function changeHandler(id: string, content: string, ev: any){
        if (id == "index-landing-title"){
            setTitleEditValue(content);
        }
        if (id == "index-landing-bubble"){
            setBubbleEditValue(content);
        }
        if (id == "index-landing-description"){
            setDescriptionEditValue(content);
        }
        if (ev != null){
            showUiEventHandler(ev, CurrentEditId, switchHandle, null, null, closeHandle, submitHandle);
        }
    }

    function handleKeyDown(isSubmit = true){
        if (isSubmit){
            switch (CurrentEditId){
                case "index-landing-title":
                    SetEntity("index-landing-title", titleEditRef.current || "", "index-landing", "index").then((res) => {
                        if (res == null){
                            return;
                        }
                    });

                    setTitle(titleEditRef.current || title);
                    setIsEditing("");
                    break;
                case "index-landing-description":
                    SetEntity("index-landing-description", descriptionEditRef.current || "", "index-landing", "index").then((res) => {
                        if (res == null){
                            return;
                        }
                    });
                    setDescription(descriptionEditRef.current || description);
                    setIsEditing("");
                    break;
                case "index-landing-bubble":
                    SetEntity("index-landing-bubble", bubbleEditRef.current || "", "index-landing", "index").then((res) => {
                        if (res == null){
                            return;
                        }
                    });
                    setBubble(bubbleEditRef.current || bubble);
                    setIsEditing("");
                    break;
            }
            return;
        }
        setIsEditing("");
        setCurrentEditing("");
    }

    useEffect(() => {
        titleEditRef.current = titleEditValue;
        bubbleEditRef.current = bubbleEditValue;
        descriptionEditRef.current = descriptionEditValue;

        window.addEventListener("keydown", checkButton);

        if (CurrentEditId.includes("index-landing")){
            let element = document.querySelector(".ql-container") as HTMLElement | null;

            if (element != null){
                element.onmousemove = showUi;
            }
        }

        function checkButton(ev: any){
            if (ev.key == "Escape"){
                handleKeyDown(false);
            }
            if (ev.key == "Enter"){
                handleKeyDown();
            }
        }

        function showUi(ev: any){
            showUiEventHandler(ev, CurrentFocusId, switchHandle, null, null, closeHandle, submitHandle);
        }

        return () => {
            window.removeEventListener("keydown", checkButton);
        };
    }, [titleEditValue, descriptionEditValue, bubbleEditValue]);

    function submitHandle(ev: any){
        handleKeyDown(true);
        hideUiEventHandler(ev);
    }

    function editHandle(ev: any){
        if (CurrentFocusId == "index-landing-title"){
            setIsEditing("index-landing-title");
            setCurrentEditing("index-landing-title");
        }
        if (CurrentFocusId == "index-landing-description"){
            setIsEditing("index-landing-description");
            setCurrentEditing("index-landing-description");
        }
        if (CurrentFocusId == "index-landing-bubble"){
            setIsEditing("index-landing-bubble");
            setCurrentEditing("index-landing-bubble");
        }

        if (ev != null){
            showUiEventHandler(ev, isEditing, switchHandle, null, null, closeHandle, submitHandle);
        }
    }

    function closeHandle(ev: any){
        setIsEditing("");
        hideUiEventHandler(null);
        setCurrentEditing("");
    }

    function switchHandle(ev: any){
        hideUiEventHandler(null);
    }

    function mouseEnterEvent(ev: any, elementId: string){
        if (!userData.IsLoggedIn || !userData.Roles.includes(Permissions.ChangeWebsiteSettings)){
            return;
        }
        showUiEventHandler(ev, elementId, switchHandle, editHandle, null, null, null);
    }

    return <>
    <div className="mt-14 px-5 landing-body phone:mt-7">
    <div className="flex flex-row-reverse">
        <div className="flex flex-col justify-center">
            <div className="border-[1px] h-[50px] w-auto flex flex-col rounded-30px justify-center px-4" id="landing-add-button">
                <div className="flex flex-row justify-center" dir="rtl">
                    {
                        isEditing == "index-landing-bubble"? <><input className="text-sm align-middle text-center py-1" id="landing-add-button-title" value={bubbleEditValue || ""} style={{minWidth: "300px"}} onChange={(ev) => {changeHandler("index-landing-bubble", ev.target.value, ev)}}></input></> :
                        <><p className="text-sm align-middle text-center py-1" id="landing-add-button-title" onMouseMove={(ev) => {mouseEnterEvent(ev, "index-landing-bubble")}}>
                            {bubble}
                        </p>
                        <p className="text-lg">
                             👋
                        </p></>
                    }
                </div>
            </div>
        </div>
    </div>
    <div className="flex flex-row-reverse mt-6">
        <div className="flex flex-col justify-center basis-[629px]" dir="rtl">
            {
                isEditing == "index-landing-title"? <div id="landing-text-editor"><ReactQuill
                value={titleEditValue}
                onChange={(newContent) => {changeHandler("index-landing-title", newContent, null)}}
                modules={modules}
                formats={formats}
                theme="snow"
              /></div> : 
                <h1 className="text-5xl phone:text-[42px] align-middle text-right py-1" id="landing-header" onMouseMove={(ev) => {mouseEnterEvent(ev, "index-landing-title")}}>
                    <div dangerouslySetInnerHTML={{ __html: title }} />
                </h1>
            }
            {
                isEditing == "index-landing-description"? <div id="landing-text-editor"><ReactQuill
                value={descriptionEditValue}
                onChange={(newContent) => {changeHandler("index-landing-description", newContent, null)}}
                modules={modules}
                formats={formats}
                theme="snow"
              /></div> :
                <p className="text-base phone:text-sm align-middle text-right py-1 mt-2" id="landing-content" onMouseMove={(ev) => {mouseEnterEvent(ev, "index-landing-description")}}>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                </p>
            }
            
            </div>
        </div>
    </div>
    <img alt="کره مدیا" src="./assets/images/globe.png" className="landing-globe"></img>
</>
}