import React, { useContext, useEffect, useState } from "react";
import ActivityItem from "./ActivityItem";
import { DeleteEntity, GetEntitiesByCategory } from "../../services/SiteEntitiesServices";
import { hideUiEventHandler, CurrentFocusId, setCurrentEditing, showUiEventHandler } from "../../services/EditUiServices";
import { UserContext } from "../../context/UserContext";
import { Permissions } from "../../enums/Permissions";
import EditActivity from "./EditActivity";
import axios from "axios";
import { BackEndUrl } from "../../App";


export default function Activities(props: {data: any[], customName: string, name: string}){
    let [userData, userReducer] = useContext(UserContext);
    let [data, setData] = useState<any[]>([]);

    let [isEditing, setIsEditing] = useState<number | null>(null);

    useEffect(() => {
        let cacheData: any[] = [];

        props.data.forEach((d) => {
            cacheData.push(d);
        });
        setData(cacheData);
    }, [props]);

    function getStatic(id: number){
        return data.filter((d) => d.id == id)[0];
    }

    function switchHandle(){
        hideUiEventHandler(null);
    }

    function editHandle(ev: any){
        setIsEditing(Number(CurrentFocusId))
        setCurrentEditing(CurrentFocusId);

        if (ev != null){
            hideUiEventHandler(ev);
        }
    }

    function closeHandle(){
        setIsEditing(null);
        hideUiEventHandler(null);
    }

    function removeHandle(){
        axios.delete(BackEndUrl + "Centers/Activity", {
            params: {
                id: Number(CurrentFocusId)
            },
            headers: {
                Authorization: "Bearer " + localStorage.getItem("UserToken")
            }
        }).then((res) => {
            alert(res.data.message);
            window.location.reload();
            return;
        });
        return;
    }

    function addHandle(){
        setIsEditing(0);
    }

    function mouseEnterEvent(ev: any, elementId: string){
        if (!userData.IsLoggedIn || !userData.Roles.includes(Permissions.ChangeWebsiteSettings)){
            return;
        }
        showUiEventHandler(ev, elementId, switchHandle, editHandle, removeHandle, null, null);
    }

    return <section className="activities px-5 mt-40 flex flex-row justify-center">
        {
            isEditing != null? <EditActivity closeHandler={closeHandle} id={isEditing == 0? null : isEditing} title={getStatic(isEditing)?.title} icon={getStatic(isEditing)?.description} name={props.name}></EditActivity> : <></>
        }
    <div className="activity-list px-5 grid grid-cols-4 phone:grid-cols-2 max-w-[1200px] gap-5"  dir="rtl">
        <div className="col-span-2 activity-item flex flex-col justify-center border-none rounded-[30px] p-16 phone:p-2">
            <div className="flex flex-row gap-4">
                {
                    userData.IsLoggedIn && (userData.Roles.includes(Permissions.AddArticle) || userData.Roles.includes(Permissions.Owner))? 
                    <div id="submit-button" className="flex flex-col justify-center text-xl px-4 text-center rounded-full hover:cursor-pointer" style={{height: "50px"}} onClick={addHandle}>
                        <i className="fa-solid fa-plus"></i>
                    </div> : <></>
                }
                <h2 className="activities-title text-[38px] text-center phone:text-[32px]">
                    فعالیت‌های <span style={{color: "rgba(121, 200, 215, 1)"}}>{props.customName}</span> در یک نگاه
                </h2>
            </div>
        </div>

        {
            data.map((d) => {
                return <ActivityItem id={d.id} title={d.title} content={d.description} mouseEnterEvent={mouseEnterEvent}></ActivityItem>
            })
        }
    </div>
</section>
}

function setIsEditing(arg0: string) {
    throw new Error("Function not implemented.");
}
