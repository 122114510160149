import React from "react";

export default function CoworkerItem(props: any){
    let lines = "";
    let name = props.name;

    if (props.top && !props.right){
        lines = "coworker-items-r";
    } else if ((props.buttom || props.mid) && !props.right){
        lines = "coworker-items-r coworker-items-t";
    } else if (!props.top) {
        lines = "coworker-items-t";
    }

    if (props.isFirst){
        lines += " col-start-2"
    }

    let className = "col-span-2 flex flex-row justify-center hover:i laptop:py-5 laptop:px-5 " + lines + (props.isAdmin? " coworker-item" : " coworker-item-use")

    if (props.filler){
        if (props.isAdmin == false){
            return <></>
        }
        return  <div className={`${className.replace("flex-row", "flex-col")} text-center`} onClick={(ev) => {props.clickHandler(name)}}>
                    <svg className="svg-inline--fa fa-circle-plus text-5xl" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="circle-plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM240 352c0 8.8 7.2 16 16 16s16-7.2 16-16V272h80c8.8 0 16-7.2 16-16s-7.2-16-16-16H272V160c0-8.8-7.2-16-16-16s-16 7.2-16 16v80H160c-8.8 0-16 7.2-16 16s7.2 16 16 16h80v80z"></path></svg>
                </div>
    }

    return  <div className={className}>
                <img src={props.image} className="coworker-item-image" alt="لوگو"></img>
                <div className="coworker-icon-holder flex flex-row gap-4">
                    <div className="coworker-icon" onClick={(ev) => {props.clickHandler(name)}}>
                        <i className="fa-regular fa-pen text-xl"></i>
                    </div>
                    <div className="coworker-icon" onClick={(ev) => {props.clickHandler(name, true)}}>
                        <i className="fa-regular fa-trash text-xl"></i>
                    </div>
                </div>
            </div>
}