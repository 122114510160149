import axios from 'axios';
import { BackEndUrl } from '../App';

export async function GetNews(category: number | number[] | null){
    if (category == null){
        try {
            let fetchResult = axios.get(BackEndUrl + "Article/GetArticles", {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                }
            });
            let fetData = (await fetchResult).data
            
            return fetData.data;
        } catch (e){
            return null;
        }
    }else {
        try {
            if (typeof category == "number"){
                let fetchResult = axios.get(BackEndUrl + "Article/GetArticles", {
                    params: {
                        categoryId: category
                    },
                    headers:{
                        "Access-Control-Allow-Origin": "*",
                    }
                });
                let fetData = (await fetchResult).data
                
                return fetData.data;
            }

            let categoryQuery = "";

            category.forEach((cat, index) => {
                if (index == category.length - 1){
                    categoryQuery += "categoryIds=" + cat;
                }else{
                    categoryQuery += "categoryIds=" + cat + "&";
                }
            });

            let fetchResult = axios.get(BackEndUrl + `Article/GetArticlesByMultipleCategories?${categoryQuery}`, {
                headers:{
                    "Access-Control-Allow-Origin": "*",
                }
            });
            let fetData = (await fetchResult).data
            
            return fetData.data;
            
        } catch (e){
            return null;
        }
    }
}   


export async function GetCategories(){
    try {
        let fetchResult = axios.get(BackEndUrl + "Article/Category", {
            headers: {
                "Access-Control-Allow-Origin": "*",
            }
        });
        let fetData = (await fetchResult).data
        
        return fetData.data;
    } catch (e){
        return null;
    }
}

export async function GetNewsData(newsId: number){
    try {
        let fetchResult = axios.get(BackEndUrl + "Article", {
            params: {
                articleId: newsId
            },
            headers: {
                "Access-Control-Allow-Origin": "*",
            }
        });
        let fetData = (await fetchResult).data
        
        return fetData.data;
    } catch (e){
        return null;
    }
}

export async function DeleteNews(id: number){
    try{
        let postData = await axios.delete(BackEndUrl + "Article", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("UserToken"),
                "Access-Control-Allow-Origin": "*",
            },
            params: {
                id: id
            }
        });

        return postData.data;
    }catch(e){
        return null;
    }
}

export async function UpdateNews(id: number, title: string, content: string, date: number, categoryId: number, landingImage: File | null){
    try{
        let formData = new FormData();
        formData.append("Id", id.toString());
        formData.append("Title", title);
        formData.append("Content", content);
        if (landingImage){
            formData.append("LandingImage", landingImage);
        }
        formData.append("PublishDate", date.toString());
        formData.append("CategoryId", categoryId.toString());

        let postData = await axios.put(BackEndUrl + "Article", formData, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("UserToken"),
                "Access-Control-Allow-Origin": "*",
            }
        });

        return postData.data;
    }catch(e){
        return null;
    }
}

export async function AddNews(title: string, content: string, date: number, categoryId: number, landingImage: File | null){
    try{
        let formData = new FormData();
        formData.append("Title", title);
        formData.append("Content", content);
        if (landingImage){
            formData.append("LandingImage", landingImage);
        }
        formData.append("PublishDate", date.toString());
        formData.append("CategoryId", categoryId.toString());

        let postData = await axios.post(BackEndUrl + "Article", formData, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("UserToken"),
                "Access-Control-Allow-Origin": "*",
            }
        });

        return postData.data;
    }catch(e){
        return null;
    }
}

export async function AddCategory(name: string){
    try{
        let postData = await axios.post(BackEndUrl + "Article/Category", {
            Name: name
        }, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("UserToken"),
                "Access-Control-Allow-Origin": "*",
            }
        });

        return postData.data.message;
    }catch(e){
        return null;
    }
}

export async function DeleteCategory(id: number){
    try{
        let postData = await axios.delete(BackEndUrl + "Article/Category", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("UserToken"),
                "Access-Control-Allow-Origin": "*",
            },
            params: {
                id: id
            }
        });

        return postData.data.message;
    }catch(e){
        return null;
    }
}

export async function UpdateCategory(id: number, content: string){
    try{
        let postData = await axios.put(BackEndUrl + "Article/Category", {
            "Id": id,
            "Name": content
        }, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("UserToken"),
                "Access-Control-Allow-Origin": "*",
            }
        });

        return postData.data;
    }catch(e){
        return null;
    }
}