import { useEffect, useState } from "react";
import { GetEntity, SetEntity } from "../../services/SiteEntitiesServices";
import React from "react";
import { BackEndUrl } from "../../App";
import axios from "axios";


export default function LandingLinkEdit(props: any){
    let [isLoading, setIsLoading] = useState(false);
    let [title, setTitle] = useState("");
    let [link, setLink] = useState("");

    useEffect(() => {
        setTitle(props.title);
        setLink(props.link);
    }, [props]);

    function submitHandler(){
        if (isLoading){
            return;
        }
        setIsLoading(true);
        let formData = new FormData();
        formData.append("Name", props.name);
        formData.append("Link", link);
        formData.append("LinkTitle", title)
        
        axios.put(BackEndUrl + "Centers", formData, {
            headers: {
                Authorization: "Bearer "  + localStorage.getItem("UserToken")
            }
        }).then((res) => {
            alert(res.data.message);
            window.location.reload();
            return;
        });
    }

    return <section className="popups flex flex-row justify-center py-12 px-72 tablet:px-0">
    <div className="flex flex-col popup bg-white p-8 rounded-15px">
        <div className="popup-content">
            <div className="popup-header flex flex-row-reverse justify-between" style={{cursor: "pointer"}} onClick={props.closeHandle}>
                <h4 className="popup-header-title text-2xl">ویرایش اطلاعات لینک</h4>
                <i className="fa-solid fa-x"></i>
            </div>
            <div className="popup-tabs flex flex-row-reverse justify-start px-4 gap-6 mt-5">
            </div>
            <div className="grid grid-cols-2 gap-6 justify-center mt-4" dir="rtl">
                <div className="popup-input col-span-1 rounded-15px px-2 py-3 my-4">
                    <input type="text" className="w-full h-full text-right" placeholder="نام" value={title} onChange={(ev) => setTitle(ev.target.value)}></input>
                </div>
                <div className="popup-input col-span-1 rounded-15px px-2 py-3 my-4">
                    <input type="text" className="w-full h-full text-right" placeholder="لینک" value={link} onChange={(ev) => setLink(ev.target.value)}></input>
                </div>
            </div>

            <div className="flex flex-row-reverse justify-center gap-4 mt-6">
                <a href="#" className="rounded-30px text-center py-4 px-10 popup-submit text-xs" onClick={() => submitHandler()}>{isLoading? <i className="fa-solid fa-spinner"></i> : <p>انتشار</p>}</a>
                <a href="#" className="rounded-30px text-center py-4 px-10 popup-cancel text-xs" onClick={props.closeHandle}>لغو</a>
            </div>
        </div>
    </div>
</section>
}