import React, { useContext, useEffect, useReducer } from "react";
import { NavbarProfile } from "./NavbarProfile";
import { UserContext } from "../../context/UserContext";
import { HeaderContext } from "../../context/HeaderContext";
import CentersPopup from "./NavbarPopups/CentersPopup";

export default function Navbar(props: any) {
    let [userData, userReduce] = useContext(UserContext);
    let showBeBlue = useContext(HeaderContext);

    return <div className="header flex px-5 flex-row-reverse flex-nowrap tablet:justify-between phone:justify-between py-3">
    <div className="header-title basis-32 align-middle h-16 flex ml-9 flex-col justify-center phone:basis-[110px] phone:ml-0">
        <a href="/" className="flex flex-row-reverse justify-start"><img alt="لوگو راوینو" src="./assets/images/logo.png" className="h-[55px] w-[110px]"></img></a>
    </div>
    <div className="hidden tablet:flex phone:flex flex-col justify-center text-center text-2xl hover:cursor-pointer" onClick={props.openMenu}>
        <i className="fa-solid fa-bars"></i>
    </div>
    <div className="header-news ml-4 basis-8 h-16 text-center align-middle flex flex-col justify-center text-base laptop:text-sm laptop:ml-4 tablet:hidden phone:hidden">
        <a href="/news" className="h-6 title-text" style={{color: props.activeTab == "news"? "#007DC3" : "black"}}>
            اخبار
        </a>
    </div>
    <div className="header-news basis-[76px] h-16 text-center align-middle flex flex-col justify-center text-base laptop:text-sm tablet:hidden phone:hidden">
        <a href="/events" className="h-6 title-text" style={{color: props.activeTab == "events"? "#007DC3" : "black"}}>
        رویدادها
        </a>
    </div>
    <CentersPopup showBeBlue={showBeBlue}></CentersPopup>
    <div className="header-news basis-[159px] ml-4 h-16 text-center align-middle flex flex-col justify-center text-base laptop:text-sm tablet:hidden phone:hidden">
        <a href="/#faq" className="h-6 title-text">
            پرسش‌های متداول
        </a>
    </div>
    <div className="header-news basis-16 h-16 ml-4 text-center align-middle flex flex-col justify-center text-base laptop:text-sm tablet:hidden phone:hidden">
        <a href="/#coworkers" className="h-6 title-text">
            همکاران
        </a>
    </div>
    <div className="header-news basis-20 h-16 ml-40 text-center align-middle flex flex-col justify-center text-base laptop:text-sm tablet:hidden phone:hidden">
        <a href="/#contactus" className="h-6 title-text">
            تماس با ما
        </a>
    </div>
    {
        <NavbarProfile></NavbarProfile>
    }
</div>

    function handlePopup() {
        props.changeCentersPopup();
    }
}