import React, { useContext, useEffect, useRef, useState } from "react";
import { BackEndUrl, StaticFileUrl } from "../../App";
import { UserContext } from "../../context/UserContext";
import { GetEntitiesByCategory, SetEntity } from "../../services/SiteEntitiesServices";
import { CurrentEditId, CurrentFocusId, hideUiEventHandler, setCurrentEditing, showUiEventHandler } from "../../services/EditUiServices";
import { Permissions } from "../../enums/Permissions";
import ReactQuill from "react-quill";
import axios from "axios";
import LandingLinkEdit from "./LandingLinkEdit";

export default function CentersLanding(props: {data: any}){
    let [data, setData] = useState<any>();

    useEffect(() => {
        setData(props.data);
    }, []);


    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': [] }, { 'background': [] }]
        ],
    };

    const formats = [
        'bold', 'italic', 'underline', 'strike', 'color', 'background'
    ];

    let [userData, userReducer] = useContext(UserContext);

    let [title, setTitle] = useState("مرکز گیم");
    let [description, setDescription] = useState("");
    let [image, setImage] = useState(StaticFileUrl + "/Game.png");
    let [linkTitle, setLinkTitle] = useState("دیدن راوی گیم");
    let [linkUrl, setLinkUrl] = useState("#")

    let [editTitle, setEditTitle] = useState("");
    let [editDescription, setEditDescription] = useState("");
    let [editImage, seteditImage] = useState("");
    let [editLinkTitle, setEditLinkTitle] = useState("");
    let [editLinkUrl, setEditLinkUrl] = useState("");

    let editTitleRef = useRef(editTitle);
    let editDescriptionRef = useRef(editDescription);
    let editImageRef = useRef(editImage);
    let editLinkTitleRef = useRef(editLinkTitle);
    let editLinkUrlRef = useRef(editLinkUrl);

    let [isEditing, setIsEditing] = useState("");

    useEffect(() => {
        setTitle(props.data.title);
        setEditTitle(props.data.title);

        setDescription(props.data.description);
        setEditDescription(props.data.description);

        setImage(props.data.image);
        seteditImage(props.data.image);

        setLinkTitle(props.data.linkTitle);
        setEditLinkTitle(props.data.LinkTitle);

        setLinkUrl(props.data.link);
        setEditLinkUrl(props.data.link);
    }, [props]);

    let [rawName, setRawName] = useState("");
    let titleEditorRef: React.MutableRefObject<ReactQuill.UnprivilegedEditor | null> = useRef(null);

    function changeRawName(){
        let name = titleEditorRef.current?.getText();
        setRawName(name || "");
    }

    function changeHandler(content: string){
        switch (CurrentEditId){
            case "landing-title":
                setEditTitle(content);
                break;
            case "landing-description":
                setEditDescription(content);
                break;
            case "landing-link-title":
                setEditLinkTitle(content)
                break;
            case "landing-link-url":
                setEditLinkUrl(content);
                break;
        }
    }

    function changeName(value: string){
        let editedName = value.replace(/ /g , "-");
        editedName = editedName.replace(/\//g, "-");
        editedName = editedName.replace(/\r/g, "");
        editedName = editedName.replace(/\n/g, "");
        console.log(editedName);
        return editedName;
    }

    function handleKeyDown(isSubmit = true){
        if (isSubmit){
            switch (CurrentEditId){
                case "landing-title":
                    var formData = new FormData();
                    formData.append("Name", props.data.name);
                    formData.append("Title", editTitle);
                    formData.append("ActivitiesTitle", rawName);
                    formData.append("NewName", changeName(rawName));
                    formData.append("PopupLink", changeName(rawName));
                    formData.append("PopupLinkTitle", "برو به " + rawName);

                    axios.put(BackEndUrl + "Centers", formData, {
                        headers: {
                            Authorization: "Bearer "  + localStorage.getItem("UserToken")
                        }
                    }).then((res) => {
                        alert(res.data.message);
                        window.location.href = changeName(rawName);
                        return;
                    });
                    break;
                case "landing-description":
                    var formData = new FormData();
                    formData.append("Name", props.data.name);
                    formData.append("Description", editDescription);
                    
                    axios.put(BackEndUrl + "Centers", formData, {
                        headers: {
                            Authorization: "Bearer "  + localStorage.getItem("UserToken")
                        }
                    }).then((res) => {
                        alert(res.data.message);
                        window.location.reload();
                        return;
                    });
                    break
            }
            return;
        }

    }

    useEffect(() => {
        editTitleRef.current = editTitle;
        editDescriptionRef.current = editDescription;
        editLinkTitleRef.current = editLinkTitle;
        editLinkUrlRef.current = editLinkUrl;

        window.addEventListener("keydown", checkButton);

        if (CurrentEditId.includes("landing")){
            let element = document.querySelector(".ql-container") as HTMLElement | null;

            if (element != null){
                element.onmousemove = showUi;
            }
        }

        function checkButton(ev: any){
            if (ev.key == "Escape"){
                handleKeyDown(false);
            }
            if (ev.key == "Enter"){
                handleKeyDown();
            }
        }

        function showUi(ev: any){
            showUiEventHandler(ev, CurrentFocusId, switchHandle, null, null, closeHandle, submitHandle);
        }

        return () => {
            window.removeEventListener("keydown", checkButton);
        };
    }, [editTitle, editDescription, editLinkTitle, editLinkUrl]);

    function switchHandle(){
        hideUiEventHandler(null);
    }

    function closeHandle(){
        setIsEditing("");
        hideUiEventHandler(null);
        setCurrentEditing("");
    }

    function submitHandle(ev: any){
        handleKeyDown(true);
        hideUiEventHandler(ev);
    }

    function editHandle(ev: any){
        if (CurrentFocusId == "landing-image"){
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.click();
      
            input.onchange = async () => {
                const file = input.files ? input.files[0] : null;

                if (file == null){
                    return;
                }

                let formData = new FormData();
                formData.append("Name", props.data.name);
                formData.append("Image", file);

                axios.put(BackEndUrl + "Centers", formData, {
                    headers: {
                        Authorization: "Bearer "  + localStorage.getItem("UserToken")
                    }
                }).then((res) => {
                    alert(res.data.message);
                    window.location.reload();
                    return;
                });
            }
            return;
        }

        setIsEditing(CurrentFocusId);
        setCurrentEditing(CurrentFocusId);

        if (ev != null){
            showUiEventHandler(ev, isEditing, switchHandle, null, null, closeHandle, submitHandle);
        }
    }

    function mouseEnterEvent(ev: any, elementId: string){
        if (!userData.IsLoggedIn || !userData.Roles.includes(Permissions.ChangeWebsiteSettings)){
            return;
        }
        showUiEventHandler(ev, elementId, switchHandle, editHandle, null, null, null);
    }

    return <div className="mt-14 landing-body px-5 phone:mt-2">
            {
                isEditing == "landing-link"? <LandingLinkEdit title={linkTitle} link={linkUrl} name={props.data.name} closeHandle={closeHandle}></LandingLinkEdit> : <></>
            }
            <div className="flex flex-row-reverse mt-6 justify-center phone:flex-col">
                <div className="flex flex-col ml-12 py-7 phone:flex-row phone:justify-center phone:ml-0" onMouseMove={((ev) => {mouseEnterEvent(ev, "landing-image")})}>
                    <img alt="مرکز گیم راوینو" className="h-[150px], w-[150px] phone:h-[100px] phone:w-[100px]" src={image}></img>
                </div>
                <div className="flex flex-col justify-center w-[680px] gap-4 phone:w-auto" dir="rtl">
                {
                    isEditing == "landing-title"? <div id="landing-text-editor"><ReactQuill
                    value={editTitle}
                    onChange={(newContent: any, delta: any, source: any, editor: ReactQuill.UnprivilegedEditor) => {changeHandler(newContent); titleEditorRef.current = editor; changeRawName()}}
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    /></div> : 
                    <h1 className="text-5xl align-middle text-right py-1 text-[36px] phone:text-center" onMouseMove={(ev) => {mouseEnterEvent(ev, "landing-title")}} id="landing-header">
                        <div dangerouslySetInnerHTML={{ __html: title }} />
                    </h1>
                }
                {
                    isEditing == "landing-description"? <div id="landing-text-editor"><ReactQuill
                    value={editDescription}
                    onChange={(newContent) => {changeHandler(newContent)}}
                    modules={modules}
                    formats={formats}
                    theme="snow"
                  /></div> :
                    <p className="text-base align-middle text-right py-1 mt-2" id="landing-content-film" onMouseMove={(ev) => {mouseEnterEvent(ev, "landing-description")}}>
                        <div dangerouslySetInnerHTML={{ __html: description }} />
                    </p>
                }
                    
                    <div className="py-1 w-40 flex flex-row">
                        <i className="fa-solid fa-link py-2 text-2xl ml-2" style={{color: "#79C8D7"}}></i>
                        <a href={linkUrl} id="landing-video-text" className="text-center text-sm" onMouseMove={(ev) => mouseEnterEvent(ev, "landing-link")}>
                            {linkTitle}
                        </a>
                    </div>
                </div>
            </div>
        </div>
}