import React, { useContext, useRef } from "react";
import { UserContext } from "../../context/UserContext";
import { Permissions } from "../../enums/Permissions";

export default function StaticsItem(props: any){
    let [userData, userReducer] = useContext(UserContext);
    let element = useRef<HTMLDivElement>(null);

    let className = userData.Roles.includes(Permissions.ChangeWebsiteSettings)? "px-10 w-[332px] py-3 flex flex-col phone:px-2 phone:col-span-1 phone:w-auto gap-6 justify-center statics-item statics-item-remove" + (props.putRight == false? " border-0" : "") : "px-10 w-[332px] py-3 flex flex-col phone:px-2 phone:col-span-1 phone:w-auto gap-6 justify-center statics-item" + (props.putRight == false? " border-0" : "");

    function trashEnter(){

    }

    function addHandle(){
        props.addHandle();
    }

    if (props.id != 0){
        return  <div ref={element} className={className} style={{cursor: "pointer"}} dir="rtl" onMouseMove={(ev) => {props.id? props.mouseEnterEvent(ev, props.id) : trashEnter()}}>
                <i className={"fa-regular text-5xl text-center statics-item-icon fa-" + props.icon}></i>
                <p className="text-center text-2xl phone:text-xl statics-item-text">{props.value}</p>
            </div>
    }else{
        return  <div ref={element} className={className} style={{cursor: "pointer"}} dir="rtl" onClick={addHandle}>
                <i className={"fa-regular text-5xl text-center statics-item-icon fa-plus"}></i>
                <p className="text-center text-2xl phone:text-xl statics-item-text">{props.value}</p>
            </div>
    }

    
}