import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BackEndUrl } from "../../App";

export default function NavbarPhone(props: any){
    let [userData, userReduce] = useContext(UserContext);

    let [centersData, setCentersData] = useState([]);

    useEffect(() => {
        axios.get(BackEndUrl + "Centers/Popups", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("UserToken")
            }
        }).then((res) => {
            setCentersData(res.data.data);
        });
    }, [])

    function logout(){
        localStorage.removeItem("UserToken");
        window.location.reload();
    }

    let navigate = useNavigate();

    return <div className="phone-menu flex flex-col gap-8">
    <div className="phone-menu-exit" onClick={props.closeMenu}>
        <i className="fa-regular fa-x"></i>
    </div>
    <div className="phone-menu-logo" onClick={props.closeMenu}>
    <a href="/" className="flex flex-row-reverse justify-start"><img alt="لوگو راوینو" src="./assets/images/logo.png" className="h-[55px] w-[110px]"></img></a>
    </div>
    <div className="flex flex-row justify-center phone-menu-content">
        <a href="/News" className="h-6 title-text text-center" onClick={props.closeMenu}>
            اخبار
        </a>
    </div>
    <div className="flex flex-row justify-center" onClick={props.closeMenu}>
        <a href="/Events" className="h-6 title-text text-center">
        رویدادها
        </a>
    </div>
    {
        centersData.map((center: any) => {
            return <div className="flex flex-row justify-center" onClick={props.closeMenu}>
            <a href={center.link} className="h-6 title-text text-center">
                {center.title}
            </a>
        </div>
        })
    }
    <div className="flex flex-row justify-center" onClick={props.closeMenu}>
        <a href="/#coworkers" className="h-6 title-text text-center">
            همکاران ما
        </a>
    </div>
    <div className="flex flex-row justify-center" onClick={props.closeMenu}>
        <a href="/#contactus" className="h-6 title-text text-center">
            تماس با ما
        </a>
    </div>
    <div className="flex flex-row justify-center" onClick={props.closeMenu}>
        <a href="/#faq" className="h-6 title-text text-center">
            سوالات متداول
        </a>
    </div>
    {
        userData.IsLoggedIn? <div className="flex flex-row justify-center">
                                <a href="#" className="h-6 title-text text-center" onClick={logout}>
                                    خوش آمدی {userData.FirstName + " " + userData.LastName} (خروج)
                                </a>
                            </div> : <div className="flex flex-row justify-center">
                                            <a href="#" className="h-6 title-text text-center" onClick={() => {navigate("/Auth")}}>
                                                ورود/ثبت نام
                                            </a>
                                        </div>

    }
    </div>
    
}