import React, { useContext, useEffect, useRef, useState } from "react";
import LandingSliderItem from "./LandingSliderItem";
import { GetEntity, SetEntity } from "../../services/SiteEntitiesServices";
import { UserContext } from "../../context/UserContext";
import { Permissions } from "../../enums/Permissions";
import axios from "axios";
import { BackEndUrl, StaticFileUrl } from "../../App";


export default function LandingSlider(props: {data: any[], name: string}) {
  let [userData, userReducer] = useContext(UserContext);
  let [data, setData] = useState<any[]>([]);

  useEffect(() => {
    setData(props.data);
  }, [props]);

  const scrollContainer = useRef<HTMLDivElement>(null);
  const scrollSpeed = 0.01;

  useEffect(() => {
    const scrollContainerElement = scrollContainer.current;

    if (!scrollContainerElement) return;

    const scroll = () => {

      let preScrollLeft = scrollContainerElement.scrollLeft;

      scrollContainerElement.scrollLeft -= scrollSpeed;
      if (scrollContainerElement.scrollLeft == preScrollLeft){
        scrollContainerElement.scrollLeft = 0;
      }
    };

    
    const intervalId = setInterval(scroll, 20);
    

    return () => clearInterval(intervalId);
  }, []);

  function addSlider(){
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click() 

    input.onchange = async () => {
        const file = input.files ? input.files[0] : null;

        if(file != null){
          let cacheData = [...data];

          let formData = new FormData();

          formData.append("Image", file);
          formData.append("Name", props.name);

          axios.post(BackEndUrl + "Centers/Slider", formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("UserToken"),
              "Access-Control-Allow-Origin": "*",
            }
          }).then((res) => {
            alert(res.data.message);
            window.location.reload();
            return;
          });
        }
    }
  }

  return (
    <section className="main-film-slider py-[50px] mt-10 phone:mt-2 phone:py-4">
      <div
        ref={scrollContainer}
        className="main-film-slider-container flex flex-row-reverse gap-10"
        id="slider-container"
      >
        {
            data.map((d, index) => {
                return <LandingSliderItem name={props.name} id={d.id} image={StaticFileUrl + "/" + d.image} />
            })
        }
        {
          userData.Roles.includes(Permissions.ChangeWebsiteSettings)? <div className="flex-none slider flex flex-col justify-center text-center" style={{border: "solid 1px #CCCCCC", borderRadius: "15px", cursor: "pointer", color: "black", width: "400px"}} onClick={addSlider}>
          <i className="fa-solid fa-plus text-5xl"></i>
        </div> : <></>
        }
      </div>
    </section>
  );
}
