import { DatePicker } from "jalaali-react-date-picker";
import React, { useEffect, useState } from "react";
import { AddEvent, UpdateEvent } from "../../services/EventsServices";

const jalaliDate = require("jalali-date");


export default function EditEvent(props: any){
    let [isLoading, setIsLoading] = useState(false);
    let [title, setTitle] = useState("");
    let [date, setDate] = useState(new jalaliDate());
    let [location, setLocation] = useState("");
    let [image, setImage] = useState<File | null>(null);
    let [id, setId] = useState<number | null>(null);
    let [link, setLink] = useState("");

    let [showDatePicker, setShowDatePicker] = useState(false);

    function changeHandler(content: string, identify: number){
        switch (identify){
            case 0:
                setTitle(content);
                break;
            case 1:
                let dateSplit = content.split("-");
                console.log(new jalaliDate(Number(dateSplit[0]), Number(dateSplit[1]), Number(dateSplit[2]))._d.getTime());
                setDate(new jalaliDate(Number(dateSplit[0]), Number(dateSplit[1]), Number(dateSplit[2])));
                break;
            case 2:
                setLocation(content);
                break;
            case 3:
                setLink(content);
                break;
        }
    }

    useEffect(() => {
        if (props.id != null){
            setTitle(props.title);
            setLocation(props.location);
            setDate(new jalaliDate(props.date));
            setId(props.id);
            setLink(props.link);
        }
    }, []);

    function submitHandler(){
        if (isLoading){
            return;
        }
        setIsLoading(true);
        if (title == "" || location == ""){
            alert("لطفا فیلد‌ها را کامل پر کنید");
            return;
        }
        
        if (id != null){
            UpdateEvent(id, title, location, new Date(date._d.getTime() - new Date().getTimezoneOffset() * 60 * 1000), image, link == ""? "events" : link).then((res) => {
                setIsLoading(false);
                if (res == null){
                    alert("مشکلی در ویرایش رویداد به وجود امد");
                    return;
                }
    
                window.location.reload();
            });
            return;
        }

        if (image == null){
            alert("لطفا عکس را بارگذاری کنید");
            return;
        }

        AddEvent(title, location, new Date(date._d.getTime() - new Date().getTimezoneOffset() * 60 * 1000), image, link == ""? "events" : link).then((res) => {
            setIsLoading(false);
            if (res == null){
                alert("مشکلی در ساخت رویداد به وجود امد");
                return;
            }

            window.location.reload();
        })
    }

    function selectFileHandler(){
        if (image == null){
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.click();
      
            input.onchange = async () => {
                const file = input.files ? input.files[0] : null;
                setImage(file);
            }
        }else{
            setImage(null);
        }
        
    }

    return <section className="popups flex flex-row justify-center py-12 px-72 tablet:px-0">
        <div className="flex flex-col popup bg-white p-8 rounded-15px">
            <div className="popup-content">
                <div className="popup-header flex flex-row-reverse justify-between" style={{cursor: "pointer"}} onClick={props.closeHandler}>
                    <h4 className="popup-header-title text-2xl">افزودن رویداد</h4>
                    <i className="fa-solid fa-x"></i>
                </div>
                <div className="popup-tabs flex flex-row-reverse justify-start px-4 gap-6 mt-5">
                </div>
                <div className="grid grid-cols-2 gap-6 justify-center mt-4" dir="rtl">
                    <div className="popup-input col-span-1 rounded-15px px-2 py-3 my-4">
                        <input type="text" className="w-full h-full text-right" placeholder="عنوان رویداد" value={title} onChange={(ev) => changeHandler(ev.target.value, 0)}></input>
                    </div>
                    <div className="popup-input col-span-1 rounded-15px px-2 py-3 my-4">
                        <input type="text" className="w-full h-full text-right" placeholder="محل رویداد" value={location} onChange={(ev) => changeHandler(ev.target.value, 2)}></input>
                    </div>
                    <div className="popup-input col-span-1 rounded-15px px-2 py-3 my-4">
                        <input type="text" className="w-full h-full text-right" placeholder="تاریخ رویداد" value={date.format("YYYY MMM DD")} onClick={(ev) => {setShowDatePicker(!showDatePicker)}}></input>
                    </div>
                    <div className="col-span-2" dir="rtl">
                        {showDatePicker? <DatePicker onChange={(date, dateString) => {changeHandler(dateString, 1); setShowDatePicker(!showDatePicker)}}></DatePicker> : <></>}
                    </div>
                    <div className="popup-input col-span-1 rounded-15px px-2 py-3 my-4">
                        <input type="text" className="w-full h-full text-right" placeholder="لینک رویداد" value={link} onChange={(ev) => changeHandler(ev.target.value, 3)}></input>
                    </div>
                </div>
                <div className="flex flex-col gap-4 mt-6 px-5">
                    <h3 className="text-sm">تصویر رویداد</h3>
                    <div className="grid grid-cols-2" dir="rtl">
                        <div className="popup-input col-span-1 rounded-15px px-2 py-3 my-4">
                            <input className="w-full h-full text-right" style={{cursor: "pointer"}} placeholder="انتخاب فایل" value={image?.name || "انتخاب کنید..."} onClick={(ev) => {ev.preventDefault(); selectFileHandler()}}></input>
                        </div>
                    </div>
                </div>

                <div className="flex flex-row-reverse justify-center gap-4 mt-6">
                    <a href="#" className="rounded-30px text-center py-4 px-10 popup-submit text-xs" onClick={(ev) => {ev.preventDefault(); submitHandler()}}>{isLoading? <i className="fa-solid fa-spinner"></i> : <p>انتشار</p>}</a>
                    <a href="#" className="rounded-30px text-center py-4 px-10 popup-cancel text-xs" onClick={props.closeHandler}>لغو</a>
                </div>
            </div>
        </div>
    </section>
}