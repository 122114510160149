import React, { useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { BackEndUrl, StaticFileUrl } from "../../App";
import "jalaali-react-date-picker/lib/styles/index.css";
import { DatePicker } from "jalaali-react-date-picker";
import { AddCategory, AddNews, DeleteCategory, GetCategories, UpdateCategory, UpdateNews } from "../../services/NewsServices";
import moment from "moment-jalaali";
import CustomUploadAdapterPlugin from "./UplaodAdaptor";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import "./EditorStyle.css";
import {
	ClassicEditor,
	AccessibilityHelp,
	Alignment,
	Autoformat,
	AutoImage,
	AutoLink,
	Autosave,
	BalloonToolbar,
	BlockQuote,
	Bold,
	CKBox,
	CloudServices,
	Code,
	CodeBlock,
	Essentials,
	FindAndReplace,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	Heading,
	Highlight,
	HorizontalLine,
	ImageBlock,
	ImageCaption,
	ImageInline,
	ImageInsert,
	ImageInsertViaUrl,
	ImageResize,
	ImageStyle,
	ImageTextAlternative,
	ImageToolbar,
	ImageUpload,
	Indent,
	IndentBlock,
	Italic,
	Link,
	LinkImage,
	List,
	ListProperties,
	Markdown,
	MediaEmbed,
	Paragraph,
	PasteFromMarkdownExperimental,
	PasteFromOffice,
	PictureEditing,
	RemoveFormat,
	SelectAll,
	SpecialCharacters,
	SpecialCharactersArrows,
	SpecialCharactersCurrency,
	SpecialCharactersEssentials,
	SpecialCharactersLatin,
	SpecialCharactersMathematical,
	SpecialCharactersText,
	Strikethrough,
	Subscript,
	Superscript,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	TextTransformation,
	TodoList,
	Underline,
	Undo,
    Mention
} from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';

const editorConfig = {
	toolbar: {
		items: [
			'undo',
			'redo',
			'|',
			'heading',
			'|',
			'fontSize',
			'fontFamily',
			'fontColor',
			'fontBackgroundColor',
			'|',
			'bold',
			'italic',
			'underline',
			'|',
			'link',
			'insertImage',
			'insertTable',
			'highlight',
			'blockQuote',
			'codeBlock',
			'|',
			'alignment',
			'|',
			'bulletedList',
			'numberedList',
			'todoList',
			'indent',
			'outdent'
		],
		shouldNotGroupWhenFull: false
	},
	plugins: [
		AccessibilityHelp,
		Alignment,
		Autoformat,
		AutoImage,
		AutoLink,
		Autosave,
		BalloonToolbar,
		BlockQuote,
		Bold,
		//CKBox,
		CloudServices,
		Code,
		CodeBlock,
		Essentials,
		FindAndReplace,
		FontBackgroundColor,
		FontColor,
		FontFamily,
		FontSize,
		Heading,
		Highlight,
		HorizontalLine,
		ImageBlock,
		ImageCaption,
		ImageInline,
		ImageInsert,
		ImageInsertViaUrl,
		ImageResize,
		ImageStyle,
		ImageTextAlternative,
		ImageToolbar,
		ImageUpload,
		Indent,
		IndentBlock,
		Italic,
		Link,
		LinkImage,
		List,
		ListProperties,
		MediaEmbed,
		Paragraph,
		PasteFromMarkdownExperimental,
		PasteFromOffice,
		PictureEditing,
		RemoveFormat,
		SelectAll,
		SpecialCharacters,
		SpecialCharactersArrows,
		SpecialCharactersCurrency,
		SpecialCharactersEssentials,
		SpecialCharactersLatin,
		SpecialCharactersMathematical,
		SpecialCharactersText,
		Strikethrough,
		Subscript,
		Superscript,
		Table,
		TableCaption,
		TableCellProperties,
		TableColumnResize,
		TableProperties,
		TableToolbar,
		TextTransformation,
		TodoList,
		Underline,
		Undo
	],
	balloonToolbar: ['bold', 'italic', '|', 'link', 'insertImage', '|', 'bulletedList', 'numberedList'],
	fontFamily: {
		supportAllValues: true
	},
	fontSize: {
		options: [10, 12, 14, 'default', 18, 20, 22],
		supportAllValues: true
	},
	heading: {
		options: [
			{
                model: "paragraph",
				title: 'Paragraph',
				class: 'ck-heading_paragraph'
			},
			{
				model: 'heading1',
				view: 'h1',
				title: 'Heading 1',
				class: 'ck-heading_heading1'
			},
			{
				model: 'heading2',
				view: 'h2',
				title: 'Heading 2',
				class: 'ck-heading_heading2'
			},
			{
				model: 'heading3',
				view: 'h3',
				title: 'Heading 3',
				class: 'ck-heading_heading3'
			},
			{
				model: 'heading4',
				view: 'h4',
				title: 'Heading 4',
				class: 'ck-heading_heading4'
			},
			{
				model: 'heading5',
				view: 'h5',
				title: 'Heading 5',
				class: 'ck-heading_heading5'
			},
			{
				model: 'heading6',
				view: 'h6',
				title: 'Heading 6',
				class: 'ck-heading_heading6'
			}
		]
	},
	image: {
		toolbar: [
			'toggleImageCaption',
			'imageTextAlternative',
			'|',
			'imageStyle:inline',
			'imageStyle:wrapText',
			'imageStyle:breakText',
			'|',
			'resizeImage'
		]
	},
	link: {
		addTargetToExternalLinks: true,
		defaultProtocol: 'https://',
		decorators: {
			toggleDownloadable: {
				mode: 'manual',
				label: 'Downloadable',
				attributes: {
					download: 'file'
				}
			}
		}
	},
	list: {
		properties: {
			styles: true,
			startIndex: true,
			reversed: true
		}
	},
	menuBar: {
		isVisible: true
	},
	placeholder: 'Type or paste your content here!',
	table: {
		contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
	}
};


const jalaliDate = require("jalali-date");

export default function NewsEdit(props: any){
    let [isLoading, setIsLoading] = useState(false);
    let [currentTab, setCurrentTab] = useState(0);

    let [showDatePicker, setShowDatePicked] = useState(false);

    let [newsId, setNewsId] = useState<number | null>(props.id);
    let [newsContent, setNewsContent] = useState(props.content);
    let [newsTitle, setNewsTitle] = useState(props.title);
    let [newsCategory, setNewsCategory] = useState(props.category);
    let [newsDate, setNewsDateCategory] = useState(props.date);

    let [editValue, setEditValue] = useState("");
    let [editCategory, setEditCategory] = useState(0);
    let [editTitle, setEditTitle] = useState("");
    let [editDate, setEditDate] = useState(new jalaliDate());
    let [editLandingImage, setEditLandingImage] = useState<File | null>(null);

    let editorRef = useRef<any>();

    let [addCategory, setAddCategory] = useState("");


    let [categories, setCategories] = useState([{
        id: -1,
        name: "Loading",
        isActive: true
    }]);

    useEffect(() => {
        if (newsContent){
            setEditValue(newsContent);
        }
        if (newsTitle){
            setEditTitle(newsTitle);
        }
        if (newsDate){
            setEditDate(new jalaliDate(new Date(newsDate * 1000)))
        }

        GetCategories().then((res: any[]) => {
            if (res == null){
                return;
            }

            let setData: {
                id: number,
                name: string,
                isActive: boolean
            }[] = [];

            res.forEach((category) => {
                setData.push({
                    id: category.id,
                    name: category.name,
                    isActive: category.id == newsCategory
                });
            });

            if (newsCategory){
                let cacheCategories = [...setData];
                setCategories(cacheCategories.map((category) => {
                    if (category.id == newsCategory){
                        return {...category, isActive: true}
                    }
                    return category;
                }))
                setCategories(cacheCategories);
            }

            setCategories(setData);
        });
    }, []);

    function getDate(){
        let date = editDate?.format('dddd DD MMMM YYYY');
        return date;
    }

    function closeWindow(){
        setNewsContent("");
        setNewsCategory(0);
        setNewsTitle("");
        setNewsId(null);
        setEditCategory(0);
        setEditTitle("");
        setEditValue("");
        setEditLandingImage(null);
        setAddCategory("");
        setEditDate(new jalaliDate());

        // Ensure categories reset correctly
        setCategories([{
            id: -1,
            name: "Loading",
            isActive: true
        }]);

        props.closeCallback();
    }

    function changeHandler(newContent: string){
        setEditValue(newContent);
    }

    function submitHandle(){
        if (isLoading){
            return;
        }
        setIsLoading(true);
        let content: string = editorRef.current.editor.getData();
        let title: string = editTitle;
        let date = editDate;
        let category: number = categories.find((item) => item.isActive)?.id || 0;
        let file: File | null = editLandingImage;

        if (content == "<p></p>" || title == "" || category == 0){
            alert("لطفا تمام فیلد‌ها را پر کنید");
            setIsLoading(false);
            return;
        }

        if (newsId){
            UpdateNews(newsId, title, content, Math.floor(date._d.getTime() / 1000 - new Date().getTimezoneOffset() * 60), category, file).then((res) => {
                setIsLoading(false);
                if (res == null){
                    alert("مشکلی در ویرایش به وجود امد");
                    setIsLoading(false);
                    return;
                }
    
                closeWindow();
                window.location.reload();
            });
            return;
        }

        AddNews(title, content, Math.floor(date._d.getTime() / 1000 - new Date().getTimezoneOffset() * 60), category, file).then((res) => {
            setIsLoading(false);
            if (res == null){
                alert("مشکلی در ساخت به وجود امد");
                setIsLoading(false);
                return;
            }

            closeWindow();
            window.location.reload();
        });
    }

    function addCategoryChangeHandler(content: string){
        setAddCategory(content);
    }

    function dateChangeHandler(date: any, dateString: string){
        let splitDate = dateString.split("-");
        let dateHolder = new jalaliDate(Number(splitDate[0]), Number(splitDate[1]), Number(splitDate[2]));

        setEditDate(dateHolder);
        setShowDatePicked(false);
    }

    function titleChangeHandler(content: string){
        setEditTitle(content);
    }

    function selectCategoryHadler(id: number){
        let categoriesCache = [...categories];

        categoriesCache.forEach((category, index) => {
            if (category.id == id){
                categoriesCache[index].isActive = true;
            }else{
                categoriesCache[index].isActive = false;
            }
        });

        setCategories(categoriesCache);
    }

    function fileChangeHandler(file: File){
        setEditLandingImage(file);
    }

    function editCategoryHandler(id: number){
        let selectedCategory: any = null;

        categories.forEach((category, index) => {
            if (category.id == id){
                selectedCategory = category;
            }
        });

        UpdateCategory(id, selectedCategory.name).then((res) => {
            if (res == null){
                alert("مشکلی در ویرایش دسته بندی پیش امد");
                window.location.reload();
                return;
            }

        })
    }

    function categoryChangeHandler(id: number, content: string){
        let categoriesCache = [...categories];

        categoriesCache.forEach((category, index) => {
            if (category.id == id){
                categoriesCache[index].name = content;
            }
        });

        setCategories(categoriesCache);
    }

    function deleteCategoryHandler(id: number){
        DeleteCategory(id).then((res) => {
            if (res == null){
                alert("مشکلی در حذف دسته بندی پیش امد");
                return;
            }

            GetCategories().then((res: any[]) => {
                if (res == null){
                    return;
                }
    
                let setData: {
                    id: number,
                    name: string,
                    isActive: boolean
                }[] = [];
    
                res.forEach((category) => {
                    setData.push({
                        id: category.id,
                        name: category.name,
                        isActive: category.id == newsCategory
                    });
                });
    
                setCategories(setData);
            });
        })
    }

    function addCategorySubmitHandler(en: any){
        AddCategory(addCategory).then((res)=> {
            if (res == null){
                alert("مشکلی در حذف دسته بندی پیش امد");
                return;
            }

            GetCategories().then((res: any[]) => {
                if (res == null){
                    return;
                }
    
                let setData: {
                    id: number,
                    name: string,
                    isActive: boolean
                }[] = [];
    
                res.forEach((category) => {
                    setData.push({
                        id: category.id,
                        name: category.name,
                        isActive: category.id == newsCategory
                    });
                });
    
                setCategories(setData);
            });
        })

        setAddCategory("");
    }

    let editValueCache = editValue;


    if (currentTab == 0){
        return <section className="popups flex flex-row justify-center py-12 px-72 tablet:px-0">
                    <div className="flex flex-col popup bg-white p-8 rounded-15px tablet:w-full">
                        <div className="popup-content w-full">
                            <div className="popup-header flex flex-row-reverse justify-between" onClick={(ev) => {closeWindow()}}>
                                <h4 className="popup-header-title text-2xl tablet:text-lg">افزودن خبر</h4>
                                <i className="fa-solid fa-x" style={{cursor: "pointer"}}></i>
                            </div>
                <div className="popup-tabs flex flex-row-reverse justify-start tablet:justify-center px-4 target:px-0 gap-6 mt-5">
                    <a href="#" className="py-3 px-5 popup-active-tab" onClick={() => setCurrentTab(0)}>پست</a>
                    <a href="#" className="py-3 px-5" onClick={() => setCurrentTab(1)}>مدیریت دسته بندی</a>
                </div>
                <div className="grid grid-cols-2 gap-6 justify-center mt-4">
                    <div className="au-input col-span-1 rounded-15px px-2 py-2 my-4">
                        <input type="file" className="w-full h-full text-right" placeholder={(editLandingImage? "یک فایل انتخاب شده" : "انتخاب فایل") + ":عکس خبر"} onChange={(ev) => {ev.target.files? fileChangeHandler(ev.target.files[0]) : ev.preventDefault()}}></input>
                    </div>
                    <div className="au-input col-span-1 rounded-15px px-2 py-2 my-4">
                        <input type="text" className="w-full h-full text-right" placeholder="عنوان خبر" value={editTitle} onChange={(ev) => {titleChangeHandler(ev.target.value)}}></input>
                    </div>
                    <div className="au-input col-span-1 rounded-15px px-2 py-2 my-4 col-start-2">
                        <input type="text" className="w-full h-full text-right" placeholder="تاریخ خبر" onClick={() => {setShowDatePicked(true)}} value={getDate()}></input>
                    </div>
                    <div className="col-span-2" dir="rtl">
                        {showDatePicker? <DatePicker defaultValue={moment(editDate._d.toDateString())} onChange={dateChangeHandler}></DatePicker> : <></>}
                    </div>
                </div>
                <div className="flex flex-col gap-4">
                    <h3 className="text-sm">انتخاب دسته بندی</h3>
                    <div className="flex gap-4 flex-wrap" dir="rtl">
                        {
                            categories.map((category: any) => {
                                return <a href="#" className={category.isActive? "rounded-30px text-center py-2 px-6 popup-category text-xs popup-category-active" : "rounded-30px text-center py-2 px-6 popup-category text-xs"} onClick={(ev) => {selectCategoryHadler(category.id); ev.preventDefault()}}>{category.name}</a>
                            })
                        }
                    </div>
                </div>
                <div className="py-2 px-2 mt-4 popup-text-editor rounded-30px ck_rtl" id="articles-text-editor" dir="rtl">
                <CKEditor
                    editor={ ClassicEditor }
                    ref={editorRef}
                    config={ {
                        
                        ...editorConfig,
                        extraPlugins: [
                            CustomUploadAdapterPlugin
                        ],
                        link: {
                            addTargetToExternalLinks: true,
		                    defaultProtocol: 'https://',
		                    decorators: {
		                    	toggleDownloadable: {
		                    		mode: 'manual',
		                    		label: 'Downloadable',
		                    		attributes: {
		                    			download: 'file'
		                    		}
		                    	}
		                    }
                        },
                        heading: {
                            options: [
                                {
                                    model: "paragraph",
                                    title: 'Paragraph',
                                    class: 'ck-heading_paragraph'
                                },
                                {
                                    model: 'heading1',
                                    view: 'h1',
                                    title: 'Heading 1',
                                    class: 'ck-heading_heading1'
                                },
                                {
                                    model: 'heading2',
                                    view: 'h2',
                                    title: 'Heading 2',
                                    class: 'ck-heading_heading2'
                                },
                                {
                                    model: 'heading3',
                                    view: 'h3',
                                    title: 'Heading 3',
                                    class: 'ck-heading_heading3'
                                },
                                {
                                    model: 'heading4',
                                    view: 'h4',
                                    title: 'Heading 4',
                                    class: 'ck-heading_heading4'
                                },
                                {
                                    model: 'heading5',
                                    view: 'h5',
                                    title: 'Heading 5',
                                    class: 'ck-heading_heading5'
                                },
                                {
                                    model: 'heading6',
                                    view: 'h6',
                                    title: 'Heading 6',
                                    class: 'ck-heading_heading6'
                                }
                            ]
                        },
                        initialData: editValue,
                    } }
                    data={editValue}
                    onChange={(ev, editor) => {
                        changeHandler(editor.getData());
                    }}
                />
                </div>
                <div className="flex flex-row-reverse justify-center gap-4 mt-6">
                    <a href="#" className="rounded-30px text-center py-4 px-10 popup-submit text-xs" onClick={(ev) => {ev.preventDefault(); submitHandle()}}>{isLoading? <i className="fa-solid fa-spinner"></i> : <p>انتشار</p>}</a>
                    <a href="#" className="rounded-30px text-center py-4 px-10 popup-cancel text-xs" onClick={(ev) => {ev.preventDefault(); closeWindow();}}>لغو</a>
                </div>
            </div>
        </div>
    </section>
    }

    return <section className="popups flex flex-row justify-center py-12 px-72 tablet:px-0">
        <div className="flex flex-col popup bg-white p-8 rounded-15px tablet:w-full">
    <div className="popup-content w-full">
        <div className="popup-header flex flex-row-reverse justify-between" onClick={(ev) => {props.closeCallback()}}>
            <h4 className="popup-header-title text-2xl tablet:text-lg">افزودن خبر</h4>
            <i className="fa-solid fa-x" style={{cursor: "pointer"}}></i>
        </div>
        <div className="popup-tabs flex flex-row-reverse justify-start tablet:justify-center px-4 target:px-0 gap-6 mt-5">
            <a href="#" className="py-3 px-5" onClick={() => setCurrentTab(0)}>پست</a>
            <a href="#" className="py-3 px-5 popup-active-tab" onClick={() => setCurrentTab(1)}>مدیریت دسته بندی</a>
        </div>
        <div className="flex flex-col gap-4 mt-6">
                    <h3 className="text-sm category-edit-header">مدیریت دسته بندی</h3>
                    <div className="flex gap-4 flex-wrap" dir="rtl">
                        {
                            categories.map((category) => {
                                return  <div className="flex flex-row popup-category-edit rounded-30px py-1 gap-2 px-2 basis-1/4">
                                            <input type="text" className="rounded-30px text-center py-1 px-2 text-xs popup-category-edit-input" value={category.name} onChange={(ev) => {categoryChangeHandler(category.id, ev.target.value)}}></input>
                                            <a href="#" className="flex flex-col justify-center" onClick={(ev) => {editCategoryHandler(category.id)}}>
                                                <i className="fa-regular fa-pen"></i>
                                            </a>
                                            <a href="#" className="flex flex-col justify-center" onClick={(ev) => {deleteCategoryHandler(category.id)}}>
                                                <i className="fa-regular fa-trash"></i>
                                            </a>
                                        </div>
                            })
                        }
                    </div>
                </div>
        <div className="flex flex-col gap-4 mt-4">
            <h3 className="text-sm category-edit-header">ایجاد دسته بندی</h3>
            <div className="flex gap-4 flex-wrap" dir="rtl">
                <div className="flex flex-row popup-category-edit rounded-30px py-1 gap-2 px-2 basis-1/4">
                    <input type="text" className="rounded-30px text-center py-1 px-2 text-xs popup-category-edit-input" placeholder="نام دسته بندی را وارد کنید" value={addCategory} onChange={(ev) => {addCategoryChangeHandler(ev.target.value)}}></input>
                </div>
                <a href="#" className="rounded-30px text-center py-2 px-6 popup-category-active text-xs" onClick={addCategorySubmitHandler}>افزودن</a>
            </div>
        </div>
    </div>
</div>
    </section>
    
}