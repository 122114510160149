import React from "react";
import { useNavigate } from "react-router-dom";
import { StaticFileUrl } from "../../App";

export default function NewsItems(props: any){
    let id = props.id;

    let Navigate = useNavigate();

    function clickHandle(){
        Navigate("/NewsData?id=" + id, {
            state: {
                id: id
            }
        });
    }

    if (props.headerImage == null){
        return <div className={`col-span-${props.span} news-item tablet:col-span-1`} style={{cursor: "pointer", height: "250px"}} onClick={clickHandle} onMouseMove={props.onMouseMove}>
        <div className="flex flex-row justify-start" style={{width: "100%", height: "100%"}}>
            <div className="rounded-30px w-full"  style={{cursor: "pointer", backgroundColor: `rgba(200, 200, 200, 1)`, backgroundSize: "cover", backgroundPosition: "cover"}}>
                <div className="flex flex-col justify-around news-overlay py-4 px-5 border-[1px] h-[275px] rounded-30px w-full">
                    <div className="max-w-20 h-8 news-category rounded-full flex flex-col justify-center">
                        <p>{props.category}</p>
                    </div>
                    <div className="py-5 flex flex-row gap-4">
                        {
                            props.content?.includes("<img ")? <div className="flex flex-col text-2xl justify-center">
                            <i className="fa-solid fa-clone"></i>
                        </div> : <></>
                        }
                        <p className="news-content text-[22px] text-right" style={{color: "white"}}>
                            {props.title}
                        </p>
                    </div>
                    <div className="flex flex-row mt-4">
                        <i className="fa-regular fa-calendar news-date-icon text-xl ml-2" style={{color: "white"}}></i>
                        <p className="news-date" style={{color: "white"}}>{props.date}</p>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    }
    return <div className={`col-span-${props.span} news-item tablet:col-span-1`} style={{cursor: "pointer", height: "250px"}} onClick={clickHandle} onMouseMove={props.onMouseMove}>
    <div className="flex flex-row justify-start" style={{width: "100%", height: "100%"}}>
        <div className="rounded-30px w-full"  style={{cursor: "pointer", backgroundImage: `url(${props.headerImage})`, backgroundSize: "cover", backgroundPosition: "cover"}}>
            <div className="flex flex-col justify-around news-overlay py-4 px-5 border-[1px] h-[275px] rounded-30px w-full">
                <div className="max-w-20 h-8 news-category rounded-full flex flex-col justify-center">
                    <p>{props.category}</p>
                </div>
                <div className="py-5 flex flex-row gap-4">
                    {
                        props.content?.includes("<img ")? <div className="flex flex-col text-2xl justify-center">
                        <i className="fa-solid fa-clone" style={{color: "white"}}></i>
                    </div> : <></>
                    }
                    <p className="news-content text-[22px] text-right" style={{color: "white"}}>
                        {props.title}
                    </p>
                </div>
                <div className="flex flex-row mt-4">
                    <i className="fa-regular fa-calendar news-date-icon text-xl ml-2" style={{color: "white"}}></i>
                    <p className="news-date" style={{color: "white"}}>{props.date}</p>
                </div>
            </div>
        </div>
        
    </div>
</div>
}