import axios from "axios";
import { BackEndUrl } from "../App";


export async function GetEvents(all: boolean){
    try {
        let fetchResult = axios.get(BackEndUrl + "Event/GetEvents?all=" + all, {
            headers: {
                "Access-Control-Allow-Origin": "*",
            }
        });
        let fetData = (await fetchResult).data
        
        return fetData.data;
    } catch (e){
        return null;
    }
}

export async function AddEvent(title: string, location: string, date: Date, image: File, link: string){
    try {
        let formData = new FormData();
        formData.append("HeaderImage", image);
        formData.append("Title", title);
        formData.append("Location", location);
        formData.append("Date", Math.floor(date.getTime() / 1000).toString());
        formData.append("Link", link);

        let fetchResult = axios.post(BackEndUrl + "Event", formData, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("UserToken"),
                "Access-Control-Allow-Origin": "*",
            }
        });
        let fetData = (await fetchResult).data
        
        return fetData.message;
    } catch (e){
        return null;
    }
}

export async function UpdateEvent(id: number, title: string, location: string, date: Date, image: File | null, link: string){
    try {
        let formData = new FormData();
        if (image != null){
            formData.append("HeaderImage", image);
        }
        formData.append("Title", title);
        formData.append("Location", location);
        formData.append("Id", id.toString());
        formData.append("Date", Math.floor(date.getTime() / 1000).toString());
        formData.append("Link", link);

        let fetchResult = axios.put(BackEndUrl + "Event", formData, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("UserToken"),
                "Access-Control-Allow-Origin": "*",
            }
        });
        let fetData = (await fetchResult).data
        
        return fetData.message;
    } catch (e){
        return null;
    }
}

export async function DeleteEvent(id: number) {
    try {
        let fetchResult = axios.delete(BackEndUrl + "Event?id=" + id, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("UserToken"),
                "Access-Control-Allow-Origin": "*",
            }
        });
        let fetData = (await fetchResult).data
        
        return fetData.message;
    } catch (e){
        return null;
    }
}