import React from "react";

export default function EventsItem(props: any){

    return <div className="event-item flex flex-col justify-center col-span-4 phone:col-span-1" style={{cursor: "pointer"}} onClick={(ev) => window.location.href = props.link}>
    <img alt={`${props.title} عکس`} src={props.image} className="event-picture rounded-[30px] event-img" onMouseMove={(ev) => props.onMouseMoveEvent(ev, "event-" + props.id)}></img>
    <div className="flex flex-col justify-center mt-5 phone:mt-1">
        <div className="flex flex-row">
            <h3 className="text-[22px] text-right event-title" onMouseMove={(ev) => props.onMouseMoveEvent(ev, "event-" + props.id)}>{props.title}</h3>
        </div>
        <div className="flex flex-row event-content mt-4">
            <div className="flex flex-row ml-8" onMouseMove={(ev) => props.onMouseMoveEvent(ev, "event-" + props.id)}>
                <i className="fa-regular fa-calendar text-sm ml-2 text-center align-middle py-2"></i>
                <p className="text-center align-middle text-base">{props.date}</p>
            </div>
            <div className="flex flex-row" onMouseMove={(ev) => props.onMouseMoveEvent(ev, "event-" + props.id)}>
                <i className="fa-solid fa-location-dot text-sm ml-2 text-center align-middle py-2"></i>
                <p className="text-center align-middle text-base">{props.location}</p>
            </div>
        </div>
    </div>
</div>
}