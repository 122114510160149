import React, { useContext, useEffect, useRef, useState } from "react";
import { GetCoworkers, RemoveCoworkers, SetCoworkers } from "../../services/CoworkersServices";
import CoworkerItem from "./CoworkerItem"
import CoworkerItemPhone from "./CoworkerItemPhone"
import { UserContext } from "../../context/UserContext";
import { Permissions } from "../../enums/Permissions";
import { StaticFileUrl } from "../../App";

let lastCalled = false;

export default function Coworker(props: any){
    let [coworkers, setCoworkers] = useState([]);
    let [userData, userReducer] = useContext(UserContext);

    let sectionRef = useRef<any>(null);

    useEffect(() => {
        setTimeout(() => {
            if (lastCalled){
                return;
            }

            let scrollId = window.location.href.slice(window.location.href.indexOf("#") + 1);

            if (scrollId == "coworkers"){
                let linkElement = document.createElement("a");
                linkElement.href = "#coworkers";

                linkElement.click();
            }

            lastCalled = true;
        }, 500);
      }, []);

    useEffect(() => {
        GetCoworkers().then((res) => {
            if (res == null){
                return null;
            }
            setCoworkers(res);
        })
    }, []);

    let cacheCoworkers: any[] = [...coworkers]; //Name: item-{number}

    cacheCoworkers = cacheCoworkers.sort((a, b) => {
        let aNameWithoutItem = a.name.replace("item-", "");
        let bNameWithoutItem = b.name.replace("item-", "");

        if (parseInt(aNameWithoutItem) > parseInt(bNameWithoutItem)){
            return 1;
        }

        if (parseInt(aNameWithoutItem) < parseInt(bNameWithoutItem)){
            return -1;
        }

        return 1;
    });
    
    let newCoworkers: any[] = [];

    let lastItem = 0;

    if (userData.Roles.includes(Permissions.ChangeWebsiteSettings)){
        for(let x = 0; x < cacheCoworkers.length; x++){
            let itemNumber = parseInt(cacheCoworkers[x].name.replace("item-", ""));
            if (itemNumber <= lastItem + 1){
                newCoworkers.push(cacheCoworkers[x]);
            }else{
                let innerCounter = lastItem + 1;
    
                while(innerCounter < itemNumber){
                    newCoworkers.push({
                        filler: true,
                        imageFile: "",
                        name: "item-" + innerCounter
                    });
                    innerCounter++;
                }
                newCoworkers.push(cacheCoworkers[x]);
            }
            lastItem = itemNumber;
        }
    }else{
        newCoworkers = [...cacheCoworkers];
    }

    
    lastItem++;
    while(lastItem < 16 && userData.Roles.includes(Permissions.ChangeWebsiteSettings)){
        newCoworkers.push({
            filler: true,
            imageFile: "",
            name: "item-" + (lastItem)
        });
        lastItem++;
    }

    let phoneRenderCounter = 0;

    let renderCounter = -1;

    function clickHandler(name: string, remove: boolean = false){
        if (!userData.IsLoggedIn || !userData.Roles.includes(Permissions.ChangeWebsiteSettings)){
            return;
        }

        if (remove){
            RemoveCoworkers(name).then((res) => {
                window.location.reload();
            });

            return;
        }

        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
      
        input.onchange = () => {
            const file = input.files ? input.files[0] : null;
            if (file){
                SetCoworkers(file, name).then((res) => {
                    if (res == null){
                        alert("مشکلی در اپلود فایل به وجود امد");
                        return;
                    }
                    window.location.reload();
                });
            }
        }
    }

    const scrollContainer = useRef<HTMLDivElement>(null);
    const scrollSpeed = 0.01;
    
    useEffect(() => {
      const scrollContainerElement = scrollContainer.current;
    
      if (!scrollContainerElement) return;
    
      const scroll = () => {
        
        let preScrollLeft = scrollContainerElement.scrollLeft;
        
        scrollContainerElement.scrollLeft -= scrollSpeed;
        if (scrollContainerElement.scrollLeft == preScrollLeft){
          scrollContainerElement.scrollLeft = 0;
        }
      };
    
      
      const intervalId = setInterval(scroll, 20);
      
    
      return () => clearInterval(intervalId);
    }, []);

    const scrollContainer2 = useRef<HTMLDivElement>(null);
    const scrollSpeed2 = 0.02;
    
    useEffect(() => {
      const scrollContainerElement2 = scrollContainer2.current;
    
      if (!scrollContainerElement2) return;

      scrollContainerElement2.scrollLeft = 100;
    
      const scroll = () => {
        
        let preScrollLeft = scrollContainerElement2.scrollLeft;
        
        scrollContainerElement2.scrollLeft -= scrollSpeed2;
        if (scrollContainerElement2.scrollLeft == preScrollLeft){
            scrollContainerElement2.scrollLeft = 0;
        }
      };
    
      
      const intervalId = setInterval(scroll, 20);
      
    
      return () => clearInterval(intervalId);
    }, []);

    return <section ref={sectionRef} id="coworkers" className="coworkers px-10 py-50px mt-10 phone:px-0">
    <div className="flex flex-row justify-center">
        <div className="flex flex-col justify-center">
            <h3 className="text-32px text-center" id="news-header-title">
                همکاران ما
            </h3>
        </div>
    </div>
    <div className="hidden flex-col mt-12 phone:flex">
        <div className="coworker-scroll hidden flex-row-reverse phone:flex overflow-clip gap-10" style={{overflow: "scroll"}} ref={scrollContainer}>
            {
                newCoworkers.map((coworker: any) => {
                    if (phoneRenderCounter >= 7){
                        return;
                    }
                    phoneRenderCounter++;
                    return <CoworkerItemPhone isAdmin={userData.Roles.includes(Permissions.ChangeWebsiteSettings)} filler={coworker.filler} image={StaticFileUrl + "/" + coworker.imageFile} clickHandler={clickHandler} name={coworker.name}></CoworkerItemPhone>
                })
            }
        </div>
        <div ref={scrollContainer2} className="coworker-scroll hidden flex-row-reverse phone:flex overflow-clip gap-10" style={{overflow: "scroll"}}>
            {
                newCoworkers.map((coworker: any) => {
                    return <CoworkerItemPhone isAdmin={userData.Roles.includes(Permissions.ChangeWebsiteSettings)} filler={coworker.filler} image={ StaticFileUrl + "/" + coworker.imageFile} clickHandler={clickHandler} name={coworker.name}></CoworkerItemPhone>
                })
            }
        </div>
    </div>
    <div className="grid grid-cols-12 mt-16 px-5 phone:hidden">
    {
            newCoworkers.map((coworker: any) => {
                let isRight = false;
                let isTop = false;
                let isMid = false;
                renderCounter++;
                if (renderCounter < 5){
                    isTop = true;
                }else {
                    isMid = true;
                }
                if (renderCounter == 4 || renderCounter == 9 || renderCounter == 14){
                    isRight = true;
                }
                
                if (coworker.filler){
                    return <CoworkerItem isFirst={(renderCounter == 0 || renderCounter == 5 || renderCounter == 10)} top={isTop} right={isRight} mid={isMid} image={coworker.imageFile} filler={true} isAdmin={userData.Roles.includes(Permissions.ChangeWebsiteSettings)} clickHandler={clickHandler} name={coworker.name}></CoworkerItem>
                }
                return <CoworkerItem isFirst={(renderCounter == 0 || renderCounter == 5 || renderCounter == 10)} top={isTop} right={isRight} mid={isMid} image={StaticFileUrl + "/" + coworker.imageFile} clickHandler={clickHandler} name={coworker.name} isAdmin={userData.Roles.includes(Permissions.ChangeWebsiteSettings)}></CoworkerItem>
            })
        }
    </div>
</section>
}