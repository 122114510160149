import axios from "axios";
import React, { useEffect, useState } from "react";
import { BackEndUrl } from "../../App";
import { SetEntity } from "../../services/SiteEntitiesServices";

export default function HeaderCentersEdit(props: {id: string, closeHandler: any, title: string, description: string, buttonText: string, buttonLink: string, image: string}){
    let [isLoading, setIsLoading] = useState(false);
    let [title, setTitle] = useState("");
    let [description, setDescription] = useState("");
    let [buttonText, setButtonText] = useState("");
    let [buttonLink, setButtonLink] = useState("");
    let [image, setImage] = useState("");
    let [imageFile, setImageFile] = useState<File | null>(null);
 
    useEffect(() => {
        setTitle(props.title || "");
        setDescription(props.description || "");
        setButtonText(props.buttonText || "");
        setButtonLink(props.buttonLink || "");
        setImage(props.image || "");
    }, []);

    function imageClick(){
        if (imageFile != null){
            imageFile = null;
            image = "";
            return;
        }

        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
      
        input.onchange = async () => {
            const file = input.files ? input.files[0] : null;

            if (file != null){
                let formData = new FormData();
                formData.append("File", file);

                axios.post(BackEndUrl + "Upload", formData, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        Authorization: "Bearer " + localStorage.getItem("UserToken")
                    }
                }).then((res) => {
                    if (res.data.fileName){
                        setImage(res.data.fileName);
                        setImageFile(file);
                    }
                });
            }
        }
    }

    function submitHandler(){
        if (isLoading){
            return;
        }
        setIsLoading(true);
        if (image == "" || title == "" || description == "" || buttonLink == "" || buttonText == ""){
            alert("لطفا تمام فیلدها را پر کنید");
            return;
        }

        let object = {
            image: image,
            title: title,
            description: description,
            buttonText: buttonText,
            buttonLink: buttonLink
        }

        SetEntity(props.id, JSON.stringify(object), "header-centers", "header").then((res) => {
            setIsLoading(false);
            if (res == null){
                alert("مشکلی در ویرایش به وجود امد");
                return;
            }

            window.location.reload();
        });
    }

    return <section className="popups flex flex-row justify-center py-12 px-72 tablet:px-0">
    <div className="flex flex-col popup bg-white p-8 rounded-15px">
        <div className="popup-content">
            <div className="popup-header flex flex-row-reverse justify-between" style={{cursor: "pointer"}} onClick={props.closeHandler}>
                <h4 className="popup-header-title text-2xl">ویرایش پاپ آپ</h4>
                <i className="fa-solid fa-x"></i>
            </div>
            <div className="popup-tabs flex flex-row-reverse justify-start px-4 gap-6 mt-5">
            </div>
            <div className="grid grid-cols-2 gap-6 justify-center mt-4" dir="rtl">
                <div className="popup-input col-span-1 rounded-15px px-2 py-3 my-4">
                    <input type="text" className="w-full h-full text-right" placeholder="عنوان" value={title} onChange={(ev) => setTitle(ev.target.value)}></input>
                </div>
                <div className="popup-input col-span-1 rounded-15px px-2 py-3 my-4">
                    <input type="text" className="w-full h-full text-right" placeholder="توضیحات" value={description} onChange={(ev) => setDescription(ev.target.value)}></input>
                </div>
                <div className="popup-input col-span-1 rounded-15px px-2 py-3 my-4">
                    <input type="text" className="w-full h-full text-right" placeholder="عنوان لینک" value={buttonText} onChange={(ev) => setButtonText(ev.target.value)}></input>
                </div>
                <div className="popup-input col-span-1 rounded-15px px-2 py-3 my-4">
                    <input type="text" className="w-full h-full text-right" placeholder="آدرس لینک" value={buttonLink} onChange={(ev) => setButtonLink(ev.target.value)}></input>
                </div>
                <div className="popup-input col-span-1 rounded-15px px-2 py-3 my-4">
                    <input type="text" className="w-full h-full text-right" placeholder="انتخاب فایل" value={imageFile? imageFile.name : image} onClick={imageClick}></input>
                </div>
            </div>

            <div className="flex flex-row-reverse justify-center gap-4 mt-6">
                <a href="#" className="rounded-30px text-center py-4 px-10 popup-submit text-xs" onClick={() => submitHandler()}>{isLoading? <i className="fa-solid fa-spinner"></i> : <p>انتشار</p>}</a>
                <a href="#" className="rounded-30px text-center py-4 px-10 popup-cancel text-xs" onClick={props.closeHandler}>لغو</a>
            </div>
        </div>
    </div>
</section>
}