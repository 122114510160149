import axios from "axios";
import { DatePicker } from "jalaali-react-date-picker";
import React, { useEffect, useState } from "react";
import { BackEndUrl } from "../../App";
import { SetEntity } from "../../services/SiteEntitiesServices";

export default function FooterSocialMediaEdit(props: any){
    let [isLoading, setIsLoading] = useState(false);
    let [link, setLink] = useState("");
    let [image, setImage] = useState("");
    let [imageFile, setImageFile] = useState<File | null>(null);

    useEffect(() => {
        if (props.link == ""){
            return;
        }
        setLink(props.link);
        setImage(props.image);
    }, []);

    function changeHandle(){
        if (imageFile != null){
            setImageFile(null);
            return;
        }

        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
      
        input.onchange = async () => {
            const file = input.files ? input.files[0] : null;
            setImageFile(file);
        }
    }

    function submitHandle(){
        if (isLoading){
            return;
        }
        setIsLoading(true);
        if (imageFile == null && !props.id){
            alert("لطفا فایل مورد نظر را وارد کنید")
            return;
        }

        if (link == ""){
            alert("فیلد مورد نیاز را درست پر کنید");
            return;
        }

        if (imageFile != null){
            let formData = new FormData();
            formData.append("File", imageFile);

            axios.post(BackEndUrl + "Upload", formData, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("UserToken"),
                    "Access-Control-Allow-Origin": "*"
                }
            }).then((res) => {
                let fileName = res.data.fileName;

                let object = {
                    link: link,
                    image: fileName
                }

                SetEntity(props.id || "footer-item-" + Math.floor(Math.random() * 1000), JSON.stringify(object), "footer-socialmedias", "footer").then((res) => {
                    setIsLoading(false);
                    if (res == null){
                        alert("مشکلی در اپلود به وجود امد");
                        return;
                    }

                    window.location.reload();
                });
            });

            return;
        }

        let fileName = image;

        let object = {
            link: link,
            image: fileName
        }
        
        SetEntity(props.id || "footer-item-" + Math.floor(Math.random() * 1000), JSON.stringify(object), "footer-socialmedias", "footer").then((res) => {
            setIsLoading(false);
            if (res == null){
                alert("مشکلی در اپلود به وجود امد");
                return;
            }
            
            window.location.reload();
        });
    }

    return <section className="popups flex flex-row justify-center py-12 px-72 tablet:px-0">
    <div className="flex flex-col popup bg-white p-8 rounded-15px">
        <div className="popup-content">
            <div className="popup-header flex flex-row justify-between" style={{cursor: "pointer"}} onClick={props.closeHandler}>
                <h4 className="popup-header-title text-2xl">ویرایش اطلاعات فوتر</h4>
                <i className="fa-solid fa-x" onClick={props.closeHandler}></i>
            </div>
            <div className="popup-tabs flex flex-row-reverse justify-start px-4 gap-6 mt-5">
            </div>
            <div className="grid grid-cols-2 gap-6 justify-center mt-4" dir="rtl">
                <div className="popup-input col-span-1 rounded-15px px-2 py-3 my-4">
                    <input type="text" className="w-full h-full text-right" placeholder="لینک..." value={link} onChange={(ev) => {setLink(ev.target.value)}}></input>
                </div>
                <div className="popup-input col-span-1 rounded-15px px-2 py-3 my-4">
                    <input type="text" className="w-full h-full text-right" placeholder="محل رویداد" value={imageFile?.name ||" انتخاب کنید"} onClick={(ev) => {ev.preventDefault(); changeHandle()}}></input>
                </div>
            </div>

            <div className="flex flex-row-reverse justify-center gap-4 mt-6">
                <a href="#" className="rounded-30px text-center py-4 px-10 popup-submit text-xs" onClick={submitHandle}>{isLoading? <i className="fa-solid fa-spinner"></i> : <p>انتشار</p>}</a>
                <a href="#" className="rounded-30px text-center py-4 px-10 popup-cancel text-xs" onClick={props.closeHandler}>لغو</a>
            </div>
        </div>
    </div>
</section>
}