import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import { CurrentEditId, CurrentFocusId, hideUiEventHandler, setCurrentEditing, showUiEventHandler } from "../../../services/EditUiServices";
import { GetEntitiesByCategory, SetEntity } from "../../../services/SiteEntitiesServices";
import { Permissions } from "../../../enums/Permissions";
import AboutUsEdit from "./AboutUsEdit";

export default function AboutUs(){
    let [data, setData] = useState<any[]>([]);
    let [userData, userReducer] = useContext(UserContext);

    let [isEditing, setIsEditing] = useState("");

    let [titleEditValue, setTitleEditValue] = useState("");
    let [subTitleEditValue, setSubTitleEditValue] = useState("");

    let [item1, setItem1] = useState<any>(null);
    let [item2, setItem2] = useState<any>(null);
    let [item3, setItem3] = useState<any>(null);
    let [item4, setItem4] = useState<any>(null);

    let [editingId, setEditingId] = useState(0);
    
    function getItemData(id: number){
        switch(id){
            case 1:
                return item1;
            case 2:
                return item2;
            case 3:
                return item3;
            case 4:
                return item4
        }
    }

    let titleEditRef = useRef(titleEditValue);
    let subTitleEditRef = useRef(subTitleEditValue);


    let [title, setTitle] = useState("در راوینو چه میگذرد؟");
    let [subTitle, setSubTitle] = useState("لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده.");

    useEffect(() => {
        GetEntitiesByCategory("index-aboutus").then((res) => {
            if (res == null){
                return;
            }

            res.forEach((d: any) => {
                if (d.entityId == "index-aboutus-title"){
                    setTitleEditValue(d.content);
                    setTitle(d.content);
                }
                if (d.entityId == "index-aboutus-subtitle"){
                    setSubTitleEditValue(d.content);
                    setSubTitle(d.content);
                }
                if (d.entityId == "index-aboutus-item1"){
                    setItem1(JSON.parse(d.content));
                }
                if (d.entityId == "index-aboutus-item2"){
                    setItem2(JSON.parse(d.content));
                }
                if (d.entityId == "index-aboutus-item3"){
                    setItem3(JSON.parse(d.content));
                }
                if (d.entityId == "index-aboutus-item4"){
                    setItem4(JSON.parse(d.content));
                }
            });
        });
    }, []);

    function changeHandler(id: string, content: string, ev: any){
        if (id == "index-aboutus-title"){
            setTitleEditValue(content);
        }
        if (id == "index-aboutus-subtitle"){
            setSubTitleEditValue(content);
        }
        
    }

    function handleKeyDown(isSubmit = true){
        if (isSubmit){
            switch (CurrentEditId){
                case "index-aboutus-title":
                    SetEntity("index-aboutus-title", titleEditRef.current || "", "index-aboutus", "index").then((res) => {
                        if (res == null){
                            return;
                        }
                    });

                    setTitle(titleEditRef.current || title);
                    setIsEditing("");
                    break;
                case "index-aboutus-subtitle":
                    SetEntity("index-aboutus-subtitle", subTitleEditRef.current || "", "index-aboutus", "index").then((res) => {
                        if (res == null){
                            return;
                        }
                    });

                    setSubTitle(subTitleEditRef.current || subTitle);
                    setIsEditing("");
                    break;
            }
            return;
        }
        setIsEditing("");
    }

    useEffect(() => {
        titleEditRef.current = titleEditValue;
        subTitleEditRef.current = subTitleEditValue;

        window.addEventListener("keydown", checkButton);

        let element = document.querySelector(".ql-container") as HTMLElement | null;

        if (element != null){
            element.onmousemove = showUi;
        }

        function checkButton(ev: any){
            if (ev.key == "Escape"){
                handleKeyDown(false);
            }
            if (ev.key == "Enter"){
                handleKeyDown();
            }
        }

        function showUi(ev: any){
            showUiEventHandler(ev, CurrentFocusId, switchHandle, null, null, closeHandle, submitHandle);
        }

        return () => {
            window.removeEventListener("keydown", checkButton);
        };
    }, [titleEditValue, subTitleEditValue]);

    function submitHandle(ev: any){
        handleKeyDown(true);
        hideUiEventHandler(ev);
    }

    function closeHandle(ev: any){
        setIsEditing("");
        hideUiEventHandler(null);
        setCurrentEditing("");
    }

    function switchHandle(ev: any){
        hideUiEventHandler(null);
    }

    function editHandle(ev: any){
        setCurrentEditing(CurrentFocusId);
        setIsEditing(CurrentFocusId);

        setEditingId(parseInt(CurrentEditId.replace("index-aboutus-item", "")));

        if (ev != null){
            showUiEventHandler(ev, CurrentFocusId, switchHandle, null, null, closeHandle, submitHandle);
        }
    }

    function mouseEnterEvent(ev: any, elementId: string){
        if (!userData.IsLoggedIn || !userData.Roles.includes(Permissions.ChangeWebsiteSettings)){
            return;
        }
        showUiEventHandler(ev, elementId, switchHandle, editHandle, null, null, null);
    }

    if (item1 == null){
        return <></>
    }

    return <section className="about-us px-5 py-[50px] mt-8">
        {
            isEditing.includes("index-aboutus-item")? <AboutUsEdit id={editingId} title={getItemData(editingId).title} icon={getItemData(editingId).icon} closeHandler={closeHandle}></AboutUsEdit> : <></>
        }
    <div className="mx-5 phone:mx-0 py-5 tablet:px-4 phone:px-4 flex flex-row-reverse tablet:grid tablet:grid-cols-2 tablet:gap-y-8 phone:grid phone:grid-cols-2 phone:gap-y-8 bg-white rounded-30px">
        <div className="flex flex-col tablet:col-span-2 phone:col-span-2 justify-center w-[374px] tablet:w-auto h-36 border-l-2 gap-4 tablet:border-none phone:border-none" dir="rtl" style={{borderColor: "#CCCCCC"}}>
            {
                isEditing == "index-aboutus-title"? <input className="text-[32px] px-7 max-h-80 text-right tablet:text-xl phone:text-sm" id="aboutus-1-header" value={titleEditValue} onChange={(ev) => changeHandler("index-aboutus-title", ev.target.value, ev)}></input> : 
                <h2 className="text-[32px] px-7 max-h-80 text-right tablet:text-xl phone:text-sm" id="aboutus-1-header" onMouseMove={(ev) => mouseEnterEvent(ev, "index-aboutus-title")}>
                    <div dangerouslySetInnerHTML={{ __html: title }} />
                </h2>
            }
            
            {
                isEditing == "index-aboutus-subtitle"? <input className="text-sm max-h-80 px-7 text-right w-[336px] tablet:text-lg tablet:w-auto phone:text-xs" id="aboutus-1-content" value={subTitleEditValue} onChange={(ev) => changeHandler("index-aboutus-subtitle", ev.target.value, ev)}></input> : 
                <h4 className="text-sm max-h-80 px-7 text-right w-[336px] tablet:text-lg tablet:w-auto phone:text-xs" id="aboutus-1-content" onMouseMove={(ev) => mouseEnterEvent(ev, "index-aboutus-subtitle")}>
                    <div dangerouslySetInnerHTML={{ __html: subTitle }} />
                </h4>
            }
        </div>
        <div className="flex about-us-item-not-mid flex-col tablet:col-span-1 phone:col-span-1 justify-center w-[240px] mt-6 h-24 items-center border-l-2 tablet:w-auto phone:w-auto" dir="rtl" style={{borderColor: "#CCCCCC"}} onMouseMove={(ev) => mouseEnterEvent(ev, "index-aboutus-item1")}>
            <i className={`fa-solid fa-${item1.icon} text-[36px] laptop:text-3xl px-7 max-h-80 text-center`} style={{color: "rgba(239, 87, 47, 1)"}}></i>
            <h4 className="text-lg laptop:text-base max-h-80 px-7 text-center mt-4 phone:test-base phone:px-0" id="aboutus-1-content">
                <div dangerouslySetInnerHTML={{ __html: item1.title }} />
            </h4>
        </div>
        <div className="flex about-us-item flex-col tablet:col-span-1 phone:col-span-1 justify-center w-[240px] mt-6 h-24 items-center border-l-2 tablet:w-auto phone:w-auto" dir="rtl" style={{borderColor: "#CCCCCC"}} onMouseMove={(ev) => mouseEnterEvent(ev, "index-aboutus-item2")}>
            <i className={`fa-solid fa-${item2.icon} text-[36px] laptop:text-3xl px-7 max-h-80 text-center`} style={{color: "rgba(239, 87, 47, 1)"}}></i>
            <h4 className="text-lg laptop:text-base max-h-80 px-7 text-center mt-4 phone:test-base phone:px-0" id="aboutus-1-content">
                <div dangerouslySetInnerHTML={{ __html: item2.title }} />
            </h4>
        </div>
        <div className="flex about-us-item-not-mid flex-col tablet:col-span-1 phone:col-span-1 justify-center w-[240px] mt-6 h-24 items-center border-l-2 tablet:w-auto phone:w-auto" dir="rtl" style={{borderColor: "#CCCCCC"}} onMouseMove={(ev) => mouseEnterEvent(ev, "index-aboutus-item3")}>
            <i className={`fa-solid fa-${item3.icon} text-[36px] laptop:text-3xl px-7 max-h-80 text-center`} style={{color: "rgba(239, 87, 47, 1)"}}></i>
            <h4 className="text-lg laptop:text-base max-h-80 px-7 text-center mt-4 phone:test-base phone:px-0" id="aboutus-1-content">
                <div dangerouslySetInnerHTML={{ __html: item3.title }} />
            </h4>
        </div>
        <div className="flex flex-col about-us-item-end tablet:col-span-1 phone:col-span-1 justify-center w-[240px] mt-6 h-24 items-center border-l-2 tablet:w-auto phone:w-auto" dir="rtl" onMouseMove={(ev) => mouseEnterEvent(ev, "index-aboutus-item4")}>
            <i className={`fa-solid fa-${item4.icon} text-[36px] laptop:text-3xl px-7 max-h-80 text-center`} style={{color: "rgba(239, 87, 47, 1)"}}></i>
            <h4 className="text-lg laptop:text-base max-h-80 px-7 text-center mt-4 phone:test-base phone:px-0" id="aboutus-1-content">
                <div dangerouslySetInnerHTML={{ __html: item4.title }} />
            </h4>
        </div>
    </div>
</section>
}