import React, { useContext, useEffect, useState } from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import FilmLanding from "../components/Landing/FilmLanding";
import LandingSlider from "../components/LandingSlider/LandingSlider";
import Statics from "../components/Statics/Statics";
import { UserContext } from "../context/UserContext";
import { Permissions } from "../enums/Permissions";
import Activities from "../components/Activities/Activities";
import News from "../components/News/News";
import { GetEntitiesByCategory, GetEntity } from "../services/SiteEntitiesServices";
import { mouseEnterSelfEvent } from "../services/EditUiServices";


export default function FilmPage(){
    let [userData, userReducer] = useContext(UserContext);
    let [activitiesItem, setActivitiesItem] = useState([]);
    let [newsCategory, setNewsCategory] = useState(null);
    let [landingData, setLandingData] = useState([]);
    let [sliderData, setSliderData] = useState([]);
    let [statics, setStatics] = useState([{
        icon: "",
        content: ""
    }]);

    useEffect(() => {
        GetEntitiesByCategory("film-landing").then((res) => {
            if (res == null){
                return;
            }

            setLandingData(res);
        });
        GetEntitiesByCategory("film-slider").then((res) => {
            if (res == null){
                return;
            }

            setSliderData(res);
        })
        GetEntitiesByCategory("film-activities").then((res) => {
            if (res == null){
                return;
            }

            setActivitiesItem(res);
        });
        GetEntity("film-news-categories").then((res) => {
            if (res == null){
                return;
            }

            let decodedObject = JSON.parse(res.content).category;

            setNewsCategory(decodedObject);
        });
        GetEntitiesByCategory("film-statics").then((res: any[]) => {
            if (res == null){
                return;
            }

            let cacheStatis: {
                icon: string,
                content: string
            }[] = [];

            if (userData.Roles.includes(Permissions.ChangeWebsiteSettings)){
                cacheStatis.push({
                    icon: "plus",
                    content: "اضافه کردن"
                });
            }

            res.forEach((staticItem: any) => {
                let decode = JSON.parse(staticItem.content);
                cacheStatis.push({
                    icon: decode.icon,
                    content: decode.content
                });
            });

            setStatics(cacheStatis);
        })
    }, []);

    return <div className="page-container">
        <div id="main-background">

        </div>
        <div id="element-actions" className="flex flex-row flex-warp gap-3" onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                    <div id="edit-button" className="text-xl px-4 py-3 rounded-full hover:cursor-pointer" hidden onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                        <i className="fa-regular fa-pen"></i>
                    </div>
                    <div id="delete-button" className="text-xl px-4 py-3 rounded-full hover:cursor-pointer" hidden onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                        <i className="fa-regular fa-trash"></i>
                    </div>
                    <div id="close-button" className="text-xl px-4 py-3 rounded-full hover:cursor-pointer" hidden onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                    <div id="submit-button" className="text-xl px-4 py-3 rounded-full hover:cursor-pointer" hidden onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                        <i className="fa-solid fa-check"></i>
                    </div>
                </div>
        <Header landing={<FilmLanding data={landingData}></FilmLanding>}></Header>
        {/*<LandingSlider updateCategory="film-landing-slider"></LandingSlider>*/}
        {/*<Statics customTitle={"راوی فیلم"} updateCategory="film-statics"></Statics>*/}
        {/*<Activities customName="راوی فیلم" updateCategory="film-activities"></Activities>*/}
        <News updateCategories="film-news-categories" limit={true} link={true} customTitle={<p>خبرهای مرتبط با <span style={{color: "rgba(121, 200, 215, 1)"}}>راوی فیلم</span></p>}></News>
        <Footer></Footer>
    </div>
}