import React, { useState } from "react";

export default function Login(props: any){
    let [phoneNumber, setPhoneNumber] = useState("");
    let [password, setPassword] = useState("");
    let [isLoading, setLoading] = useState(false);

    function handlePhoneChange(event: any){
        let newValue = event.target.value;

        setPhoneNumber(newValue);
    }

    function handlePasswordChange(event: any){
        let newValue = event.target.value;

        setPassword(newValue);
    }

    function submitHandle(){
        if (phoneNumber == "" || password == "" ){
            return;
        }

        setLoading(true);
        props.loginHandle(phoneNumber, password);
    }

    function switchHandle(){
        props.switchHandle(false)
    }

    return <>
        <div className="flex flex-row justify-center auth-page">
                    <div className="w-[240px] flex flex-row-reverse text-base rounded-30px px-1 justify-around py-1" id="login-buttons-container">
                        <div className="flex flex-col justify-center text-center rounded-30px au-active px-8 py-2 hover:cursor-pointer">
                            ورود
                        </div>
                        <div className="flex flex-col justify-center text-center rounded-30px px-8 py-2 hover:cursor-pointer" onClick={switchHandle}>
                            عضویت
                        </div>
                    </div>
                </div>
                <div className="w-full au-input rounded-15px px-2 py-2 my-4">
                    <p className="relative au-input-header p-1">شماره همراه</p>
                    <input type="tel" className="w-full h-full au-input-input text-right" placeholder="" value={phoneNumber} onChange={handlePhoneChange}></input>
                </div>
                <div className="w-full au-input rounded-15px px-2 py-2 my-4">
                    <p className="relative au-input-header p-1">رمز عبور</p>
                    <input type="password" className="w-full h-full au-input-input text-right" placeholder="" value={password} onChange={handlePasswordChange}></input>
                </div>
                <div className="flex flex-row justify-center">
                    <div className="w-[240px] flex flex-row-reverse text-base rounded-30px px-1 justify-around py-1">
                        <button className="flex flex-col justify-center text-center rounded-30px au-active px-8 py-3" onClick={submitHandle}>
                            {
                                !isLoading? <p>ورود</p> : <i className="fa-solid fa-spinner"></i>
                            }
                        </button>
                    </div>
                </div>
    </>
}