import React, { useRef, useState } from "react";
import Navbar from "../Navbar/Navbar";
import NavbarPhone from "../Navbar/NavbarPhone";
import HeaderCentersPopup from "../Popups/HeaderCentersPopup";
import IndexLanding from "../Landing/IndexLanding";
import { HeaderContext } from "../../context/HeaderContext";
import { fa } from "jalaali-react-date-picker/lib/core/constants/translations";
import { hideUiEventHandler } from "../../services/EditUiServices";

export default function Header(props: any){
    let [isMenuOpen, setMenuOpen] = useState(false);
    let [isCentersOpen, setIsCentersOpen] = useState(false);
    let currentCloseInterval: any = useRef();

    function mouseEnterEvent(con: string){
        if (currentCloseInterval.current?.length != 0){
            currentCloseInterval.current?.forEach((c: any) => {
                clearTimeout(c);
            });
            currentCloseInterval.current = []
        }else{
        }
        setIsCentersOpen(true);
    }

    function mouseLeaveEvent(con: string){
        if (currentCloseInterval.current?.length != 0){
            currentCloseInterval.current?.forEach((c: any) => {
                clearTimeout(c);
            });
            currentCloseInterval.current = []
        }
        currentCloseInterval.current = [...currentCloseInterval.current, setTimeout(() => {setIsCentersOpen(false); hideUiEventHandler(null)}, 100)]
    }

    return  <section className="landing px-5 py-3 phone:p-0">
                {
                    isMenuOpen? <NavbarPhone closeMenu  ={() => setMenuOpen(!isMenuOpen)}></NavbarPhone> : <></>
                }
                <HeaderContext.Provider value={isCentersOpen}>
                    <Navbar mouseEnter={mouseEnterEvent} mouseLeave={mouseLeaveEvent} activeTab={props.activeTab} openMenu={() => setMenuOpen(!isMenuOpen)}></Navbar>
                    <HeaderCentersPopup mouseEnter={mouseEnterEvent} mouseLeave={mouseLeaveEvent} shouldOpen={isCentersOpen}></HeaderCentersPopup>
                </HeaderContext.Provider>
                {
                    props.landing
                }
            </section>
}