import React, { useContext, useEffect, useRef, useState } from "react";

import style from "./Centers.module.css";
import axios from "axios";
import { BackEndUrl, StaticFileUrl } from "../../../App";
import CentersEditor from "./CentersEditor";
import { UserContext } from "../../../context/UserContext";
import { Permissions } from "../../../enums/Permissions";

export default function CentersPopup(props: any){
    let [userData, userRaduser] = useContext(UserContext);

    let containerRef: any = useRef(null);
    let [showPopup, setShowPopup] = useState(false);

    let [popupData, setPopupData]: any[] = useState([]);

    let [showEditor, setShowEditor] = useState(false);

    useEffect(() => {
        axios.get(BackEndUrl + "Centers/Popups", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("UserToken")
            }
        }).then((res) => {
            setPopupData(res.data.data);
        });
    }, []);

    let checkMouseCooldown = 0;

    function mouseEnterEvent(ev: MouseEvent){
        if (checkMouseCooldown > 0){
            return;
        }
        if (containerRef.current?.contains(ev.target)){
            setShowPopup(true);
        }else{
            setShowPopup(false);
        }
    }

    useEffect(() => {
        setInterval(() => {
            if (checkMouseCooldown > 0){
                checkMouseCooldown -= 0.01;
            }else{
                checkMouseCooldown = 0.1;
            }
        }, 10);

        window.addEventListener("mousemove", mouseEnterEvent);

        return () => {
            window.removeEventListener("mouseenter", mouseEnterEvent);
        }
    }, []);

    let renderCounter = 0;

    function closeEditor(){
        setShowEditor(false);
        setEditName(null);
    }

    let [editName, setEditName] = useState(null);

    function deleteHandle(name: string){
        axios.delete(BackEndUrl + "Centers", {
            params: {
                name: name
            },
            headers: {
                Authorization: "Bearer " + localStorage.getItem("UserToken")
            }
        }).then((res) => {
            alert(res.data.message);
            window.location.reload();
            return;
        });
    }

    return <>
        {showEditor? <CentersEditor editName={editName} editData={popupData.find((e: any) => e.name == editName)} closeFunction={closeEditor}></CentersEditor> : <></>}
        <div ref={containerRef} className={`header-news basis-[120px] h-16 text-center align-middle flex flex-col justify-center text-base laptop:text-sm tablet:hidden phone:hidden ${style.main_container}`}>
            <div className="flex title-text flex-row-reverse gap-2 justify-center" style={{color: props.showBeBlue? "#007DC3" : "black"}}>
                <a href="#" className="h-6">
                    مرکز‌ها
                </a>
                <div style={{transform: showPopup? "rotate(180deg)" : "", transition: "ease-in 0.1s"}}>
                    <i className="fa-regular fa-chevron-down" style={{cursor: "pointer"}}></i>
                </div>
            </div>
            <div className={`${showPopup? style.centers_container : style.centers_container_hidden} ${userData.Roles.includes(Permissions.ChangeWebsiteSettings)? popupData.length > 1? style.centers_container_3_center : popupData.length > 0? style.centers_container_2_center : style.centers_container_1_center : popupData.length > 2? style.centers_container_3_center : popupData.length > 1? style.centers_container_2_center : style.centers_container_1_center}`}>
                {
                    popupData.map((popup: any, index: number) => {
                        renderCounter++;
                        if (renderCounter >= 3){
                            renderCounter = 0;
                        }

                        return <div className={`${style.centers_center} ${renderCounter == 0 || index != (popupData.length + 1)? "" : style.centers_center_border}`}>
                        <div className={`${style.centers_center_header}`}>
                            <div className={`${style.centers_center_header_image}`}>
                                <img src={StaticFileUrl + "/" + popup.image} alt="image"></img>
                            </div>
                            <div className={`${style.centers_center_header_title}`}>
                                {popup.title}
                            </div>
                            {userData.Roles.includes(Permissions.ChangeWebsiteSettings)? <div className={`${style.centers_center_header_buttons}`}>
                                <div className={`${style.centers_center_header_buttons_button}`} onClick={() => {setEditName(popup.name); setShowEditor(true)}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{width: "15px", height: "15px"}}><path d="M36.4 360.9L13.4 439 1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1L73 498.6l78.1-23c12.4-3.6 23.7-9.9 33.4-18.4c1.4-1.2 2.7-2.5 4-3.8L492.7 149.3c21.9-21.9 24.6-55.6 8.2-80.5c-2.3-3.5-5.1-6.9-8.2-10L453.3 19.3c-25-25-65.5-25-90.5 0L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4zm46 13.5c1.7-5.6 4.5-10.8 8.4-15.2c.6-.6 1.1-1.2 1.7-1.8L321 129 383 191 154.6 419.5c-4.7 4.7-10.6 8.2-17 10.1l-23.4 6.9L59.4 452.6l16.1-54.8 6.9-23.4z"/></svg></div>
                                <div className={`${style.centers_center_header_buttons_button}`} onClick={() => {deleteHandle(popup.name)}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{width: "15px", height: "15px"}}><path d="M177.1 48h93.7c2.7 0 5.2 1.3 6.7 3.6l19 28.4h-145l19-28.4c1.5-2.2 4-3.6 6.7-3.6zM354.2 80L317.5 24.9C307.1 9.4 289.6 0 270.9 0H177.1c-18.7 0-36.2 9.4-46.6 24.9L93.8 80H80.1 32 24C10.7 80 0 90.7 0 104s10.7 24 24 24H35.6L59.6 452.7c2.5 33.4 30.3 59.3 63.8 59.3H324.6c33.5 0 61.3-25.9 63.8-59.3L412.4 128H424c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8H367.9 354.2zm10.1 48L340.5 449.2c-.6 8.4-7.6 14.8-16 14.8H123.4c-8.4 0-15.3-6.5-16-14.8L83.7 128H364.3z"/></svg></div>
                            </div> : <></>}
                        </div>
                        <div className={`${style.centers_center_description}`}>
                            {popup.description}
                        </div>
                        <div className={`${style.centers_center_link}`} onClick={() => window.location.href = popup.link}>
                            <p>{popup.linkTitle}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{width: "20px", height: "20px"}}><path d="M7.4 273.4C2.7 268.8 0 262.6 0 256s2.7-12.8 7.4-17.4l176-168c9.6-9.2 24.8-8.8 33.9 .8s8.8 24.8-.8 33.9L83.9 232 424 232c13.3 0 24 10.7 24 24s-10.7 24-24 24L83.9 280 216.6 406.6c9.6 9.2 9.9 24.3 .8 33.9s-24.3 9.9-33.9 .8l-176-168z"/></svg>
                        </div>
                    </div>
                    })
                }
                {userData.Roles.includes(Permissions.ChangeWebsiteSettings)? <div className={`${style.add_center}`} onClick={() => {setShowEditor(true)}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className={`${style.add_center_icon}`}><path d="M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24V232H40c-13.3 0-24 10.7-24 24s10.7 24 24 24H200V440c0 13.3 10.7 24 24 24s24-10.7 24-24V280H408c13.3 0 24-10.7 24-24s-10.7-24-24-24H248V72z"/></svg>
                </div> : <></>}
                
            </div>
        </div>
    </>
}