import { useContext, useReducer } from "react";
import { UserContext } from "../../context/UserContext";
import React from "react";
import { useNavigate } from "react-router-dom";


export function NavbarProfile() {
    let [userData, userReduce] = useContext(UserContext);

    function logout(){
        localStorage.removeItem("UserToken");
        window.location.reload();
    }

    let navigate = useNavigate();

    if (userData.IsLoggedIn){
        return <div className="mr-auto h-16 basis-[181px] flex flex-col justify-center tablet:hidden phone:hidden">
        <div className="header-auth h-[50px] text-center align-middle flex flex-col justify-center border-2 rounded-[30px]">
            <button className="flex flex-row justify-center align-middle h-5 text-sm" dir="rtl" onClick={logout} style={{whiteSpace: "nowrap"}}>
                خوش آمدی {userData.FirstName + " " + userData.LastName} (خروج)
            </button>
        </div>
    </div>
    }else{
        return <div className="mr-auto h-16 basis-[181px] flex flex-col justify-center tablet:hidden phone:hidden">
                <div className="header-auth h-[50px] text-center align-middle flex flex-col justify-center border-2 rounded-[30px]">
                    <button className="flex flex-row justify-center align-middle h-5 text-sm" onClick={() => {navigate("/Auth")}}>
                        <p className="h-5 title-auth laptop:text-sm">
                            عضویت /
                        </p>
                        <p className="h-5 title-auth ml-1 laptop:text-sm">
                            ورود 
                        </p>
                    </button>
                </div>
            </div>
    }
}