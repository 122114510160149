import React from "react";

export var CurrentEditId = "";
export var CurrentFocusId = "";
var currentHideTimeOutId: any = null;
var SwitchCallBack: any = null;

var coolDown = 500;

setInterval(() => {
    if (coolDown > 0){
        coolDown -= 100;
    }
}, 100);

export function setCurrentEditing(entityId: string){
    CurrentEditId = entityId;
}
export function showUiEventHandler(ev: any, entityId: string, switchCallBack: any, editCallBack: any, deleteCallBack: any, closeCallBack: any, submitCallBack: any, mouseEnterButtonsCallBack?: Function) : any{
    if (coolDown > 0){
        return;
    }
    
    let editButton = document.getElementById("edit-button");
    let trashButton = document.getElementById("delete-button");
    let closeButton = document.getElementById("close-button");
    let submitButton = document.getElementById("submit-button");
    let container = document.getElementById("element-actions");

    if (editButton == null || trashButton == null || closeButton == null || submitButton == null || container == null || switchCallBack == null){
        return;
    }

    if (CurrentEditId != ""){
        SwitchCallBack();
        mouseEnterSelfEvent(null);
    }

    editButton.hidden = true;
    trashButton.hidden = true;
    closeButton.hidden = true;
    submitButton.hidden = true;

    editButton.onmousedown = () => {
        editCallBack(ev);
    };
    trashButton.onmousedown = deleteCallBack;
    closeButton.onmousedown = closeCallBack;
    submitButton.onmousedown = submitCallBack;
    SwitchCallBack = switchCallBack;

    if (mouseEnterButtonsCallBack != undefined){
        editButton.onmouseenter = (ev) => mouseEnterButtonsCallBack();
        trashButton.onmouseenter = (ev) => mouseEnterButtonsCallBack();
        closeButton.onmouseenter = (ev) => mouseEnterButtonsCallBack();
        submitButton.onmouseenter = (ev) => mouseEnterButtonsCallBack();
    }

    if (editCallBack != null){
        editButton.hidden = false;
    }

    if (deleteCallBack != null){
        trashButton.hidden = false;
    }
    
    CurrentFocusId = entityId;

    if (closeCallBack != null){
        closeButton.hidden = false
    }

    if (submitCallBack != null){
        submitButton.hidden = false;
    }

    let elementOrigin = ev.target.getBoundingClientRect();

    

    let elementPositionY = (elementOrigin.top + elementOrigin.height);
    let elementPositionX = (elementOrigin.left + elementOrigin.width * 0.5 - container.clientWidth / 2);

    container.style.top = elementPositionY + "px";
    container.style.left = elementPositionX + "px";
}

export function mouseEnterSelfEvent(ev: any){
    clearTimeout(currentHideTimeOutId);
}

export function scrollEvent(ev: any){
    if (CurrentEditId != ""){
        SwitchCallBack();
        mouseEnterSelfEvent(null);
    }
    hideUiEventHandler(ev);
}

export function hideUiEventHandler(ev: any) {
    coolDown = 500;

    currentHideTimeOutId = setTimeout(() => {
        var editButton = document.getElementById("edit-button");
        var trashButton = document.getElementById("delete-button");
        let closeButton = document.getElementById("close-button");
        let submitButton = document.getElementById("submit-button");

        if (editButton == null || trashButton == null || closeButton == null || submitButton == null){
            return;
        }

        editButton.onmouseenter = null;
        trashButton.onmouseenter = null;
        closeButton.onmouseenter = null;
        submitButton.onmouseenter = null;

        editButton.hidden = true;
        trashButton.hidden = true;
        closeButton.hidden = true;
        submitButton.hidden = true;
        CurrentFocusId = "";
    }, 200)
}