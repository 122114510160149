import axios, { AxiosInstance, AxiosResponse } from "axios";
import { BackEndUrl, StaticFileUrl } from "../../App";


class AxiosUploadAdapter {
    private loader: any; // Define the loader type according to CKEditor's documentation
    private readonly axios: AxiosInstance;

    constructor(loader: any, uploadUrl: string) {
        // CKEditor FileLoader instance
        this.loader = loader;

        // Axios instance for making HTTP requests
        this.axios = axios.create();

        // Configure the axios instance to use the provided upload URL
        this.axios.defaults.baseURL = uploadUrl;
    }

    // Implement the upload method required by CKEditor's UploadAdapter interface
    upload(): Promise<any> {
        return new Promise((resolve, reject) => {
            // Resolve the promise to get the file
            Promise.resolve(this.loader.file)
            .then((file: File) => {
                const data = new FormData();
                data.append('file', file);

                this.axios.post('', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response: AxiosResponse<any>) => {
                    // Assuming your server responds with the location of the uploaded file
                    const imageUrl = response.data.location;
                    resolve({ default: imageUrl });
                })
                .catch((error: any) => {
                    reject(error);
                });
            })
            .catch((error: any) => {
                reject(error); // Handle any error while resolving the file promise
            });
        });
    }

    // Implement the abort method required by CKEditor's UploadAdapter interface
    abort(): void {
        // Optionally implement abort logic if needed
        // This method is called when the upload is canceled
    }
}

export default function CustomUploadAdapterPlugin( editor: any ) {
    editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader: any ) => {
        return new AxiosUploadAdapter( loader, BackEndUrl + "Upload?staticFileUrl=" + StaticFileUrl + "/" );
    };
}