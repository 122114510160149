import React, { useContext, useEffect, useState } from "react";
import NewsItems from "./NewsItems";
import NewsEdit from "./NewsEdit";
import { BackEndUrl, StaticFileUrl } from "../../App";
import { DeleteNews, GetCategories, GetNews } from "../../services/NewsServices";
import { UserContext } from "../../context/UserContext";
import { Permissions } from "../../enums/Permissions";
import { CurrentEditId, hideUiEventHandler, setCurrentEditing, showUiEventHandler } from "../../services/EditUiServices";
import { GetEntitiesByCategory, GetEntity, SetEntity } from "../../services/SiteEntitiesServices";
import axios from "axios";

const jalaliDate = require("jalali-date");

export default function News(props: any){
    let [isEditing, setIsEditing] = useState(0);

    let [activeCategories, setActiveCategories] = useState<number[]>([]);

    let [userData, userReducer] = useContext(UserContext);
    let [news, setNews] = useState<any[]>([]);
    let [categories, setCategories] = useState([]);
    let [currentEditingData, setCurrentEditingData] = useState({
        title: "",
        content: "",
        date: 0,
        id: 0,
        categoryId: 0
    });

    let [pageCategories, setPageCategories] = useState<number[]>([]);

    useEffect(() => {
        GetCategories().then((res) => {
            if (res == null){
                return;
            }
            setCategories(res);
        });
    }, []);

    useEffect(() => {
        setActiveCategories(props.data);
        setPageCategories(props.data);
    }, [props]);

    useEffect(() => {
        if (activeCategories?.length == 0){
            GetNews(null).then((res) => {
                if (res == null){
                    return null;
                }

                setNews(res);
            });

            return;
        }

        GetNews(activeCategories).then((res) => {
            if (res == null){
                return null;
            }
            
            setNews(res);
        });
    }, [activeCategories, pageCategories]);

    function newArticleHandle(ev: any){
        setIsEditing(-1);
    }

    function closeHandle(ev: any){
        hideUiEventHandler(null);
    }

    function editHandle(entityId: number){
        setCurrentEditing(CurrentEditId.replace("news-item-", ""));
        setIsEditing(entityId);
        news.forEach((item: any) => {
            if (item.id == entityId){
                setCurrentEditingData({
                    title: item.title,
                    content: item.content,
                    date: item.publishDate,
                    categoryId: item.categoryId,
                    id: item.id
                });
            }
        });
    }

    function removeHandle(entityId: number){
        DeleteNews(entityId).then((res) => {
            if (res == null){
                alert("مشکلی در حذف به وجود امد");
                return;
            }

            window.location.reload();
        });
    }

    function mouseEnterEvent(ev: any, elementId: string){
        if (!userData.IsLoggedIn || !userData.Roles.includes(Permissions.ChangeWebsiteSettings)){
            return;
        }
        showUiEventHandler(ev, elementId, () => {}, () => {editHandle(parseInt(elementId.replace("news-item-", "")))}, () => {removeHandle(parseInt(elementId.replace("news-item-", "")))}, null, null);
    }

    function switchCategory(id: number){
        let cacheCategories = [...pageCategories];
        if (pageCategories.includes(id)){
            setPageCategories(pageCategories.filter((category) => category != id));
            cacheCategories = cacheCategories.filter((category) => category != id);
        }else{
            setPageCategories([...pageCategories, id]);
            cacheCategories = [...cacheCategories, id]
        }

        axios.put(BackEndUrl + "Centers/NewsCategories", {
            centerName: props.name,
            categories: cacheCategories
        }, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("UserToken")
            }
        });
        setActiveCategories(cacheCategories);
    }

    function setCategory(id: number){
        if (activeCategories.includes(id)){
            setActiveCategories(activeCategories.filter((category) => category != id));
        }else{
            setActiveCategories([...activeCategories, id]);
        }
    }

    function closeEdit(){
        setIsEditing(0);
        setCurrentEditingData({
            title: "",
            content: "",
            date: 0,
            id: 0,
            categoryId: 0
        });
    }

    if (props.limit){
        let firstRowRemaining = 3;
        let secondRowRemaining = 3;
        let spansArray = [3, 4, 5];

        let cacheSpansArray: number[] = [];

        return <section className="news px-5 py-50px mt-16">
            {
                isEditing != 0? <NewsEdit closeCallback={closeEdit} id={currentEditingData.id} title={currentEditingData.title} content={currentEditingData.content} category={currentEditingData.categoryId} date={currentEditingData.date}></NewsEdit> : <></>
            }
        <div className="flex flex-row justify-center">
            <div className="flex flex-col justify-center gap-3">
                <div className="text-32px text-center flex flex-row gap-4 justify-center" id="news-header-title">
                    {
                        props.customTitle? props.customTitle : "روی خط راوینو"
                    }
                    {
                        userData.IsLoggedIn && (userData.Roles.includes(Permissions.AddArticle) || userData.Roles.includes(Permissions.Owner))? 
                        <div id="submit-button" className="flex flex-col justify-center text-xl px-4 text-center rounded-full hover:cursor-pointer" onClick={newArticleHandle}>
                            <i className="fa-solid fa-plus"></i>
                        </div> : <></>
                    }
                </div>
                {
                    props.link == true? <p className="text-sm text-center" id="news-header-content">
                                            در راوینو چه میگذرد <a href="/News" style={{color: "rgba(88, 204, 220, 1)"}}>(آرشیو اخبار)</a>
                                        </p> :  <div className="flex gap-4 flex-wrap" dir="rtl">
                                                    {
                                                        categories.map((category: any) => {
                                                            return <a href="#" className={`rounded-30px text-center py-2 px-6 popup-category ${activeCategories.includes(category.id)? "popup-category-active" : ""} text-xs`} onClick={() => {setCategory(category.id);}}>{category.name}</a>
                                                        })
                                                    }
                                                </div>
                }
                <div className="flex gap-4 flex-wrap">
                    {
                        props.name && userData.Roles.includes(Permissions.ChangeWebsiteSettings)?  categories.map((category: any) => {
                            return <a href="#" className={`rounded-30px text-center py-2 px-6 popup-category ${pageCategories.includes(category.id)? "popup-category-active" : ""} text-xs`} onClick={(ev) => {ev.preventDefault(); switchCategory(category.id);}}>{category.name}</a>
                        }) : <></>
                    }
                </div>             
            </div>
        </div>
        <div className="px-5 py-16 text-center grid grid-cols-12 gap-x-5 gap-y-8 phone:gap-x-0 tablet:grid-cols-1 tablet:gap-x-1 phone:grid-cols-1 phone:px-0" dir="rtl">
            {news.map((value: any) => {
                if (firstRowRemaining <= 0){
                    return;
                }

                let selctedSpan = 3;

                if (cacheSpansArray.length <= 0){
                    cacheSpansArray = shuffleSpans(spansArray);
                }

                selctedSpan = cacheSpansArray[cacheSpansArray.length - 1];
                cacheSpansArray.pop();


                let dateInPersian = new jalaliDate(new Date(value.publishDate * 1000 + new Date().getTimezoneOffset() * 60 * 1000));

                let dateInText = dateInPersian.format("YYYY MMM DD");

                firstRowRemaining--;
                return <NewsItems content={value.content} id={value.id} title={value.title} span={selctedSpan} headerImage={value.landingImage? StaticFileUrl + "/" + value.landingImage : null} category={value.categoryName} date={dateInText} onMouseMove={(ev: any) => mouseEnterEvent(ev, "news-item-" + value.id)}></NewsItems>
            })}
            {news.map((value: any) => {
                if (firstRowRemaining < 3){
                    firstRowRemaining++;
                    return;
                }
                if (secondRowRemaining <= 0){
                    return;
                }

                let selctedSpan = 3;

                if (cacheSpansArray.length <= 0){
                    cacheSpansArray = shuffleSpans(spansArray);
                }

                selctedSpan = cacheSpansArray[cacheSpansArray.length - 1];
                cacheSpansArray.pop();


                let dateInPersian = new jalaliDate(new Date(value.publishDate * 1000 + new Date().getTimezoneOffset() * 60 * 1000));

                let dateInText = dateInPersian.format("YYYY MMM DD");

                secondRowRemaining--;
                return <NewsItems id={value.id} content={value.content} title={value.title} span={selctedSpan} headerImage={value.landingImage? StaticFileUrl + "/" + value.landingImage : null} category={value.categoryName} date={dateInText} onMouseMove={(ev: any) => mouseEnterEvent(ev, "news-item-" + value.id)}></NewsItems>
            })}
        </div>
        </section>
    }

    let rowsRemaining: number[] = [];
    let spansArray: number[] = [];
    let rowCounter = 0;

    return <section className="news px-5 py-50px mt-16">
            {
                isEditing != 0? <NewsEdit closeCallback={closeEdit} id={currentEditingData.id} title={currentEditingData.title} content={currentEditingData.content} category={currentEditingData.categoryId} date={currentEditingData.date}></NewsEdit> : <></>
            }
        <div className="flex flex-row justify-center">
            <div className="flex flex-col justify-center gap-3">
                <div className="text-32px text-center flex flex-row gap-4 justify-center" id="news-header-title">
                    {
                        props.customTitle? props.customTitle : "روی خط راوینو"
                    }
                    {
                        userData.IsLoggedIn && (userData.Roles.includes(Permissions.AddArticle) || userData.Roles.includes(Permissions.Owner))? 
                        <div id="submit-button" className="flex flex-col justify-center text-xl px-4 text-center rounded-full hover:cursor-pointer" onClick={newArticleHandle}>
                            <i className="fa-solid fa-plus"></i>
                        </div> : <></>
                    }
                </div>
                {
                    props.link == true? <p className="text-sm text-center" id="news-header-content">
                    راوینو همراه شما در مسیر شبکه‌سازی محتوایی! <a href="/News" style={{color: "rgba(88, 204, 220, 1)"}}>(آرشیو اخبار)</a>
                </p> :  <div className="flex gap-4 flex-wrap" dir="rtl">
                            {
                                categories.map((category: any) => {
                                    return <a href="#" className={`rounded-30px text-center py-2 px-6 popup-category ${activeCategories?.includes(category.id)? "popup-category-active" : ""} text-xs`} onClick={() => {setCategory(category.id);}}>{category.name}</a>
                                })
                            }
                        </div>
                }
            </div>
        </div>
        <div className="px-5 py-16 text-center grid grid-cols-12 gap-x-5 gap-y-8 phone:gap-x-0 tablet:grid-cols-1 tablet:gap-x-1 phone:grid-cols-1 phone:px-0" dir="rtl">
            {news.map((value: any, key: number) => {
                if (spansArray.length == 0){
                    rowCounter++;
                    spansArray = shuffleSpans([3, 4, 5]);
                    rowsRemaining.push(3); 
                }
                if (rowsRemaining[key] <= 0){
                    return;
                }

                let selctedSpan = 3;

                if (spansArray.length <= 0){
                    spansArray = shuffleSpans(spansArray);
                }

                selctedSpan = spansArray[spansArray.length - 1];
                spansArray.pop();


                let dateInPersian = new jalaliDate(new Date(value.publishDate * 1000 + new Date().getTimezoneOffset() * 60 * 1000));

                let dateInText = dateInPersian.format("YYYY MMM DD");
                rowsRemaining[key]--;

                return <NewsItems content={value.content} id={value.id} title={value.title} span={selctedSpan} headerImage={value.landingImage? StaticFileUrl + "/" + value.landingImage : null} category={value.categoryName} date={dateInText} onMouseMove={(ev: any) => mouseEnterEvent(ev, "news-item-" + value.id)}></NewsItems>
            })}
        </div>
        </section>
    
    
}

function shuffleSpans<T>(array: T[]): T[] {
    const shuffledArray = array.slice();

    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));

        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }

    return shuffledArray;
}