import React, { useContext, useEffect, useRef, useState } from "react";
import { BackEndUrl, StaticFileUrl } from "../../App";
import { UserContext } from "../../context/UserContext";
import { GetEntitiesByCategory, SetEntity } from "../../services/SiteEntitiesServices";
import { CurrentEditId, CurrentFocusId, hideUiEventHandler, setCurrentEditing, showUiEventHandler } from "../../services/EditUiServices";
import { Permissions } from "../../enums/Permissions";
import ReactQuill from "react-quill";
import axios from "axios";
import LandingLinkEdit from "./LandingLinkEdit";

export default function FilmLanding(props: {data: any[]}){
    let [data, setData] = useState<any[]>([]);

    useEffect(() => {
        GetEntitiesByCategory("film-landing").then((res) => {
            setData(res);
        });
    }, [])


    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': [] }, { 'background': [] }]
        ],
    };

    const formats = [
        'bold', 'italic', 'underline', 'strike', 'color', 'background'
    ];

    let [userData, userReducer] = useContext(UserContext);

    let [title, setTitle] = useState("مرکز راوی فیلم");
    let [description, setDescription] = useState("لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگر‌ها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربرد‌های متنوع با هدف بهبود ابزار‌های کاربردی می باشد. کتاب‌های زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد.");
    let [image, setImage] = useState(StaticFileUrl + "/film.png");
    let [linkTitle, setLinkTitle] = useState("دیدن راوی فیلم");
    let [linkUrl, setLinkUrl] = useState("#")

    let [editTitle, setEditTitle] = useState("");
    let [editDescription, setEditDescription] = useState("");
    let [editImage, seteditImage] = useState("");
    let [editLinkTitle, setEditLinkTitle] = useState("");
    let [editLinkUrl, setEditLinkUrl] = useState("");

    let editTitleRef = useRef(editTitle);
    let editDescriptionRef = useRef(editDescription);
    let editImageRef = useRef(editImage);
    let editLinkTitleRef = useRef(editLinkTitle);
    let editLinkUrlRef = useRef(editLinkUrl);

    let [isEditing, setIsEditing] = useState("");

    useEffect(() => {
        data.forEach((d) => {
            if (d.entityId == "film-landing-title"){
                setTitle(d.content);
                setEditTitle(d.content);
            }
            if (d.entityId == "film-landing-description"){
                setDescription(d.content);
                setEditDescription(d.content);
            }
            if (d.entityId == "film-landing-image"){
                setImage(d.content);
                seteditImage(d.content);
            }
            if (d.entityId == "film-landing-link-title"){
                setLinkTitle(d.content);
                setEditLinkTitle(d.content);
            }
            if (d.entityId == "film-landing-link-url"){
                setLinkUrl(d.content);
                setEditLinkUrl(d.content);
            }
        });
    }, [data])

    function changeHandler(content: string){
        switch (CurrentEditId){
            case "film-landing-title":
                setEditTitle(content);
                break;
            case "film-landing-description":
                setEditDescription(content);
                break;
            case "film-landing-link-title":
                setEditLinkTitle(content)
                break;
            case "film-landing-link-url":
                setEditLinkUrl(content);
                break;
        }
    }

    function handleKeyDown(isSubmit = true){
        if (isSubmit){
            switch (CurrentEditId){
                case "film-landing-title":
                    SetEntity("film-landing-title", editTitleRef.current, "film-landing", "film").then((res) => {
                        if (res == null){
                            alert("مشکلی در اپلود به وجود امد");
                            return;
                        }

                        window.location.reload();
                    });
                    break;
                case "film-landing-description":
                    SetEntity("film-landing-description", editDescriptionRef.current, "film-landing", "film").then((res) => {
                        if (res == null){
                            alert("مشکلی در اپلود به وجود امد");
                            return;
                        }

                        window.location.reload();
                    });
                    break
            }
            return;
        }

    }

    useEffect(() => {
        editTitleRef.current = editTitle;
        editDescriptionRef.current = editDescription;
        editLinkTitleRef.current = editLinkTitle;
        editLinkUrlRef.current = editLinkUrl;

        window.addEventListener("keydown", checkButton);

        if (CurrentEditId.includes("film-landing")){
            let element = document.querySelector(".ql-container") as HTMLElement | null;

            if (element != null){
                element.onmousemove = showUi;
            }
        }

        function checkButton(ev: any){
            if (ev.key == "Escape"){
                handleKeyDown(false);
            }
            if (ev.key == "Enter"){
                handleKeyDown();
            }
        }

        function showUi(ev: any){
            showUiEventHandler(ev, CurrentFocusId, switchHandle, null, null, closeHandle, submitHandle);
        }

        return () => {
            window.removeEventListener("keydown", checkButton);
        };
    }, [editTitle, editDescription, editLinkTitle, editLinkUrl]);

    function switchHandle(){
        hideUiEventHandler(null);
    }

    function closeHandle(){
        setIsEditing("");
        hideUiEventHandler(null);
        setCurrentEditing("");
    }

    function submitHandle(ev: any){
        handleKeyDown(true);
        hideUiEventHandler(ev);
    }

    function editHandle(ev: any){
        if (CurrentFocusId == "film-landing-image"){
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.click();
      
            input.onchange = async () => {
                const file = input.files ? input.files[0] : null;

                if (file == null){
                    return;
                }

                let formData = new FormData();
                formData.append("File", file);

                axios.post(BackEndUrl + "Upload", formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("UserToken"),
                        "Access-Control-Allow-Origin": "*"
                    }
                }).then((res) => {
                    let fileName = res.data.fileName;

                    SetEntity("film-landing-image", fileName, "film-landing", "film").then((res) => {
                        if (res == null){
                            alert("مشکلی در اپلود عکس پیش امد");
                            return;
                        }

                        window.location.reload();
                    });
                });
            }
            return;
        }

        setIsEditing(CurrentFocusId);
        setCurrentEditing(CurrentFocusId);

        if (ev != null){
            showUiEventHandler(ev, isEditing, switchHandle, null, null, closeHandle, submitHandle);
        }
    }

    function mouseEnterEvent(ev: any, elementId: string){
        if (!userData.IsLoggedIn || !userData.Roles.includes(Permissions.ChangeWebsiteSettings)){
            return;
        }
        showUiEventHandler(ev, elementId, switchHandle, editHandle, null, null, null);
    }

    return <div className="mt-14 landing-body px-5 phone:mt-2">
            {
                isEditing == "film-landing-link"? <LandingLinkEdit closeHandle={closeHandle}></LandingLinkEdit> : <></>
            }
            <div className="flex flex-row-reverse mt-6 justify-center phone:flex-col">
                <div className="flex flex-col ml-12 py-7 phone:flex-row phone:justify-center phone:ml-0" onMouseMove={((ev) => {mouseEnterEvent(ev, "film-landing-image")})}>
                    <img alt="راوی فیلم" className="h-[150px], w-[150px] phone:h-[100px] phone:w-[100px]" src={StaticFileUrl + "/" + image}></img>
                </div>
                <div className="flex flex-col justify-center w-[680px] gap-4 phone:w-auto" dir="rtl">
                {
                    isEditing == "film-landing-title"? <div id="landing-text-editor"><ReactQuill
                    value={editTitle}
                    onChange={(newContent: any) => {changeHandler(newContent)}}
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    /></div> : 
                    <h1 className="text-5xl align-middle text-right py-1 text-[36px] phone:text-center" onMouseMove={(ev) => {mouseEnterEvent(ev, "film-landing-title")}} id="landing-header">
                        <div dangerouslySetInnerHTML={{ __html: title }} />
                    </h1>
                }
                {
                    isEditing == "film-landing-description"? <div id="landing-text-editor"><ReactQuill
                    value={editDescription}
                    onChange={(newContent) => {changeHandler(newContent)}}
                    modules={modules}
                    formats={formats}
                    theme="snow"
                  /></div> :
                    <p className="text-base align-middle text-right py-1 mt-2" id="landing-content-film" onMouseMove={(ev) => {mouseEnterEvent(ev, "film-landing-description")}}>
                        <div dangerouslySetInnerHTML={{ __html: description }} />
                    </p>
                }
                    
                    <div className="py-1 w-40 flex flex-row">
                        <i className="fa-solid fa-link py-2 text-2xl ml-2" style={{color: "#79C8D7"}}></i>
                        <a href={linkUrl} id="landing-video-text" className="text-center text-sm" onMouseMove={(ev) => mouseEnterEvent(ev, "film-landing-link")}>
                            {linkTitle}
                        </a>
                    </div>
                </div>
            </div>
        </div>
}