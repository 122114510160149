import React from "react";

export default function ActivityItem(props: any){
    return  <>
                <div className="col-span-2 activity-item flex flex-col justify-center rounded-[30px] p-16 gap-8 phone:p-6" onMouseEnter={(ev) => props.mouseEnterEvent(ev, props.id)}>
                    <h2 className="activity-title text-[32px] text-center">
                        {props.title}
                    </h2>
                    <p className="activity-content text-lg">{props.content}</p>
                </div>
            </>
}