import Events from "../components/Events/Events";
import Header from "../components/Header/Header";
import AboutUs from "../components/Index/AboutUs/AboutUs";
import News from "../components/News/News";
import Coworker from "../components/Coworker/Coworker";
import ContactUs from "../components/Index/ContactUs/ContactUs";
import FAQ from "../components/Index/FAQ/FAQ";
import Footer from "../components/Footer/Footer";
import IndexLanding from "../components/Landing/IndexLanding";
import { GetEntitiesByCategory } from "../services/SiteEntitiesServices";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { hideUiEventHandler, mouseEnterSelfEvent } from "../services/EditUiServices";
import Globe from "../components/Globe/Globe";

export default function IndexPage(){
    let [landingData, setLandingData] = useState<any[]>([]);

    useEffect(() => {
        GetEntitiesByCategory("index-landing").then((res) => {
            if (res == null){
                return;
            }

            setLandingData(res);
        });

        window.addEventListener("scroll", (ev) => {
            hideUiEventHandler(ev);
        });
    }, []);

    if (landingData.length == 0){
        return <></>
    }


    return  <div className="page-container">
                <div id="main-background">

                </div>
                <div id="element-actions" className="flex flex-row flex-warp gap-3" onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                    <div id="edit-button" className="text-xl px-4 py-3 rounded-full hover:cursor-pointer" hidden onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                        <i className="fa-regular fa-pen"></i>
                    </div>
                    <div id="delete-button" className="text-xl px-4 py-3 rounded-full hover:cursor-pointer" hidden onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                        <i className="fa-regular fa-trash"></i>
                    </div>
                    <div id="close-button" className="text-xl px-4 py-3 rounded-full hover:cursor-pointer" hidden onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                    <div id="submit-button" className="text-xl px-4 py-3 rounded-full hover:cursor-pointer" hidden onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                        <i className="fa-solid fa-check"></i>
                    </div>
                </div>
                {/*<Globe key="unique-key" widthPercent={40} heightPercent={90} />*/}
                <Header landing={<IndexLanding data={landingData}></IndexLanding>}></Header>
                <AboutUs></AboutUs>
                <News limit={true} link={true}></News>
                <Events limit={true} link={true}></Events>
                <Coworker></Coworker>
                <ContactUs></ContactUs>
                <FAQ></FAQ>
                <Footer></Footer>
            </div>
}