import React, { useEffect, useState } from "react";
import { SetEntity } from "../../../services/SiteEntitiesServices";

export default function AboutUsEdit(props: {id: number, title: string, icon: string, closeHandler: any}){
    let [title, setTitle] = useState("");
    let [icon, setIcon] = useState("");
    let [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setTitle(props.title);
        setIcon(props.icon);
    }, []);

    function submitHandler(){
        if (isLoading){
            return;
        }
        setIsLoading(true);
        if (title == "" || icon == ""){
            alert("لطفا فیلد‌ها را کامل کنید");
            return;
        }

        let object = {
            title: title,
            icon: icon
        }

        SetEntity("index-aboutus-item" + props.id, JSON.stringify(object), "index-aboutus", "index").then((res) => {
            setIsLoading(false);
            if (res == null){
                alert("مشکلی در ویرایش پیش آمد");
                return;
            }

            window.location.reload();
        });
    }

    return <section className="popups flex flex-row justify-center py-12 px-72 tablet:px-0">
        <div className="flex flex-col popup bg-white p-8 rounded-15px">
            <div className="popup-content">
                <div className="popup-header flex flex-row-reverse justify-between" onClick={() => {props.closeHandler()}}>
                    <h4 className="popup-header-title text-2xl">درباره ما</h4>
                    <i className="fa-solid fa-x" style={{cursor: "pointer"}}></i>
                </div>
                <div className="popup-tabs flex flex-row-reverse justify-start px-4 gap-6 mt-5">
                </div>
                <div className="grid grid-cols-2 gap-6 justify-center mt-4">
                    <div className="popup-input col-span-2 rounded-15px px-2 py-2 my-4">
                        <input type="text" className="w-full h-full text-right" placeholder="عنوان" value={title} onChange={(ev) => setTitle(ev.target.value)}></input>
                    </div>
                    <div className="popup-input col-span-2 rounded-15px px-2 py-2 my-4">
                        <input type="text" className="w-full h-full text-right" placeholder="ایکون از fontawesome.com" value={icon} onChange={(ev) => setIcon(ev.target.value)}></input>
                    </div>
                </div>
                
                <div className="flex flex-row-reverse justify-center gap-4 mt-6">
                    <a href="#" className="rounded-30px text-center py-4 px-10 popup-submit text-xs" onClick={submitHandler}>{isLoading? <i className="fa-solid fa-spinner"></i> : <p>انتشار</p>}</a>
                    <a href="#" className="rounded-30px text-center py-4 px-10 popup-cancel text-xs" onClick={(ev) => {ev.preventDefault();props.closeHandler()}}>لغو</a>
                </div>
            </div>
        </div>
    </section>
}