import { DatePicker } from "jalaali-react-date-picker";
import React, { useEffect, useState } from "react";
import { SetEntity } from "../../services/SiteEntitiesServices";

export default function FooterSocialMediaEdit(props: any){
    let [isLoading, setIsLoading] = useState(false);
    let [des, setDes] = useState("");

    useEffect(() => {
        setDes(props.content);
    }, []);

    function submitHanlder(){
        if (isLoading){
            return;
        }
        setIsLoading(true);
        SetEntity("footer-description", des, "footer-content", "footer").then((res) => {
            setIsLoading(false);
            if (res == null){
                alert("مشکلی در ویرایش به وجود امد");
                return;
            }

            window.location.reload();
        });
    }

    return <section className="popups flex flex-row justify-center py-12 px-72 tablet:px-0">
    <div className="flex flex-col popup bg-white p-8 rounded-15px">
        <div className="popup-content">
            <div className="popup-header flex flex-row justify-between" style={{cursor: "pointer"}} onClick={props.closeHandler}>
                <h4 className="popup-header-title text-2xl">ویرایش اطلاعات فوتر</h4>
                <i className="fa-solid fa-x"></i>
            </div>
            <div className="popup-tabs flex flex-row-reverse justify-start px-4 gap-6 mt-5">
            </div>
            <div className="grid grid-cols-2 gap-6 justify-center mt-4" dir="rtl">
                <div className="popup-input col-span-1 rounded-15px px-2 py-3 my-4">
                    <input type="text" className="w-full h-full text-right" placeholder="توضیحات" value={des} onChange={(ev) => setDes(ev.target.value)}></input>
                </div>
            </div>

            <div className="flex flex-row-reverse justify-center gap-4 mt-6">
                <a href="#" className="rounded-30px text-center py-4 px-10 popup-submit text-xs" onClick={() => submitHanlder()}>{isLoading? <i className="fa-solid fa-spinner"></i> : <p>انتشار</p>}</a>
                <a href="#" className="rounded-30px text-center py-4 px-10 popup-cancel text-xs" onClick={props.closeHandler}>لغو</a>
            </div>
        </div>
    </div>
</section>
}