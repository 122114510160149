import React, { useContext, useEffect, useState } from "react";
import StaticsItem from "./StaticsItem";
import { UserContext } from "../../context/UserContext";
import EditStatic from "./EditStatic";
import { Permissions } from "../../enums/Permissions";
import { CurrentEditId, CurrentFocusId, hideUiEventHandler, setCurrentEditing, showUiEventHandler } from "../../services/EditUiServices";
import { DeleteEntity, GetEntitiesByCategory } from "../../services/SiteEntitiesServices";
import axios from "axios";
import { BackEndUrl } from "../../App";

export default function Statics(props: {data: any[], name:string, customTitle: string}){
    let [userData, userReducer] = useContext(UserContext);
    let [statics, setStatics] = useState<any[]>([]);

    let [isEditingTitle, setIsEditingTitle] = useState(false); 

    function getStatic(id: number){
        return statics.filter(d => d.id == id)[0];
    }

    let [isEditing, setIsEditing] = useState<number | null>(null);

    useEffect(() => {
        let cacheStatis: any[] = [];

        if (userData.Roles.includes(Permissions.ChangeWebsiteSettings)){
            cacheStatis.push({
                id: 0,
                icon: "plus",
                content: "اضافه کردن"
            });
        }

        props.data.map((d) => {
            cacheStatis.push({
                id: d.id,
                icon: d.icon,
                content: d.title
            });
        });
        
        /*GetEntitiesByCategory(props.updateCategory).then((res) => {
            res.forEach((staticItem: any) => {
                let decode = JSON.parse(staticItem.content);
                cacheStatis.push({
                    entityId: staticItem.entityId,
                    icon: decode.icon,
                    content: decode.content
                });
            });

            if (userData.Roles.includes(Permissions.ChangeWebsiteSettings)){
                cacheStatis.push({
                    entityId: "",
                    icon: "plus",
                    content: "اضافه کردن"
                });
            }

            
        });*/
        setStatics(cacheStatis);
    }, [props]);


    let rows = Math.floor(1 + statics.length / 4);
    let rowsPassed = 0;
    let htmlElement: any[] = [];

    while (rows > 0){
        let intCounter = 4;
        let skipAmount = rowsPassed * 4;
        htmlElement.push(
            <div className="flex flex-row phone:grid phone:grid-cols-2 phone:gap-y-3 justify-center mt-16">
                {
                    statics.map((stat: any, index: number) => {
                        if (skipAmount > 0){
                            skipAmount--;
                            return;
                        }
                        if (intCounter <= 0){
                            return;
                        }

                        intCounter--;
                        var putRight = true;
                        if (statics[index + 1] == undefined || intCounter == 0){
                            putRight = false
                        }

                        if (stat.id == 0){
                            return <StaticsItem putRight={putRight} icon={stat.icon} id={stat.id} value={stat.content} addHandle={addHandle}></StaticsItem>
                        }

                        return <StaticsItem putRight={putRight} id={stat.id} icon={stat.icon} value={stat.content} mouseEnterEvent={mouseEnterEvent}></StaticsItem>
                    })
                }
            </div>
        );
        rows--;
        rowsPassed++;
    }

    function switchHandle(){
        hideUiEventHandler(null);
    }

    function editHandle(ev: any){
        setIsEditing(Number(CurrentFocusId))
        setCurrentEditing(CurrentFocusId);

        if (ev != null){
            hideUiEventHandler(ev);
        }
    }

    function closeHandle(){
        setIsEditing(null);
        hideUiEventHandler(null);
    }

    function removeHandle(){
        axios.delete(BackEndUrl + "Centers/Statistic",{
            params: {
                id: Number(CurrentFocusId)
            },
            headers: {
                Authorization: "Bearer " + localStorage.getItem("UserToken")
            }
        }).then((res) => {
            alert(res.data.message);
            window.location.reload();
            return;
        });
    }

    function addHandle(){
        setIsEditing(0);
    }

    function mouseEnterEvent(ev: any, elementId: number){
        if (!userData.IsLoggedIn || !userData.Roles.includes(Permissions.ChangeWebsiteSettings)){
            return;
        }
        showUiEventHandler(ev, String(elementId), switchHandle, editHandle, removeHandle, null, null);
    }

    return <section className="statics px-5 py-3 mt-20">
        {
            isEditing != null? <EditStatic name={props.name} closeHandler={closeHandle} id={isEditing == 0? null : isEditing} title={getStatic(isEditing).content} icon={getStatic(isEditing).icon} category={props}></EditStatic> : <></>
        }
    <div className="flex flex-col justify-center gap-3">
        <h3 className="text-38px text-center phone:text-2xl" id="news-header-title">
            آمار <span style={{color: "rgba(121, 200, 215, 1)"}}>{props.customTitle}</span> در یک نگاه
        </h3>
    </div>
    {
        htmlElement
    }
</section>
}