import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import { CurrentEditId, CurrentFocusId, hideUiEventHandler, setCurrentEditing, showUiEventHandler } from "../../../services/EditUiServices";
import { Permissions } from "../../../enums/Permissions";
import { GetEntitiesByCategory, SetEntity } from "../../../services/SiteEntitiesServices";
import ContactUsEdit from "./ContactUsEdit";

export default function ContactUs(props: any){
    let [userData, userReducer] = useContext(UserContext);
    let [data, setData] = useState<any[]>([]);

    let sectionRef = useRef<any>(null);

    useEffect(() => {
        setTimeout(() => {
            let scrollId = window.location.href.slice(window.location.href.indexOf("#") + 1);

            if (scrollId == "contactus"){
                let linkElement = document.createElement("a");
                linkElement.href = "#contactus";
    
                linkElement.click();
            }
        }, 500);
      }, []);

    let [phoneNumber, setPhoneNumber] = useState("۰۲۱-۱۱۲۲۳۳۴۴");
    let [email, setEmail] = useState("info@example.com");
    let [address, setAddress] = useState("تهران ، میدان آزادی ، خیابان 14 شرقی ، پلاک 20 ، واحد 1");
    let [locationLink, setLocationLink]= useState("https://www.openstreetmap.org/export/embed.html?bbox=46.30947589874268%2C38.055255376464714%2C46.33707046508789%2C38.066068099727076&layer=mapnik&marker=38.060661937812846%2C46.32327318191528");

    let [isEditing, setIsEditing] = useState("");

    let [editPhoneNumber, setEditPhoneNumber] = useState("");
    let [editEmail, setEditEmail] = useState("");
    let [editAddress, setEditAddress] = useState("");
    let [editLocationLink, setEditLocationLink] = useState("");

    let editPhoneNumberRef = useRef(editPhoneNumber);
    let editEmailRef = useRef(editEmail);
    let editAddressRef = useRef(editAddress);
    let editLocationLinkRef = useRef(editLocationLink);

    let contactUsRef = useRef<any>();
    
    useEffect(() => {
        GetEntitiesByCategory("index-contactus").then((res) => {
            if (res == null){
                return;
            }

            setData(res);
        });
    }, [])

    useEffect(() => {
        data.forEach((d) => {
            if (d.entityId == "index-contactus-phonenumber"){
                setPhoneNumber(d.content);
                setEditPhoneNumber(d.content);
            }
            if (d.entityId == "index-contactus-email"){
                setEmail(d.content);
                setEditEmail(d.content);
            }
            if (d.entityId == "index-contactus-address"){
                setAddress(d.content);
                setEditAddress(d.content);
            }
            if (d.entityId == "index-contactus-location"){
                setLocationLink(d.content);
                setEditLocationLink(d.content);
            }
        });
    }, [data]);

    useEffect(() => {
        editAddressRef.current = editAddress;
        editPhoneNumberRef.current = editPhoneNumber;
        editLocationLinkRef.current = editLocationLink;
        editEmailRef.current = editEmail;
    }, [editAddress, editEmail, editPhoneNumber, editLocationLink])

    function changeHandler(id: string, content: string, ev: any){
        if (id == "index-contactus-phonenumber"){
            setEditPhoneNumber(content);
        }
        if (id == "index-contactus-email"){
            setEditEmail(content);
        }
        if (id == "index-contactus-address"){
            setEditAddress(content);
        }
        if (id == "index-contactus-locationlink"){
            setEditLocationLink(content);
        }

        if (ev != null){
            showUiEventHandler(ev, CurrentEditId, switchHandle, null, null, closeHandle, submitHandle);
        }
    }

    function handleKeyDown(isSubmit = true){
        if (isSubmit){
            switch (CurrentEditId){
                case "index-contactus-phonenumber":
                    SetEntity("index-contactus-phonenumber", editPhoneNumberRef.current, "index-contactus", "index").then((res) => {
                        if (res == null){
                            alert("مشکلی در ویرایش به وجود امد");
                            setIsEditing("");
                            hideUiEventHandler(null);
                            return;
                        }

                        setPhoneNumber(editPhoneNumberRef.current);
                        setIsEditing("");
                        hideUiEventHandler(null);
                    });
                    break;
                case "index-contactus-email":
                    SetEntity("index-contactus-email", editEmailRef.current, "index-contactus", "index").then((res) => {
                        if (res == null){
                            alert("مشکلی در ویرایش به وجود امد");
                            setIsEditing("");
                            hideUiEventHandler(null);
                            return;
                        }

                        setEmail(editEmailRef.current);
                        setIsEditing("");
                        hideUiEventHandler(null);
                    });
                    break;
                case "index-contactus-address":
                    SetEntity("index-contactus-address", editAddressRef.current, "index-contactus", "index").then((res) => {
                        if (res == null){
                            alert("مشکلی در ویرایش به وجود امد");
                            setIsEditing("");
                            hideUiEventHandler(null);
                            return;
                        }

                        setAddress(editAddressRef.current);
                        setIsEditing("");
                        hideUiEventHandler(null);
                    });
                    break;
                case "index-contactus-locationlink":
                    SetEntity("index-contactus-locationlink", editLocationLinkRef.current, "index-contactus", "index").then((res) => {
                        if (res == null){
                            alert("مشکلی در ویرایش به وجود امد");
                            setIsEditing("");
                            hideUiEventHandler(null);
                            return;
                        }
                        setLocationLink(editLocationLinkRef.current);
                        setIsEditing("");
                        hideUiEventHandler(null);
                    });
                    break;
            }
            return;
        }
        setIsEditing("");
        setCurrentEditing("");
    }

    function submitHandle(ev: any){
        handleKeyDown(true);
        hideUiEventHandler(ev);
    }

    useEffect(() => {
        window.addEventListener("keydown", checkButton);

        if (CurrentEditId.includes("index-landing")){
            let element = document.querySelector(".ql-container") as HTMLElement | null;

            if (element != null){
                element.onmousemove = showUi;
            }
        }

        function checkButton(ev: any){
            if (ev.key == "Escape"){
                handleKeyDown(false);
            }
            if (ev.key == "Enter"){
                handleKeyDown();
            }
        }

        function showUi(ev: any){
            showUiEventHandler(ev, CurrentFocusId, switchHandle, null, null, closeHandle, submitHandle);
        }

        return () => {
            window.removeEventListener("keydown", checkButton);
        };
    }, []);

    function switchHandle(){
        hideUiEventHandler(null);
    }

    function editHandle(ev: any){
        if (CurrentFocusId == "index-contactus-phonenumber"){
            setIsEditing("index-contactus-phonenumber");
            setCurrentEditing("index-contactus-phonenumber");
        }
        if (CurrentFocusId == "index-contactus-email"){
            setIsEditing("index-contactus-email");
            setCurrentEditing("index-contactus-email");
        }
        if (CurrentFocusId == "index-contactus-address"){
            setIsEditing("index-contactus-address");
            setCurrentEditing("index-contactus-address");
        }
        if (CurrentFocusId == "index-contactus-location"){
            setIsEditing("index-contactus-location");
            setCurrentEditing("index-contactus-location");
        }
    }

    function closeHandle(){
        setIsEditing("");
        hideUiEventHandler(null);
        setCurrentEditing("");
    }

    function mouseEnterEvent(ev: any, elementId: string){
        let x = ev.clientX;
        let y = ev.clientY;

        let react = (contactUsRef.current as HTMLElement).getBoundingClientRect();

        if (elementId == "index-contactus-location"){
            if (x < react.left + react.width && x > react.left && y < react.top + react.height && y > react.top){
                return;
            }
        }

        if (!userData.IsLoggedIn || !userData.Roles.includes(Permissions.ChangeWebsiteSettings)){
            return;
        }
        showUiEventHandler(ev, elementId, switchHandle, editHandle, null, null, null);
    }

    return <section ref={sectionRef} className="contantus py-50px mt-10 relative">
        {
            isEditing == "index-contactus-location"? <ContactUsEdit oldUrl={locationLink} closeHandler={closeHandle} ></ContactUsEdit> : <></>
        }
    <div className="flex flex-row justify-center gap-4">
        <div className="flex flex-col justify-center">
            <h3 className="text-32px text-center" id="news-header-title">
                تماس با ما
            </h3>
        </div>
    </div>
    <div id="contactus" className="contactus-content mt-10 flex flex-row justify-start phone:flex-col phone:justify-end" dir="rtl" onMouseMove={(ev) => {mouseEnterEvent(ev, "index-contactus-location")}}>
        <div ref={contactUsRef} className="contactus-address px-6 gap-6 flex flex-col justify-center w-[430px] h-[420px] mt-12 phone:mt-24 laptop:mr-8 tablet:mr-0 phone:w-auto phone:gap-0 phone:mx-2 phone:justify-end bg-white rounded-30px">
            <h3 className="text-[32px] contactus-address-title phone:hidden tablet:text-center">در دسترس هستیم!</h3>
            <div className="flex flex-row" style={{pointerEvents: "all"}}>
                <div className="rounded-full ml-2 h-16 w-16 flex flex-col justify-center text-center contactus-address-bubble text-3xl">
                    <i className="fa-solid fa-phone contactus-address-bubble-item text-2xl"></i>
                </div>
                <div className="flex flex-col">
                    <p className="text-lg contactus-address-information-title">تلفن(پاسخگویی در ساعات کاری)</p>
                    {
                        isEditing == "index-contactus-phonenumber"? <input className="text-xl contactus-address-information-content" style={{border: "1px solid #CCCCCC", borderRadius: "15px", padding: "4px"}} value={editPhoneNumber} onChange={(ev) => {changeHandler("index-contactus-phonenumber", ev.target.value, ev)}}></input> : <p className="text-xl contactus-address-information-content" onMouseMove={(ev) => {mouseEnterEvent(ev, "index-contactus-phonenumber")}}>{phoneNumber == ""? "وارد کنید..." : phoneNumber}</p>
                    }
                </div>
            </div>
            <div className="flex flex-row" style={{pointerEvents: "all"}}>
                <div className="rounded-full ml-2 h-16 w-16 flex flex-col justify-center text-center contactus-address-bubble text-3xl">
                    <i className="fa-regular fa-envelope contactus-address-bubble-item text-2xl"></i>
                </div>
                <div className="flex flex-col">
                    <p className="text-lg contactus-address-information-title">ایمیل</p>
                    {
                        isEditing == "index-contactus-email"? <input className="text-xl contactus-address-information-content" style={{border: "1px solid #CCCCCC", borderRadius: "15px", padding: "4px"}} value={editEmail} onChange={(ev) => {changeHandler("index-contactus-email", ev.target.value, ev)}}></input> : <p className="text-xl contactus-address-information-content" onMouseMove={(ev) => {mouseEnterEvent(ev, "index-contactus-email")}}>{email == ""? "وارد کنید..." : email}</p>
                    }
                </div>
            </div>
            <div className="flex flex-row" style={{pointerEvents: "all"}}>
                <div className="rounded-full ml-2 h-16 w-16 flex flex-col justify-center text-center contactus-address-bubble text-3xl">
                    <i className="fa-solid fa-location-dot contactus-address-bubble-item text-2xl w-16"></i>
                </div>
                <div className="flex flex-col">
                    <p className="text-lg contactus-address-information-title">آدرس دفتر</p>
                    {
                        isEditing == "index-contactus-address"? <input className="text-xl contactus-address-information-content" style={{border: "1px solid #CCCCCC", borderRadius: "15px", padding: "4px"}} value={editAddress} onChange={(ev) => {changeHandler("index-contactus-address", ev.target.value, ev)}}></input> : <p className="text-xl contactus-address-information-content" onMouseMove={(ev) => {mouseEnterEvent(ev, "index-contactus-address")}}>{address == ""? "وارد کنید..." : address}</p>
                    }
                </div>
            </div>
        </div>
        <div className="location-map w-full left-0 absolute" id="map-container" style={{"pointerEvents": "all"}} dangerouslySetInnerHTML={{ __html: locationLink }} />
        {/*<iframe className="location-map w-full left-0 absolute -z-10" src={locationLink} style={{border: "1px solid black"}}></iframe>*/}
    </div>
</section>
}
