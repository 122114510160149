import axios from "axios";
import { BackEndUrl } from "../App";
import { UserInitialValues } from "../context/UserContext";

export async function GetEntity(entityId: string){
    try{
        let fetchData = await axios.get(BackEndUrl + "SiteEntities", {
            params: {
                EntityId: entityId
            }
        });
        let entityData = fetchData.data;

        return entityData.data;
    }catch(e){
        return null;
    }
}

export async function  DeleteEntity(id: string) {
    try{
        let fetchData = await axios.delete(BackEndUrl + "SiteEntities?id=" + id, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("UserToken"),
                "Access-Control-Allow-Origin": "*",
            }
        });
        let entityData = fetchData.data;

        return entityData.message;
    }catch(e){
        return null;
    }
}

export async function GetEntitiesByCategory(categoryId: string){
    try{
        let fetchData = await axios.get(BackEndUrl + "SiteEntities/ByCategory", {
            params: {
                CategoryId: categoryId
            },
            headers:{
                "Access-Control-Allow-Origin": "*",
            }
        });
        let entityData = fetchData.data;

        return entityData.data;
    }catch(e){
        return null;
    }
}

export async function SetEntity(entityId: string, content: string, category: string, page: string){
    var token = "";
    
    if (localStorage.getItem("UserToken")){
        token = localStorage.getItem("UserToken") || "";
    }else {
        return UserInitialValues;
    }
    
    try{
        let fetchData = await axios.post(BackEndUrl + "SiteEntities", {
            entityId: entityId,
            content: content,
            page: page,
            category: category
        }, {
            headers: {
                Authorization: "Bearer " + token,
                "Access-Control-Allow-Origin": "*",
            }
        }
        );
        let entityData = fetchData.data;

        return entityData.message;
    }catch(e){
        console.log(e);
        return null;
    }
}