import React, { useContext, useEffect, useState } from "react";
import EventsItem from "./EventsItem";
import { DeleteEvent, GetEvents } from "../../services/EventsServices";
import { StaticFileUrl } from "../../App";
import { UserContext } from "../../context/UserContext";
import { Permissions } from "../../enums/Permissions";
import EditEvents from "./EditEvents";
import { CurrentFocusId, hideUiEventHandler, showUiEventHandler } from "../../services/EditUiServices";

const jalaliDate = require("jalali-date");

export default function Events(props: any){
    let [userData, userReducer] = useContext(UserContext);

    let [isEditing, setIsEditing] = useState(false);
    let [editingId, setEditingId] = useState<null | number>(null);

    let [events, setEvents] = useState<any[]>([]);

    useEffect(() => {
        GetEvents(!props.limit).then((res: any[]) => {
            if (res == null){
                return;
            }
            setEvents(res.reverse());
        })
    }, []);

    let renderRemaining = props.limit? 3 : 999999;

    function newEventHandler(){
        setIsEditing(true)
    }

    function getEvent(id: number){
        return events.filter(e => e.id == id)[0] as any;
    }

    function closeHandler(){
        setEditingId(null);
        setIsEditing(false);
    }

    function switchHandle(){
        hideUiEventHandler(null);
    }

    function editHandle(ev: any){
        let id = parseInt(CurrentFocusId.replace("event-", ""));

        setEditingId(id);
        setIsEditing(true);
    }

    function closeHandle(){
        setIsEditing(false);
        setEditingId(null);
        hideUiEventHandler(null);
    }

    function deleteHandler(){
        let id = parseInt(CurrentFocusId.replace("event-", ""));

        DeleteEvent(id).then((res) => {
            if (res == null){
                alert("مشکلی در حذف پیش امد");
                return;
            }

            window.location.reload();
        });
    }

    function mouseEnterEvent(ev: any, elementId: string){
        if (!userData.IsLoggedIn || !userData.Roles.includes(Permissions.ChangeWebsiteSettings)){
            return;
        }
        showUiEventHandler(ev, elementId, switchHandle, editHandle, deleteHandler, null, null);
    }

    let containerClass = "px-5 py-16 text-center grid grid-cols-12 gap-x-5 gap-y-8 phone:py-8 phone:grid-cols-1 phone:gap-x-0";

    if (renderRemaining > 3){
        containerClass += " events-container-indef"
    }else{
        containerClass += " events-container"
    }

    return <section className="events px-5 py-50px mt-10">
        {
            isEditing? <EditEvents closeHandler={closeHandler} id={editingId} link={getEvent(editingId || -1)?.link} title={getEvent(editingId || -1)?.title || null} location={getEvent(editingId || -1)?.location || null} date={new Date(getEvent(editingId || -1)?.eventDate * 1000) || null}></EditEvents> : <></>
        }
    <div className="flex flex-row justify-center">
        <div className="flex flex-col justify-center">
            <div className="text-32px text-center flex flex-row gap-4 justify-center" id="news-header-title">
                رویدادها
                {
                    userData.IsLoggedIn && (userData.Roles.includes(Permissions.AddArticle) || userData.Roles.includes(Permissions.Owner))? 
                    <div id="submit-button" className="flex flex-col justify-center text-xl px-4 text-center rounded-full hover:cursor-pointer" onClick={newEventHandler}>
                        <i className="fa-solid fa-plus"></i>
                    </div> : <></>
                }
            </div>
            {
                props.link? <p className="text-sm text-center" id="news-header-content">
                شبکه‌سازی محتوایی! <a href="/Events" style={{color: "rgba(88, 204, 220, 1)"}}>(برو به آرشیو رویداد‌ها)</a>
            </p> : ""
            }
        </div>
    </div>
    <div className={containerClass} dir="rtl">
        {
            events.map((event: any) => {
                if (renderRemaining <= 0){
                    return;
                }

                let persianDate = new jalaliDate(new Date(event.eventDate * 1000 + new Date().getTimezoneOffset() * 60 * 1000));
                renderRemaining--;

                return <EventsItem id={event.id} link={event.link} image={StaticFileUrl + "/" + event.headerImage} title={event.title} date={"تا " + persianDate.format("YYY MMM DD")} location={event.location} onMouseMoveEvent={mouseEnterEvent}></EventsItem>
            })
        }
    </div>
</section>
}