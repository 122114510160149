import { useLocation } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DeleteNews, GetNewsData } from "../services/NewsServices";
import { StaticFileUrl } from "../App";
import { mouseEnterSelfEvent } from "../services/EditUiServices";
import "./EditorStyle.css";
import "./ContentStyle.css";
import NewsEdit from "../components/News/NewsEdit";
import { UserContext } from "../context/UserContext";
import { Permissions } from "../enums/Permissions";
import OpenImagePopup from "../components/Popups/OpenImagePopup";

var jalaliDate = require("jalali-date");

export default function NewsDataPage(){
    let [isCopeid, setIsCopied] = useState(false);
    let state = useLocation();
    let [newsData, setNewsData] = useState({title: "", content: "", landingImage: "", publishDate: 1700000000, categoryName: "", id: 0, categoryId: 0});
    let [usedQuery, setUseQuery] = useState(false);
    let [articleId, setArticleId] = useState(0);
    let [userData, userReducer] = useContext(UserContext);

    let [isEditing, setIsEditing] = useState(false);
    let contentRef: any = useRef();

    useEffect(() => {
        let cacheArticleId = 0;

        if (state.state == null || state.state == undefined){
            const queryParameters = new URLSearchParams(window.location.search)
            setArticleId(parseInt(queryParameters.get("id") || "0"));
            cacheArticleId = parseInt(queryParameters.get("id") || "0");
        }else{
            setArticleId(state.state.id);
            cacheArticleId = state.state.id;
        }

        let queryTest = new URLSearchParams(window.location.search);

        if (queryTest.get("id") != null){
            setUseQuery(true);
        }

        GetNewsData(cacheArticleId).then((res) => {
            setNewsData(res);
        });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [state]);

    function removeHandle(){
        DeleteNews(newsData.id).then((res) => {
            if (res == null){
                alert("مشکلی در حذف به وجود امد");
                return;
            }

            window.location.href = "/news"
        });
    }

    function copyLink(){
        setIsCopied(true);

        if (!usedQuery){
            navigator.clipboard.writeText(window.location.href + "?id=" + articleId);
        }else{
            navigator.clipboard.writeText(window.location.href);
        }

        

        setTimeout(() => {
            setIsCopied(false);
        }, 3000);
    }

    function closeEdit(){
        setIsEditing(false);
    }

    let publishDate = new Date(newsData?.publishDate * 1000);
    let persianDate = new jalaliDate(publishDate);

    if (newsData.landingImage == null){
        return  <div className="page-container">
                <div id="main-background-au">

                </div>
                <Header></Header>
                <OpenImagePopup content={contentRef}></OpenImagePopup>
                <section className="blog px-5 py-50px mt-4 phone:mt-0 phone:py-0">
                    {
                        isEditing? <NewsEdit closeCallback={closeEdit} id={newsData.id} title={newsData.title} content={newsData.content} category={newsData.categoryId} date={newsData.publishDate}></NewsEdit> : <></>
                    }
                    <div className="blog-header grid grid-cols-12 px-5 phone:grid-cols-6 phone:px-0" dir="rtl">
                        <div className="blog-header-info col-span-6 flex flex-col justify-between gap-5 py-3" dir="rtl">
                            {
                                isCopeid?   <>
                                            <div className="blog-header-info-share flex flex-row hover:cursor-pointer" onClick={(copyLink)}>
                                                <div className="blog-header-info-share-bubble flex flex-col h-14 w-14 ml-5 justify-center text-center rounded-full">
                                                    <i className="fa-regular fa-circle-check text-[32px]"></i>
                                                </div>
                                                    <p className="blog-header-info-share-text text-base py-4">لینک کپی شد!</p>
                                                </div>
                                            </> : 
                                            <div className="blog-header-info-share flex flex-row hover:cursor-pointer" onClick={(copyLink)}>
                                            <div className="blog-header-info-share-bubble flex flex-col h-14 w-14 ml-5 justify-center text-center rounded-full">
                                                <i className="fa-regular fa-share-nodes text-[32px]"></i>
                                            </div>
                                            <p className="blog-header-info-share-text text-base py-4">اشتراک گذاری</p>
                                        </div>
                            }
                            
                            <h1 className="blog-header-info-title text-5xl text-right tablet:text-2xl phone:text-[32px]">{newsData.title}</h1>
                            <div className="blog-header-info-more flex flex-row">
                                <p className="blog-header-info-more-category rounded-full text-center align-middle px-4 py-4 ml-10 phone:ml-2">{newsData.categoryName}</p>
                                <p className="blog-header-info-more-date rounded-full text-center text-sm align-middle py-4 px-2 ml-1">{persianDate.format("YYYY MMM DD")}</p>
                                <div className="text-xl px-2 py-2 rounded-full">
                                    <i className="fa-regular fa-calendar"></i>
                                </div>
                                {
                                    userData.Roles.includes(Permissions.ChangeWebsiteSettings) && userData.IsLoggedIn? <><div className="text-xl px-2 py-2 rounded-full hover:cursor-pointer" onClick={() => setIsEditing(true)}>
                                    <i className="fa-regular fa-pen"></i>
                                </div>
                                <div className="text-xl px-2 py-2 rounded-full hover:cursor-pointer" onClick={() => removeHandle()}>
                                    <i className="fa-regular fa-trash"></i>
                                </div></> : <></>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="blog-content gap-10 mt-16 px-5 phone:mt-6 phone:px-0">
                        {<div dir="rtl" style={{display: "inline-block"}} ref={contentRef} className="ck-content" dangerouslySetInnerHTML={{ __html: newsData.content }} />}
                        <p style={{opacity: 0}}>.</p>
                    </div>
                </section>
                <Footer></Footer>
            </div>
    }else{
        return  <div className="page-container">
                <div id="main-background-au">

                </div>
                <div id="element-actions" className="flex flex-row flex-warp gap-3" onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                    <div id="edit-button" className="text-xl px-4 py-3 rounded-full hover:cursor-pointer" hidden onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                        <i className="fa-regular fa-pen"></i>
                    </div>
                    <div id="delete-button" className="text-xl px-4 py-3 rounded-full hover:cursor-pointer" hidden onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                        <i className="fa-regular fa-trash"></i>
                    </div>
                    <div id="close-button" className="text-xl px-4 py-3 rounded-full hover:cursor-pointer" hidden onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                    <div id="submit-button" className="text-xl px-4 py-3 rounded-full hover:cursor-pointer" hidden onMouseMove={(ev) => {mouseEnterSelfEvent(ev)}}>
                        <i className="fa-solid fa-check"></i>
                    </div>
                </div>
                <Header></Header>
                <OpenImagePopup content={contentRef}></OpenImagePopup>
                <section className="blog py-50px mt-4 phone:mt-0 phone:py-0">
                    {
                        isEditing? <NewsEdit closeCallback={closeEdit} id={newsData.id} title={newsData.title} content={newsData.content} category={newsData.categoryId} date={newsData.publishDate}></NewsEdit> : <></>
                    }
                    <div className="blog-header grid grid-cols-12 px-5" dir="rtl">
                        <div className="blog-header-info col-span-6 flex flex-col justify-between gap-5 py-3" dir="rtl">
                            {
                                isCopeid?   <>
                                            <div className="blog-header-info-share flex flex-row hover:cursor-pointer" onClick={(copyLink)}>
                                                <div className="blog-header-info-share-bubble flex flex-col h-14 w-14 ml-5 justify-center text-center rounded-full">
                                                    <i className="fa-regular fa-circle-check text-[32px]"></i>
                                                </div>
                                                    <p className="blog-header-info-share-text text-base py-4">لینک کپی شد!</p>
                                                </div>
                                            </> : 
                                            <div className="blog-header-info-share flex flex-row hover:cursor-pointer" onClick={(copyLink)}>
                                            <div className="blog-header-info-share-bubble flex flex-col h-14 w-14 ml-5 justify-center text-center rounded-full">
                                                <i className="fa-regular fa-share-nodes text-[32px]"></i>
                                            </div>
                                            <p className="blog-header-info-share-text text-base py-4">اشتراک گذاری</p>
                                        </div>
                            }
                            <h1 className="blog-header-info-title text-5xl text-right tablet:text-2xl phone:text-[32px]">{newsData.title}</h1>
                            <div className="blog-header-info-more flex flex-row">
                                <p className="blog-header-info-more-category rounded-full text-center align-middle px-4 py-4 ml-10 phone:ml-2">{newsData.categoryName}</p>
                                <p className="blog-header-info-more-date rounded-full text-center text-sm align-middle py-4 px-2 ml-1">{persianDate.format("YYYY MMM DD")}</p>
                                <div className="text-xl px-2 py-2 rounded-full">
                                    <i className="fa-regular fa-calendar"></i>
                                </div>
                                {
                                    userData.Roles.includes(Permissions.ChangeWebsiteSettings) && userData.IsLoggedIn? <><div className="text-xl px-2 py-2 rounded-full hover:cursor-pointer" onClick={() => setIsEditing(true)}>
                                    <i className="fa-regular fa-pen"></i>
                                </div>
                                <div className="text-xl px-2 py-2 rounded-full hover:cursor-pointer" onClick={() => removeHandle()}>
                                    <i className="fa-regular fa-trash"></i>
                                </div></> : <></>
                                }
                            </div>
                        </div>
                        <div className="h-[350px] w-full blog-header-img-container flex flex-row col-span-6 phone:mt-6 justify-center">
                            <img alt={`عکس ${newsData.title}`} src={StaticFileUrl + "/" + newsData.landingImage} className="blog-header-img"></img>
                        </div>
                    </div>
                    <div className="blog-content gap-10 mt-16 phone:mt-6 phone:px-0">
                        {<div dir="rtl" style={{display: "inline-block"}} ref={contentRef} className="ck-content" dangerouslySetInnerHTML={{ __html: newsData.content }} />}
                        <p style={{opacity: 0}}>.</p>
                    </div>
                </section>
                <Footer></Footer>
            </div>
    }

    
}