import React, { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { Permissions } from "../../enums/Permissions";
import { SetEntity } from "../../services/SiteEntitiesServices";
import axios from "axios";
import { BackEndUrl } from "../../App";

export default function LandingSliderItem(props: any){
    let imageUrl = props.image;
    let [userData, userReducer] = useContext(UserContext);

    function removeHandle(){
        axios.delete(BackEndUrl + "Centers/Slider", {
            params: {
                SliderIndex: props.id,
                Name: props.name
            },
            headers: {
                Authorization: "Bearer " + localStorage.getItem("UserToken")
            }
        }).then((res) => {
            alert(res.data.message);
            window.location.reload();
            return;
        });
    }

    if (userData.Roles.includes(Permissions.ChangeWebsiteSettings)){
        return  <div className="flex-none slider slider-admin" onClick={(removeHandle)}>
                <img alt="اسلایدر راوینو" className="h-full w-full rounded-[15px]" src={imageUrl} style={{objectFit: "cover"}}></img>
                <i className="fa-solid fa-x cross-icon"></i>
            </div>
    }

    return  <div className="flex-none slider">
                <img alt="اسلایدر راوینو" className="h-full w-full rounded-[15px]" src={imageUrl} style={{objectFit: "cover"}}></img>
            </div>
}