import axios from "axios";
import { User, UserInitialValues } from "../context/UserContext";
import { BackEndUrl } from "../App";


export async function GetUser() : Promise<User> {
    var token = "";
    
    if (localStorage.getItem("UserToken")){
        token = localStorage.getItem("UserToken") || "";
    }else {
        return UserInitialValues;
    }

    try {
        let userFetchData = await axios.get(BackEndUrl + "Auth", {
            headers: {
                Authorization: "Bearer " + token,
                "Access-Control-Allow-Origin": "*",
            }
        });

        if (userFetchData.data.data){
            let data = userFetchData.data.data;

            return {
                FirstName: data.firstName,
                LastName: data.lastName,
                Roles: data.permissions,
                Token: token,
                IsLoggedIn: true
            };
        }
    }catch(e){
        localStorage.removeItem("UserToken");
        return UserInitialValues;
    }
    
    return {
        ...UserInitialValues,
        FirstName: "Not Logged In"
    };
}

export async function RegisterUser(phoneNumber: string, password: string, firstName: string, lastName: string){
    if (localStorage.getItem("UserToken")){
        return false;
    }

    try {
        let userFetchData = await axios.post(BackEndUrl + "Auth/Register", {
            PhoneNumber: phoneNumber,
            Password: password,
            FirstName: firstName,
            LastName: lastName
        }, {
            headers: {
                "Access-Control-Allow-Origin": "*",
            }
        });

        if (userFetchData.data.data){
            let data = userFetchData.data.data;
            if (data.token == undefined){
                return [userFetchData.data.message, ""];
            }
            return [data.message, data.token];
        }
    }catch(e){
        return false;
    }
}

export async function LoginUser(phoneNumber: string, password: string){
    if (localStorage.getItem("UserToken")){
        return false;
    }

    try {
        let userFetchData = await axios.post(BackEndUrl + "Auth/Login", {
            PhoneNumber: phoneNumber,
            Password: password
        }, {
            headers: {
                "Access-Control-Allow-Origin": "*",
            }
        });

        if (userFetchData.data.data){
            let data = userFetchData.data.data;
            if (data.token == undefined){
                return [userFetchData.data.message, ""];
            }
            return [data.message, data.token];
        }
    }catch(e){
        return false
    }
}