import React, { useEffect, useState } from "react";

import style from "./OpenImagePopup.module.css";

export default function OpenImagePopup(props: any){
    let [isOpen, setIsOpen] = useState(false);
    let [imgSrc, setImgSrc] = useState("");

    function mouseClick(ev: MouseEvent){
        let clieckedElement = ev.target as HTMLElement;

        if (clieckedElement.tagName.toLocaleLowerCase() == "img" && props.content.current?.contains(clieckedElement)){
            let imgElement = clieckedElement as HTMLImageElement;
            setIsOpen(true);
            setImgSrc(imgElement.src);
        }
    }

    useEffect(() => {
        window.addEventListener("mousedown", mouseClick);

        return () => {
            window.removeEventListener("mousedown", mouseClick);
        }
    }, []);

    return isOpen? <div className={`${style.main_container}`}>
        <div className={`${style.header}`} onClick={() => {setIsOpen(false); setImgSrc("")}}>
            <div>مشاهده عکس</div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" style={{width: "20px", height: "20px"}}><path d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"/></svg>
        </div>
        <div className={`${style.image_container}`}>
            <img src={imgSrc} className={`${style.image}`}></img>
        </div>
    </div> : <></>
}