import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import { Permissions } from "../../../enums/Permissions";
import { GetEntity, SetEntity } from "../../../services/SiteEntitiesServices";
import FAQEdit from "./FAQEdit";

export default function FAQ(props: any){
    let [userData, userReducer] = useContext(UserContext);
    let [questions, setQuestions] = useState<any[]>([]);
    let [answers, setAnswers] = useState<any[]>([]);
    let [answerHidden, setAnswerHidden] = useState<boolean[]>([]);

    let [isEditing, setIsEditing] = useState(false);
    let [editingId, setEditingId] = useState<number | null>(null);

    let sectionRef = useRef<any>(null);

    useEffect(() => {
        setTimeout(() => {
            let scrollId = window.location.href.slice(window.location.href.indexOf("#") + 1);

            if (scrollId == "faq"){
                let linkElement = document.createElement("a");
                linkElement.href = "#faq";
    
                linkElement.click();
            }
        }, 500);
      }, []);

    useEffect(() => {
        GetEntity("index-faq-data").then((res) => {
            if (res == null){
                return;
            }
            if (res.content == null || res.content == ""){
                setQuestions([]);
                setAnswers([]);
                return;
            }

            let jsonObject = JSON.parse(res.content);
            setQuestions(jsonObject.questions);
            setAnswers(jsonObject.answers);
            setAnswerHidden(jsonObject.answers.map((answer: any) => true));
        });
    }, []);

    function switchAnswer(id: number){
        let cacheNewAnswers = [...answerHidden];

        cacheNewAnswers[id] = !cacheNewAnswers[id];

        setAnswerHidden(cacheNewAnswers);
    }

    function removeAnswer(id: number){
        let newQuestions = questions.filter((q, index) => index != id);
        let newAnswers = answers.filter((q, index) => index != id);

        let object = JSON.stringify({
            questions: newQuestions,
            answers: newAnswers
        });

        SetEntity("index-faq-data", object, "index-faq", "index").then((res) => {
            if (res == null){
                alert("مشکلی در حذف پیش امد");
                return;
            }

            window.location.reload();
        })
    }

    function editHandler(id: number){
        setEditingId(id);
        setIsEditing(true);
    }

    function closeHandle(){
        setEditingId(null);
        setIsEditing(false);
    }

    return <section ref={sectionRef} id="faq" className="faq px-36 py-50px mt-10 phone:px-10">
        {
            isEditing? <FAQEdit otherQuestions={questions} otherAnswers={answers} closeHandler={closeHandle} id={editingId}></FAQEdit> : <></>
        }
    <div className="flex flex-row justify-center">
        <div className="flex flex-col justify-center">
            <div className="flex flex-row gap-4">
                <h3 className="text-32px text-center faq-header-title" id="news-header-title">
                    سوالات متداول
                </h3>
                {
                    userData.Roles.includes(Permissions.ChangeWebsiteSettings)? <div id="submit-button" className="flex flex-col justify-center text-xl px-4 text-center rounded-full hover:cursor-pointer" onClick={() => {setIsEditing(true)}}>
                    <i className="fa-solid fa-plus"></i>
                </div> : <></>
                }
            </div>
        </div>
    </div>
    <div className="flex flex-col mt-16" dir="rtl">
        {
            questions.map((question: any, key: number) => {
                let answer = answers[key];

                let classAnswer = "faq-question-content mt-8 " + (answerHidden[key]? "hidden" : "block");

                return  <div className="faq-question-item flex flex-col justify-center px-10 py-8 phone:px-0">
                            <div className="faq-question-header flex flex-row justify-between">
                                <h3 className="faq-question-item-title" style={{cursor: "pointer"}} onClick={() => {switchAnswer(key)}}>{question}</h3>
                                <div className="flex flex-row gap-4">
                                    {
                                        userData.Roles.includes(Permissions.ChangeWebsiteSettings)? <div className="flex flex-col justify-center" style={{cursor: "pointer"}} onClick={() => editHandler(key)}><i className="fa-solid fa-pen"></i></div> : <></>
                                    }
                                    {
                                        userData.Roles.includes(Permissions.ChangeWebsiteSettings)? <div className="flex flex-col justify-center" style={{cursor: "pointer"}} onClick={() => removeAnswer(key)}><i className="fa-solid fa-trash"></i></div> : <></>
                                    }
                                    <div className="flex flex-col justify-center" style={{cursor: "pointer", transform: answerHidden[key]? "" : "rotate(180deg)", transition: "ease-in 0.1s"}} onClick={() => {switchAnswer(key)}}>
                                        <i className="fa-solid fa-chevron-down"></i>
                                    </div>
                                </div>
                            </div>
                            <div className={classAnswer}>
                                <div className="text-lg" dangerouslySetInnerHTML={{ __html: answer }} />
                            </div>
                        </div>
            })
        }
        
    </div>
</section>
}