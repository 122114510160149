import React, { useState } from "react";

export default function Register(props: any){
    let [phoneNumber, setPhoneNumber] = useState("");
    let [password, setPassword] = useState("");
    let [firstName, setFirstName] = useState("");
    let [lastName, setLastName] = useState("");
    let [passwordRepeat, setPasswordRepeat] = useState("");


    function handlePhoneChange(event: any){
        setPhoneNumber(event.target.value);
    }

    function handlePasswordChange(event: any){
        setPassword(event.target.value);
    }
    function handlePasswordRepeatChange(event: any){
        setPasswordRepeat(event.target.value);
    }
    function handleFirstNameChange(event: any){
        setFirstName(event.target.value);
    }
    function handleLastNameChange(event: any){
        setLastName(event.target.value);
    }

    function submitHandle() {
        const phoneRegex = /^09\d{9}$/;
    
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{5,30}$/;
    
        if (!phoneRegex.test(phoneNumber)) {
            alert("شماره تلفن وارد شده معتبر نمیباشد");
            return;
        }
    
        if (!passwordRegex.test(password)) {
            alert("رمز باید شامل حروف کوچک و بزرگ و یک عدد باشد و بین 5 تا 30 کلمه باشد");
            return;
        }

        if (password != passwordRepeat){
            alert("رمز با تکرارش یکی نمیباشد")
        }
    
        if (firstName === "" || lastName === "") {
            alert("نام و نام خانوادگی نباید خالی باشد")
            return;
        }
    
        props.registerHandle(phoneNumber, password, firstName, lastName);
    }

    function switchHandle(){
        props.switchHandle(true)
    }

    return <>
        <div className="flex flex-row justify-center">
                    <div className="w-[240px] flex flex-row-reverse text-base rounded-30px px-1 justify-around py-1" id="login-buttons-container">
                        <div className="flex flex-col justify-center text-center rounded-30px px-8 py-2 hover:cursor-pointer" onClick={switchHandle}>
                            ورود
                        </div>
                        <div className="flex flex-col justify-center text-center rounded-30px au-active px-8 py-2 hover:cursor-pointer">
                            عضویت
                        </div>
                    </div>
                </div>
                <div className="w-full au-input rounded-15px px-2 py-2 my-4">
                    <p className="relative au-input-header py-1 px-3">نام</p>
                    <input type="tel" className="w-full h-full au-input-input text-right" placeholder="" value={firstName} onChange={handleFirstNameChange}></input>
                </div>
                <div className="w-full au-input rounded-15px px-2 py-2 my-4">
                    <p className="relative au-input-header py-1 px-3">نام خانوادگی</p>
                    <input type="tel" className="w-full h-full au-input-input text-right" placeholder="" value={lastName} onChange={handleLastNameChange}></input>
                </div>
                <div className="w-full au-input rounded-15px px-2 py-2 my-4">
                    <p className="relative au-input-header py-1 px-3">شماره همراه</p>
                    <input type="tel" className="w-full h-full au-input-input text-right" placeholder="" value={phoneNumber} onChange={handlePhoneChange}></input>
                </div>
                <div className="w-full au-input rounded-15px px-2 py-2 my-4">
                    <p className="relative au-input-header py-1 px-3">رمز عبور</p>
                    <input type="password" className="w-full h-full au-input-input text-right" placeholder="" value={password} onChange={handlePasswordChange}></input>
                </div>
                <div className="w-full au-input rounded-15px px-2 py-2 my-4">
                    <p className="relative au-input-header py-1 px-3">تکرار رمز عبور</p>
                    <input type="password" className="w-full h-full au-input-input text-right" placeholder="" value={passwordRepeat} onChange={handlePasswordRepeatChange}></input>
                </div>
                <div className="flex flex-row justify-center">
                    <div className="w-[240px] flex flex-row-reverse text-base rounded-30px px-1 justify-around py-1">
                        <div className="flex flex-col justify-center text-center rounded-30px au-active px-8 py-3 hover:cursor-pointer" onClick={submitHandle}>
                            ورود
                        </div>
                    </div>
                </div>
    </>
}