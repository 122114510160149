import React, { useEffect, useState } from "react";
import { SetEntity } from "../../../services/SiteEntitiesServices";

export default function ContactUsEdit(props: {oldUrl: string, closeHandler: any}){
    let [location, setLocation] = useState("");
    let [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setLocation(props.oldUrl || "");
    }, []);

    function extractIframeSrc(htmlString: string) {
        let iFrame = htmlString.slice(0, htmlString.indexOf("<br"));

        iFrame = iFrame.slice(0, htmlString.indexOf("width=\"")) + " style=\"width:100%;height:100%\" " + iFrame.slice(htmlString.indexOf("src=\""));

        //let srcLink = htmlString.slice(srcStartIndex, srcEndIndex - srcStartIndex);
        return iFrame;
    }

    function submitHandler(){
        if (isLoading){
            return;
        }
        setIsLoading(true);
        let src = extractIframeSrc(location);

        if (src == null){
            alert("لینک وارد شده نامعتبر میباشد");
            return;
        }

        SetEntity("index-contactus-location", src, "index-contactus", "index").then((res) => {
            setIsLoading(false);
            if (res == null){
                alert("مشکلی در ویرایش به وجود امد");
                return;
            }


            window.location.reload();
        });
    }

    return <section className="popups flex flex-row justify-center py-12 px-72 tablet:px-0">
        <div className="flex flex-col popup bg-white p-8 rounded-15px">
            <div className="popup-content">
                <div className="popup-header flex flex-row-reverse justify-between" onClick={() => {props.closeHandler()}}>
                    <h4 className="popup-header-title text-2xl">ویرایش نقشه</h4>
                    <i className="fa-solid fa-x" style={{cursor: "pointer"}}></i>
                </div>
                <div className="popup-tabs flex flex-row-reverse justify-start px-4 gap-6 mt-5">
                </div>
                <div className="grid grid-cols-2 gap-6 justify-center mt-4">
                    <div className="popup-input col-span-2 rounded-15px px-2 py-2 my-4">
                        <input type="text" className="w-full h-full text-right" placeholder="مختصات" value={location} onChange={(ev) => {setLocation(ev.target.value)}}></input>
                    </div>
                </div>

                <div className="flex flex-row-reverse justify-center gap-4 mt-6">
                    <a href="#" className="rounded-30px text-center py-4 px-10 popup-submit text-xs" onClick={submitHandler}>{isLoading? <i className="fa-solid fa-spinner"></i> : <p>انتشار</p>}</a>
                    <a href="#" className="rounded-30px text-center py-4 px-10 popup-cancel text-xs" onClick={(ev) => {ev.preventDefault();props.closeHandler()}}>لغو</a>
                </div>
            </div>
        </div>
    </section>
}